
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { InAppTemplate } from "../../../shared/templates/inapp";
import Rate from "../../../assets/rate.svg";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import { Link } from "react-router-dom"
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { TableComponent } from "../../../shared/components/table"







import AddIco from "../../../assets/icons/add.png"
import ShopIco from "../../../assets/shop_1.jpeg"
import DeleteIco from "../../../assets/icons/delete.svg"
import EditIco from "../../../assets/icons/edit.svg"










import { useWindowSize } from "../../../hooks/useWindowSize";

import {FilterUtil} from "../../../shared/components/report-filter"

import AllProductsListManagement from "./product-listing"
import "../index.scss"






// const InAppContent = ({ pageProps }: any) => {
//     const [salesFlag, setSalesFlag] = useState(1);
//     const screenResolution: any = useWindowSize();




//     useEffect(() => {



//         // let loggedAccountInfo: any = localStorage.getItem("mami-Super-XAuth") || "{}";
//         // loggedAccountInfo = JSON.parse(loggedAccountInfo);
//         // let storeId = loggedAccountInfo.selectedStore.id;
//         // let payload = `StoreId=${storeId}`;
//         // let graphPayload = `StoreId=${storeId}&ReportGroupingType=${salesFlag}`;







//     }, [])

//     const ReportTable = () => {
//         return (
//             <>
//                 <thead>
//                     <tr>
//                         <th className="left_top">
//                             <span>Product</span>
//                         </th>
//                         <th>Quantity</th>

//                         <th>Category</th>
//                         {/* <th>Switch</th> */}
//                         {/* <th>On-sale</th> */}
//                         <th>Store</th>
//                         <th>Price</th>
//                         <th>Rating</th>
//                         <th>Created On</th>

//                         <th className="right_top blanked"> <span></span></th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     <tr>
//                         <td>
//                             <div className="product-info">
//                                 <input type="checkbox" name="" id="" />
//                                 <img src={ShopIco} alt="" />
//                                 <div className="prod-name">
//                                     Cashew Juice
//                                 </div>
//                             </div>
//                         </td>
//                         <td>23</td>
//                         <td>
//                             <span className="cate">
//                                 Beverage
//                             </span>
//                             <span className="cate">
//                                 Dairy
//                             </span>
//                         </td>
//                         <td className="grayed">The Food Emporium</td>
//                         <td className="branded">$10.19</td>
//                         <td><img className="rate_ico" src={Rate} alt="" /> 4.8 <span className="grayed">(126)</span></td>
//                         <td className="grayed">01/05/2023</td>
//                         <td>
//                             <div className="action_ctas">
//                                 <button>
//                                     <img src={EditIco} alt="" />
//                                 </button>
//                                 <button>
//                                     <img src={DeleteIco} alt="" />
//                                 </button>
//                             </div>
//                         </td>
//                     </tr>
//                     <tr>
//                         <td>
//                             <div className="product-info">
//                                 <input type="checkbox" name="" id="" />
//                                 <img src={ShopIco} alt="" />
//                                 <div className="prod-name">
//                                     Cashew Juice
//                                 </div>
//                             </div>
//                         </td>
//                         <td>23</td>
//                         <td>
//                             <span className="cate">
//                                 Beverage
//                             </span>
//                             <span className="cate">
//                                 Dairy
//                             </span>
//                         </td>
//                         <td className="grayed">The Food Emporium</td>
//                         <td className="branded">$10.19</td>
//                         <td><img className="rate_ico" src={Rate} alt="" /> 4.8 <span className="grayed">(126)</span></td>
//                         <td className="grayed">01/05/2023</td>
//                         <td>
//                             <div className="action_ctas">
//                                 <button>
//                                     <img src={EditIco} alt="" />
//                                 </button>
//                                 <button>
//                                     <img src={DeleteIco} alt="" />
//                                 </button>
//                             </div>
//                         </td>
//                     </tr>
//                     <tr>
//                         <td>
//                             <div className="product-info">
//                                 <input type="checkbox" name="" id="" />
//                                 <img src={ShopIco} alt="" />
//                                 <div className="prod-name">
//                                     Cashew Juice
//                                 </div>
//                             </div>
//                         </td>
//                         <td>23</td>
//                         <td>
//                             <span className="cate">
//                                 Beverage
//                             </span>
//                             <span className="cate">
//                                 Dairy
//                             </span>
//                         </td>
//                         <td className="grayed">The Food Emporium</td>
//                         <td className="branded">$10.19</td>
//                         <td><img className="rate_ico" src={Rate} alt="" /> 4.8 <span className="grayed">(126)</span></td>
//                         <td className="grayed">01/05/2023</td>
//                         <td>
//                             <div className="action_ctas">
//                                 <button>
//                                     <img src={EditIco} alt="" />
//                                 </button>
//                                 <button>
//                                     <img src={DeleteIco} alt="" />
//                                 </button>
//                             </div>
//                         </td>
//                     </tr>



//                 </tbody>
//             </>
//         )
//     }


//     const ReportsDisplay = () => {
//         return (
//             <div className="reports_wrap">
//                 <div className="report_head_wrap">
//                     <div className="report_head_left">
//                         <div className="report_heading">Products <span>23,691</span></div>
//                         <div className="report_desc">Easily add, edit and delete products from vendors&apos; stores </div>
//                     </div>
//                     <div className="report_head_right">
//                         <div className="report_head_cta">
//                             <Link className="head_cta_link" to={`/app/products/create-new`}>
//                                 <button>
//                                     <img src={AddIco} alt="" />
//                                     <span>New Product</span>
//                                 </button>
//                             </Link>

//                         </div>
//                     </div>
//                 </div>
//                 <div className="reports_table">
//                     <div className="report_actions">
//                         <input type="text" className="search_table" placeholder="Search for product or store name" />
//                         <div className="right_actions">
//                         <FilterUtil/>


//                         </div>
//                     </div>
//                     <TableComponent
//                         childComponent={<ReportTable />}
//                     // childComponent={<ReportTable reportData={pageProps.getStoreOrdersRequest.request_data.response} />}
//                     />
//                 </div>
//             </div>
//         )
//     }










//     // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
//     return (
//         <div className="inapp-content">

//             <div className="page-content dashboard">
//                 <ReportsDisplay />
//             </div>
//         </div>
//     )
// }

const ProductsManagement = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Products Management" childComponent={<AllProductsListManagement pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
    // return {

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(ProductsManagement);