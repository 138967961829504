import './help.scss'
import { AiOutlineClose } from 'react-icons/ai'
import user from '../../../assets/user.png'
import React from 'react'

export type props = Array<{
    img: string,
    name: string,
    role: string,
    status: 'online' | 'offline'
}>
export const AssignModal = ({ content, title, handleModalClose }: { content: props, title: string, handleModalClose: () => void }) => {
    return (
        <div className='modal-container'>
            <div className='modal-content'>
                <div className='space'>
                    <h4 className='modal-title'>{title}</h4>
                    <div onClick={handleModalClose} style={{cursor: 'pointer'}}>
                        <AiOutlineClose />
                    </div>
                </div>
                <div className='scroll'>
                    {
                        content.map((elem) =>
                            <div className='space2'>
                                <div className='flex2'>
                                    <div className='assignee'>
                                        <img src={elem.img} />
                                        <div className={
                                            elem.status === 'online' ? 'round'
                                                : 'round2'
                                        } />
                                    </div>
                                    <div className='details'>
                                        <h6>{elem.name}</h6>
                                        <p>{elem.role}</p>
                                    </div>
                                </div>
                                <input type='radio' name='radio' />
                            </div>
                        )
                    }
                </div>
                <button className='assign-btn'>Assign issue</button>
            </div>
        </div>
    )
}