import { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import { connect } from 'react-redux';

import { NavLink } from "react-router-dom"
import { Link } from "react-router-dom"
import Logo from "../../../assets/logo2.png"

import CloseIco from "../../../assets/icons/close.svg"
import "./index.scss"

import { authActions } from "../../../redux/actions/onboarding"



// type OwnProps = HeaderProps & ReturnType<typeof mapStateToProps>;
export const SideBarWrap = ({ pageProps }: any) => {
    
    let loggedAccountInfo: any = localStorage.getItem("mami-Super-XAuth") || "{}",
        userStores: any[];




    if (loggedAccountInfo) {
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        userStores = loggedAccountInfo.myStores;

    }
    const BizName = () => {
        return (
            <div className="bizname-wrap">
                <Link className="logo-head" to={`/app`}>
                    <img src={Logo} alt="" />
                </Link>
                <div className="admin_txt">Super Admin</div>

                
            </div>
        )
    }



    const MenuItems = () => {
        return (
            <div className="all-menu-items">
                <div className="menu-item">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app">
                        <div className="menu-icon dash-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Dashboard</div>
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores">
                        <div className="menu-icon stores-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Stores</div>
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/vendors">
                        <div className="menu-icon vendors-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Vendors</div>
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/orders">
                        <div className="menu-icon order-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Orders</div>
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/customers">
                        <div className="menu-icon customer-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Customers</div>
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/products">
                        <div className="menu-icon prod-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Products</div>
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/settings">
                        <div className="menu-icon settings-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Settings</div>
                    </NavLink>
                </div>
               

                

                
                <div className="menu-item   ">
                    <div onClick={() => pageProps.logoutAction()} className="logout_wrap" >
                        <div className="menu-icon logout-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt logout">Logout</div>
                    </div>
                </div>
                
               

            </div>
        )
    }


    return (

        <div className={!pageProps?.isMobile ? "sidebar-wrap" : "sidebar-wrap withmobile"}>
            {pageProps?.isMobile &&
                <div className="sidebar-close" onClick={() => pageProps?.setShowVisible(false)}>
                    <img src={CloseIco} alt="" />
                </div>
            }

            <BizName />
            <MenuItems />
        </div>

    )
}

const SideBar = (pageProps: any, isMobile?: any) => {
    // const { productId } = useParams();
    // useEffect(() => {


    //         getProductDetail({
    //             pageProps,
    //             payload: "CLEAR"
    //         })



    // }, [])
    return (
        <>
            {pageProps?.isMobile && <div className="underlay_mobile"></div>}
            <SideBarWrap pageProps={pageProps} isMobile={isMobile} />
        </>
    )
}
const mapDispatchToProps = {
    logoutAction: authActions.Logout,
};

const mapStateToProps = (state: any) => {
    return {
        // loginRequest: state.onboardingOutboundReducers.loginReducer,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);