import { useState, useEffect } from "react";
import '../../../scss/onboarding.scss';
import { connect } from 'react-redux';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { OnBoardingFormValues, ILoginProps } from "../../../types"
import GSign from "../../../assets/g-sign.svg"
import { PostBoardingWrap } from "../../../shared/templates/onboarding";
import { authActions } from "../../../redux/actions/onboarding"
import { AlertMsg } from "../../../shared/components/alert-msg"

const forgotPasswordAction = async ({ pageProps, payload }: any) => {

    await pageProps.ForgotPassword(payload);
}

const PostBoardingContent = ({ pageProps }: any) => {
    useEffect(() => {
        // pageProps.logoutAction();
        forgotPasswordAction({
            pageProps,
            payload: "CLEAR"
        })


    }, [])
    let checkValidationSchema = Yup.object().shape({
        username: Yup.string().trim()
            .email('Valid email is required')
            .required('Required'),

    });
    let initialValues: any = { username: "" }
    return (
        <div className="postboarding-content">
            {(pageProps?.forgotPasswordRequest?.request_status
                && pageProps.forgotPasswordRequest.request_status !== "FORGOT_PASSWORD_SUCCESS") &&
                <div className="content-heading">
                    <h2>Forgot Password</h2>
                    {/* <div className="content-heading-msg">
                    New Vendor?
                    <Link to="/account/create">Register</Link>
                </div> */}
                </div>
            }
            <div className="form-wrap">
                <Formik
                    initialValues={initialValues}

                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {
                        let { username } = values;
                        let payload: any = {
                            email: username
                        }
                        await forgotPasswordAction({ pageProps, payload })
                    }}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            values,
                            touched,
                            errors,
                            // handleBlur,
                            handleChange,
                            // isSubmitting,
                        } = props;

                        return (
                            <Form>
                                {/* <PersistFormikValues name="signin-form" /> */}
                                {(pageProps?.forgotPasswordRequest?.request_status
                                    && pageProps.forgotPasswordRequest.request_status !== "FORGOT_PASSWORD_SUCCESS") &&
                                    <>
                                        <div className="form-group">
                                            <div className={errors.username && touched.username ? "invalid-input-wrap" : ""}>
                                                <Field
                                                    value={values.username}
                                                    id="username"
                                                    name="username"
                                                    type="text"
                                                    onChange={handleChange('username')}
                                                    placeholder="Email Address"
                                                    className={`input-wrap ${errors.username && touched.username ?
                                                        "is-invalid-input "
                                                        : !errors.username && values.username !== "" ? "valid-input" : ""}`}
                                                />
                                            </div>
                                            <ErrorMessage name="username" className="form-input-error" component="div" />
                                        </div>

                                        {pageProps?.forgotPasswordRequest?.request_status
                                            && pageProps.forgotPasswordRequest.request_status === "FORGOT_PASSWORD_FAILURE" &&
                                            <AlertMsg type="error" message={pageProps.forgotPasswordRequest?.request_data.error} />
                                        }
                                        <button
                                            className="main-cta full"

                                            disabled={pageProps.forgotPasswordRequest?.is_request_processing}
                                            type="submit">

                                            {pageProps.forgotPasswordRequest.is_request_processing ? "Please wait..." : "Continue"}
                                        </button>
                                        <div className="form-link">
                                            <Link to="/">Back to Login</Link>
                                        </div>
                                    </>
                                }
                                {(pageProps?.forgotPasswordRequest?.request_status
                                    && pageProps.forgotPasswordRequest.request_status === "FORGOT_PASSWORD_SUCCESS") &&
                                    <div className="success-message-wrap">
                                        <div className="success-animation">
                                            <svg className="success_icon_tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                                        </div>
                                        <div className="success-message-txt">Please click the link sent to your email to reset your password</div>

                                    </div>
                                }
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            {(pageProps?.forgotPasswordRequest?.request_status
                && pageProps.forgotPasswordRequest.request_status !== "FORGOT_PASSWORD_SUCCESS") &&
                <div className="optional">
                    <div className="option-txt">OR</div>
                    <button className="alt-cta full all-center">
                        <img className="gsign" src={GSign} alt="Google SignIn" />
                    </button>
                </div>
            }

        </div>
    )
}


const ForgotPassword = (pageProps: any) => {
    return (
        <PostBoardingWrap pageHeadingTitle="Reset your password" childComponent={<PostBoardingContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    ForgotPassword: authActions.ForgotPassword,
    logoutAction: authActions.Logout,
};

const mapStateToProps = (state: any) => ({
    forgotPasswordRequest: state.onboardingOutboundReducers.forgotPasswordReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);