import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import { useWindowSize } from "../../../hooks/useWindowSize";
import SideBar from "../sidebar"

import Dropdown from 'react-bootstrap/Dropdown';

import Noti from "../../../assets/noti-1.png"
import MailIco from "../../../assets/Mail.png"
import User1 from "../../../assets/user.png"
import DropIco from "../../../assets/drop-ico.png"


import "./index.scss";

export const AppHeader = (props: any) => {
    const screenResolution: any = useWindowSize();
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const [isMobileMenuVisible, setShowVisible] = useState<boolean>(false)
    let loggedAccountInfo: any = localStorage.getItem("mami-Super-XAuth") || "{}";
    loggedAccountInfo = JSON.parse(loggedAccountInfo);
    // console.log("screenResolution", screenResolution)

    const handleDropdown = () => {
        setOpenMenu(!openMenu)
    }

    return (
        <div className="header-wrap">


            {screenResolution?.width >= 768 &&
                <div className="header-menus">
                    {/* <div className="each-menu">
                        <img src={Noti} alt="" />
                    </div>
                    <div className="each-menu">
                        <img src={ProfileIco} alt="" />
                    </div> */}

                    <div className="page-heading-content">
                        <div className="search_bar">
                            <input type="text" placeholder="Type to search" />
                        </div>
                    </div>

                    {/* {(loggedAccountInfo?.selectedStore && props?.showMainPageHeading && props?.mainPageHeading && loggedAccountInfo?.selectedStore?.hasBankLinked === true) &&
                        <div className="page-heading-content">
                            {props?.mainPageHeading}
                        </div>
                    } */}
                    <div className="other_items">

                        <div className="notify-cta" onClick={() => handleDropdown()}>
                            <img src={MailIco} alt="" />
                            <div className='notification'>
                                <p>2</p>
                            </div>
                        </div>

                        <div className="notify-cta">
                            <img src={Noti} alt="" />
                        </div>
                        <div className="profile-info">

                            <div className="">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className="profile-img-drop">
                                        <img src={User1} alt="" className="user-img" />
                                        <div className="drop-ico">
                                            <img src={DropIco} />
                                        </div>
                                    </Dropdown.Toggle>

                                </Dropdown>

                            </div>
                        </div>
                    </div>
                </div>

            }
            {
                screenResolution?.width <= 767 &&
                <div className="header-menus" onClick={() => setShowVisible(true)}>
                    <div className="each-menu small">

                    </div>
                </div>
            }
            {
                isMobileMenuVisible &&
                <SideBar
                    setShowVisible={setShowVisible}
                    isMobile={isMobileMenuVisible}
                />
            }
            {openMenu &&
                <div className='menu-dropdown'>
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <Link to={'/app/help-center'} className='dropdown-item'>
                            Customers
                        </Link>
                        <div className='notif'>
                            <p>2</p>
                        </div>
                    </div>
                    <div style={{marginTop: '1rem'}}>
                        <Link to={'/app/help-center'} className='dropdown-item'>
                            Vendors
                        </Link>
                    </div>
                </div>
            }
        </div >
    )
}
// const mapDispatchToProps = {
// };

// const mapStateToProps = (state: any) => {
//     return {
//         // loginRequest: state.onboardingOutboundReducers.loginReducer,
//     };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HeadeWrap);