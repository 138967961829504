
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";

import { TableComponent } from "../../../shared/components/table"

import Modal from 'react-bootstrap/Modal'



import { LoadingItem } from "../../../shared/components/loading"
import { AlertMsg } from "../../../shared/components/alert-msg"

import StoreSalesDisplay from "./store-sales-chart"

import { appActions } from "../../../redux/actions/in-app"
import { appConstants } from "../../../redux/action-constants/inapp.constants";
import TrendUp from "../../../assets/trend_up.svg"

import BackIco from "../../../assets/back.svg"
import SuspendIco from "../../../assets/icons/suspend.svg"
import All_Earn from "../../../assets/all_earning.svg"
import All_Feedback from "../../../assets/all_feedback.svg"
import All_Stores from "../../../assets/all_stores.svg"
import ContactIco from "../../../assets/contact.svg"

import NewStoreModalWrap from "../../../shared/components/new-store"

import { history } from "../../../shared/_helpers/history";







import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ArcElement,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);



const toggleUser = async ({ pageProps, payload }: any) => {

    await pageProps.toggleUserAction(payload);
}

const getVendorDetails = async ({ pageProps, payload }: any) => {

    await pageProps.getVendorDetailsAction(payload);
}



const SuspendVendorModal = (props: any) => {
    let { showSuspendModal, setShowSuspendModal, pageProps } = props
    const { vendorId } = useParams();





    const suspendProcess = () => {
        toggleUser({
            pageProps,
            payload: `?Id=${vendorId}`
        }).then(() => {
        })
    }
    return (
        <Modal className="suspend_wrap" show={showSuspendModal} onHide={() => setShowSuspendModal(false)}>
            <Modal.Header closeButton>
                Suspend vendor?
            </Modal.Header>
            <Modal.Body>
                <div className="head_message">
                    Please be advised that suspending this vendor will also <span> suspend all of the stores owned by the vendor.</span> This means that customers will be unable to make purchases from these stores until the suspension is lifted. Are you sure you want to proceed with this action?
                </div>
                {pageProps.toggleUserRequest.request_status !== appConstants.TOGGLE_USER_SUCCESS &&
                    <div className="suspend_form">
                        <textarea placeholder='Reason for suspension' name="" id="" rows={10}></textarea>
                        <div className="suspend_ctas">
                            <button disabled={pageProps.toggleUserRequest?.is_request_processing} className="btn cancel"> Cancel</button>
                            <button onClick={suspendProcess} disabled={pageProps.toggleUserRequest?.is_request_processing} className="btn confirm">{pageProps.toggleUserRequest?.is_request_processing ? "Please wait" : "Confirm suspension"} </button>
                        </div>
                    </div>
                }
                {pageProps.toggleUserRequest.request_status === appConstants.TOGGLE_USER_FAILURE &&
                    <Modal.Body>
                        <AlertMsg type="error" message={pageProps.toggleUserRequest?.request_data.error} />
                    </Modal.Body>
                }
                {pageProps.toggleUserRequest.request_status === appConstants.TOGGLE_USER_SUCCESS &&
                    <Modal.Body>
                        <AlertMsg type="success" message="Vendor status successfully updated" />
                    </Modal.Body>
                }
            </Modal.Body>
        </Modal>
    )

}

const SalesChart = ({ salesData }: any) => {
    let chartValues: any[] = salesData.map((each: any) => each?.count)
    let chartLabels: any[] = salesData.map((each: any) => each?.grouping)
    const options = {
        responsive: true,
        cutout: 120,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
            title: {
                display: false,
                // text: 'Chart.js Line Chart',
            },
        },
        tension: 0.5
    };
    const data6 = {
        labels: chartLabels,
        datasets: [
            {
                label: '',
                data: chartValues,
                borderColor: '#00A85B',
                borderWidth: 2,
                // backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            // {
            //     label: '',
            //     data: labelValues2,
            //     borderColor: 'rgb(80, 143, 244)',
            //     borderWidth: 5,
            //     // backgroundColor: 'rgba(255, 99, 132, 0.5)',
            // },

        ],
    };
    return (
        <>
            {salesData.length >= 1 &&
                <Line options={options} data={data6} />
            }

            {salesData.length === 0 &&
                <div className="no-data">No Data for specified period</div>
            }
        </>
    )
}

const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false);
    const [showNewStore, setShowNewStore] = useState<boolean>(false)
    const screenResolution: any = useWindowSize();
    const { vendorId } = useParams();



    useEffect(() => {



        // let loggedAccountInfo: any = localStorage.getItem("mami-Super-XAuth") || "{}";
        // loggedAccountInfo = JSON.parse(loggedAccountInfo);
        // let storeId = loggedAccountInfo.selectedStore.id;
        // let payload = `StoreId=${storeId}`;
        // let graphPayload = `StoreId=${storeId}&ReportGroupingType=${salesFlag}`;


        getVendorDetails({
            pageProps,
            payload: `${vendorId}`
        }).then(() => {


        })




    }, [])


    const VendorStoresList = ({ storeList }: any) => {
        const [salesFlag, setSalesFlag] = useState(1);
        return (
            <div className="stores_list_wrap">
                {
                    storeList.map((eachStore: any, index: any) => {
                        // console.log("eachStore", eachStore)
                        return (
                            // <div className='each_store' key={index}>
                            //     <div className="store_name"></div>
                            //     <SalesChart salesData={eachStore?.storeChart} />
                            // </div>
                            <div className="all-sale-stats cards" key={index}>
                                <div className="stats-heading">
                                    <div className="heading-txt">{eachStore?.storeName}</div>
                                    <div className="stats-heading">
                                        <div className={salesFlag === 1 ? "each-stat-head  active" : "each-stat-head"} onClick={() => setSalesFlag(1)} >6 Months</div>
                                        <div className={salesFlag === 3 ? "each-stat-head  active" : "each-stat-head"} onClick={() => setSalesFlag(3)}  >7 days</div>
                                        {/* <div className="each-stat-head">30 days</div> */}
                                    </div>

                                    <div className="goto-store">
                                        {/* <img src={ExportIco} alt="" /> */}
                                        <Link to={`/app/stores/${eachStore?.storeId}/details`}>
                                            <span>Store Info <img src={TrendUp} alt="" /> </span>
                                        </Link>
                                    </div>

                                </div>
                                <div className="allstats">

                                    <div className="each-chartwrap" id="chart-sales">
                                        <SalesChart salesData={eachStore?.storeChart} />

                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {/* <StoreSalesDisplay storeId={storeList[0]?.id} /> */}
                {/* <SalesChart salesData={getDashbardBottompDataRequest.request_data?.response?.graph} /> */}
            </div>
        )
    }




    const ReportsDisplay = () => {
        let vendorDetails = pageProps.getVendorDetailsRequest?.request_data?.response?.data;
        let vendorContact = pageProps.getVendorDetailsRequest?.request_data?.response?.contact;
        let allStoreChart = pageProps.getVendorDetailsRequest?.request_data?.response?.allStoreChart;
        let vendorDetailsRatings: any[] = pageProps.getVendorDetailsRequest?.request_data?.response?.data?.ratings || [];
        let averageRating: any = 0, totalRating = 0;
        if (vendorDetailsRatings.length >= 1) {
            vendorDetailsRatings.forEach((rating: any) => {
                totalRating += rating;
            })
            averageRating = totalRating / vendorDetailsRatings.length;
            averageRating = parseFloat(averageRating.toFixed(1))


        }

       
        const RatingRemarks = () => {


            if (averageRating <= 3) {

                return "Bad"
            }
            if (averageRating >= 3 && averageRating <= 4) {

                return "Good"
            }
            if (averageRating > 4 && averageRating <= 4.5) {

                return "Very Good"
            }
            if (averageRating > 4.5 && averageRating <= 5) {

                return "Good"
            }


            return ""
        }
        const formatPhoneNumber = (phoneNumberString: any) => {
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                var intlCode = (match[1] ? '+1 ' : '');
                return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
            }
            return null;
        }
        return (
            <div className="reports_wrap">
                {/* {showNewStore &&
                    <NewStoreModalWrap
                        showNewStore={showNewStore}
                        setShowNewStore={setShowNewStore}
                        vendorId={vendorId}
                    />
                } */}
                {showSuspendModal && <SuspendVendorModal pageProps={pageProps} showSuspendModal={showSuspendModal} setShowSuspendModal={setShowSuspendModal} />}
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className="report_heading" onClick={() => history.push(`/app/vendors`)}><img className="back_ico" src={BackIco} alt="" />  <span className="main_head_txt">{vendorContact?.name}</span>  </div>
                    </div>
                    <div className="report_head_right">
                        <button
                            className="btn add_store_cta"
                            onClick={() => {
                                pageProps.getStoreUrlAction("CLEAR")
                                pageProps.getStoreCategoriesAction(`Page=1&Size=1000`)
                                pageProps.addNewStoreAction("CLEAR")
                                setShowNewStore(true)}
                            }
                        >
                            <span>+</span> Add New Store
                        </button>
                        <button
                            onClick={() => {
                                toggleUser({
                                    pageProps,
                                    payload: "CLEAR"
                                }).then(() => {
                                })
                                setShowSuspendModal(true)
                            }}
                            className="red_cta"><img src={SuspendIco} alt="" /> Suspend Vendor</button>
                    </div>

                </div>
                <div className="vendor_summary_top">
                    <div className="vendor_info_wrap for_vendor">
                        <div className="vendor_top_info">
                            <div className="info_icon"><img src={ContactIco} alt="" /> </div>
                            <div className="info_txt">Contact details</div>
                        </div>
                        <div className="vendor_bottom_info">
                            <div className="main_text_item">{vendorContact?.phoneNumber ? formatPhoneNumber(vendorContact?.phoneNumber) : "N/A"}</div>
                            <div className="other_txt_item">{vendorContact?.email}</div>
                        </div>
                    </div>
                    <div className="vendor_info_wrap for_vendor">
                        <div className="vendor_top_info">
                            <div className="info_icon"><img src={All_Stores} alt="" /> </div>
                            <div className="info_txt">Number of stores</div>
                        </div>
                        <div className="vendor_bottom_info">
                            <div className="main_text_item">{vendorDetails?.stores ? vendorDetails?.stores.length : "N/A"}</div>
                            {vendorDetails?.stores &&
                                <div className="other_txt_item">
                                    {vendorDetails.stores.map((eachStore: any, index: any) => {

                                        return (<span key={index}>{eachStore?.name}{vendorDetails?.stores.length > index + 1 ? ", " : ""} </span>)
                                    }
                                    )}</div>
                            }
                        </div>
                    </div>
                    <div className="vendor_info_wrap for_vendor">
                        <div className="vendor_top_info">
                            <div className="info_icon"><img src={All_Earn} alt="" /> </div>
                            <div className="info_txt">Total Earnings</div>
                        </div>
                        <div className="vendor_bottom_info">
                            <div className="main_text_item">${vendorDetails?.totalEarnings ? vendorDetails?.totalEarnings.toLocaleString() : "N/A"}</div>
                            <div className="other_txt_item">from last month</div>
                        </div>
                    </div>
                    <div className="vendor_info_wrap for_vendor">
                        <div className="vendor_top_info">
                            <div className="info_icon"><img src={All_Feedback} alt="" /> </div>
                            <div className="info_txt">Customer Perception</div>
                        </div>
                        {
                            vendorDetailsRatings.length >= 1 &&
                            <div className="vendor_bottom_info">
                                <div className="main_text_item">${averageRating > 0 ? RatingRemarks() : ""}</div>
                                <div className="other_txt_item"> <span>{averageRating} </span>rating across all stores</div>
                            </div>
                        }
                        {
                            vendorDetailsRatings.length === 0 &&
                            <div className="vendor_bottom_info">
                                <div className="main_text_item">No ratings yet</div>
                            </div>
                        }
                    </div>
                </div>
                <VendorStoresList storeList={allStoreChart} />

            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                {pageProps.getVendorDetailsRequest.request_status === appConstants.GET_VENDOR_DETAILS_PENDING &&
                    <LoadingItem />
                }
                {showNewStore &&
                    <NewStoreModalWrap
                        showNewStore={showNewStore}
                        setShowNewStore={setShowNewStore}
                        vendorId={vendorId}
                    />
                }
                {pageProps.getVendorDetailsRequest.request_status === appConstants.GET_VENDOR_DETAILS_SUCCESS &&
                    <ReportsDisplay />
                }
                {pageProps.getVendorDetailsRequest.request_status === appConstants.GET_VENDOR_DETAILS_FAILURE &&
                    <AlertMsg type="error" message={pageProps.getVendorDetailsRequest?.request_data.error} />
                }
            </div>
        </div>
    )
}

const VendorDetails = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Vendor Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    toggleUserAction: appActions.ToggleUser,
    getVendorDetailsAction: appActions.GetVendorDetails,
    getVendorStoresAction: appActions.GetVendorStores,

    getStoreUrlAction: appActions.GetStoreUrl,
    getStoreCategoriesAction: appActions.GetStoreCategories,
    addNewStoreAction: appActions.AddNewStore,
};

const mapStateToProps = (state: any) => ({
    toggleUserRequest: state.allInAppReducers.toggleUserReducer,
    getVendorDetailsRequest: state.allInAppReducers.getVendorDetailsReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(VendorDetails);