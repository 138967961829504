import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';

import FilterIcon from "../../../assets/icons/filter.svg"
import "./index.scss";

const FilterModal = (filterProps: any) => {
    const [chosenCity, setChosenCity] = useState<any>();
    let today = new Date();
    const [startDate, setStartDate] = useState<any>();
    // const [startDate, setStartDate] = useState<any>(new Date(new Date().setDate(today.getDate() - 7)));
    const [endDate, setEndDate] = useState<any>();
    // const [endDate, setEndDate] = useState<any>(today);
    const [startOpen, setStartOpen] = useState<boolean>(false);
    const [bothDateChosen, setBothDateChosen] = useState<boolean>(true);
    let { showFilter, setShowFilter } = filterProps;
    const citiesList = [
        { label: "Winnipeg", value: "Winnipeg" },
        { label: "Toronto", value: "Toronto" },
        { label: "Vancouver", value: "Vancouver" }
    ]

    const onDateChange = (dates: any) => {
        const [start, end] = dates;
    

        setStartDate(start);
        setEndDate(end);
        // setDateWasChosen(true)

        if (start && end) {
            setBothDateChosen(true)
        } else {
            setBothDateChosen(false)
        }
    };
    return (
        <Modal className="filter_modal_wrap" show={showFilter} onHide={() => setShowFilter(false)}>
            <Modal.Header closeButton>
                Search Filter
            </Modal.Header>
            <Modal.Body>
                <div className="filter_sections">
                    <div className="each_filter_section">
                        <div className="filter_heading_text">Province</div>

                        <div className="all_province">
                            <div className="each_province">
                                <input type="radio" name="province_option" id="province_option_alberta" />
                                <label htmlFor="province_option_alberta">Alberta</label>
                            </div>
                            <div className="each_province">
                                <input type="radio" name="province_option" id="province_option_nvs" />
                                <label htmlFor="province_option_nvs">Nova Scotia</label>
                            </div>
                            <div className="each_province">
                                <input type="radio" name="province_option" id="province_option_btc" />
                                <label htmlFor="province_option_btc">British Columbia</label>
                            </div>
                            <div className="each_province">
                                <input type="radio" name="province_option" id="province_option_ont" />
                                <label htmlFor="province_option_ont">Ontario</label>
                            </div>
                            <div className="each_province">
                                <input type="radio" name="province_option" id="province_option_man" />
                                <label htmlFor="province_option_man">Manitoba</label>
                            </div>
                            <div className="each_province">
                                <input type="radio" name="province_option" id="province_option_ed" />
                                <label htmlFor="province_option_ed">Prince Edward Island</label>
                            </div>
                            <div className="each_province">
                                <input type="radio" name="province_option" id="province_option_newb" />
                                <label htmlFor="province_option_newb">New Brunswick</label>
                            </div>
                            <div className="each_province">
                                <input type="radio" name="province_option" id="province_option_Quebec" />
                                <label htmlFor="province_option_Quebec">Quebec</label>
                            </div>
                            <div className="each_province">
                                <input type="radio" name="province_option" id="province_option_newfd" />
                                <label htmlFor="province_option_newfd">Newfoundland and Labrador</label>
                            </div>
                            <div className="each_province">
                                <input type="radio" name="province_option" id="province_option_sas" />
                                <label htmlFor="province_option_sas">Saskachewan</label>
                            </div>

                        </div>
                    </div>
                    <div className="each_filter_section">
                        <div className="filter_heading_text">City</div>
                        <Select
                            value={chosenCity}
                            onChange={setChosenCity}
                            options={citiesList}
                            placeholder={<div className="select-placeholder-text">Select City</div>} 
                            className="city-select-wrap"
                            components={{
                                IndicatorSeparator: () => null
                              }}

                        // ref={catChosenRef}
                        />
                    </div>
                    <div className="each_filter_section  date_filter">
                        <div className="filter_heading_text">Date range</div>
                        <DatePicker
                            selected={startDate}
                            onChange={onDateChange}
                            startOpen={startOpen}
                            // disabled={!startOpen}
                            placeholderText="Select date range"
                            dateFormat="dd/MM/yy"
                            maxDate={new Date()}
                            monthsShown={2}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            className="filter_date_range"
                            withPortal
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="reset_cta btn">Reset changes</button>
                <button className="filter_cta btn">Done</button>
            </Modal.Footer>
        </Modal>
    )
}

export const FilterUtil = () => {
    const [showFilter, setShowFilter] = useState<boolean>(false)
    return (
        <div className="filter_wrapper">
            {showFilter &&
                <FilterModal showFilter={showFilter} setShowFilter={setShowFilter} />
            }
            <div className="filter_cta">
                <button onClick={() => setShowFilter(true)}>
                    Search Filter
                    <img src={FilterIcon} alt="" />
                </button>
            </div>
        </div>
    )
}