
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from "react-router-dom"
import Modal from 'react-bootstrap/Modal'
import { InAppTemplate } from "../../../shared/templates/inapp";

import { TableComponent } from "../../../shared/components/table"


import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { getDateFromISO } from "../../../shared/utils"

import { AlertMsg } from "../../../shared/components/alert-msg"


import { LoadingItem } from "../../../shared/components/loading"
import DownloadIco from "../../../assets/download.svg"
import BackIco from "../../../assets/back.svg"
import SuspendIco from "../../../assets/icons/suspend.svg"


import { appActions } from "../../../redux/actions/in-app"
import { appConstants } from "../../../redux/action-constants/inapp.constants";



import { history } from "../../../shared/_helpers/history";

import TrendUp from "../../../assets/trend_up.svg"
import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ArcElement,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const getStoreDetails = async ({ pageProps, payload }: any) => {

    await pageProps.getStoreDetailsAction(payload);
}
const getAStoreChartAction = async ({ pageProps, payload }: any) => {

    await pageProps.getAStoreChartData(payload);
}

const getStoreTransactions = async ({ pageProps, payload }: any) => {

    await pageProps.getStoreTransactionsAction(payload);
}

const toggleStoreAction = async ({ pageProps, payload, action }: any) => {

    await pageProps.toggleStoreAction(payload, action);
}

const SuspendStoreModal = (props: any) => {
    let { showSuspendModal, setShowSuspendModal, pageProps, storeId } = props;

    let checkValidationSchema = Yup.object().shape({
        reason: Yup.string().trim()
            .required('Required'),

    });



    const suspendProcess: any = (payload: any) => {
        toggleStoreAction({
            pageProps,
            payload,
            action: "suspend"
        }).then(() => {
        })
    }

    return (
        <Modal className="suspend_wrap" show={showSuspendModal} onHide={() => {
            if (!pageProps?.toggleStoreRequest?.is_request_processing) {
                setShowSuspendModal(false)
            }
        }
        }>
            <Modal.Header closeButton>
                Suspend store?
            </Modal.Header>
            <Modal.Body>
                {pageProps?.toggleStoreRequest?.request_status !== appConstants.TOGGLE_STORE_SUCCESS &&
                    <>
                        <div className="head_message">
                            Please be advised that suspending this store will  means that <span> customers will be unable to make purchases from this store</span> until the suspension is lifted. Are you sure you want to proceed with this action?
                        </div>
                        <div className="suspend_form">
                            <Formik
                                initialValues={{
                                    reason: ""
                                }}

                                validationSchema={checkValidationSchema}
                                onSubmit={(values) => {
                                    let { reason } = values;
                                    let payload = {
                                        ids: [
                                            storeId
                                        ],
                                        reason
                                    }

                                    suspendProcess(payload)




                                }}
                            >
                                {(props: FormikProps<any>) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        // handleBlur,
                                        handleChange,
                                        // isSubmitting,
                                    } = props;

                                    return (
                                        <Form>


                                            {!pageProps.toggleStoreRequest?.is_request_processing &&
                                                <textarea value={values.reason}
                                                    name="reason" id="reason"
                                                    placeholder='Reason for suspension'
                                                    onChange={handleChange('reason')}
                                                    className={` ${errors.reason && touched.reason ?
                                                        "is-invalid-input "
                                                        : !errors.reason && values.reason !== "" ? "valid-input" : ""}`}
                                                    rows={10}></textarea>
                                            }
                                            <ErrorMessage name="reason" className="form-input-error" component="div" />
                                            {pageProps?.toggleStoreRequest?.request_status
                                                && pageProps.toggleStoreRequest.request_status === appConstants.TOGGLE_STORE_FAILURE &&
                                                <AlertMsg type="error" message={pageProps.toggleStoreRequest?.request_data.error} />
                                            }
                                            <div className="suspend_ctas">
                                                <button className="btn cancel"
                                                    disabled={pageProps.toggleStoreRequest?.is_request_processing}
                                                    onClick={() => setShowSuspendModal(false)}
                                                > Cancel</button>
                                                <button
                                                    type="submit"
                                                    className="btn confirm"
                                                    disabled={pageProps.toggleStoreRequest?.is_request_processing}
                                                >
                                                    {pageProps.toggleStoreRequest.is_request_processing ? "Please wait..." : "Confirm suspension"}
                                                </button>
                                            </div>




                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </>
                }
                {pageProps?.toggleStoreRequest?.request_status === appConstants.TOGGLE_STORE_SUCCESS &&
                    <AlertMsg type="success" message="Store was successfully suspended" />
                }
            </Modal.Body>
        </Modal>
    )

}

const SalesChart = ({ salesData }: any) => {
    
    let chartValues: any[] = salesData.map((each: any) => each?.count)
    let chartLabels: any[] = salesData.map((each: any) => each?.grouping)
    const options = {
        responsive: true,
        cutout: 120,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
            title: {
                display: false,
                // text: 'Chart.js Line Chart',
            },
        },
        tension: 0.5
    };
    const data6 = {
        labels: chartLabels,
        datasets: [
            {
                label: '',
                data: chartValues,
                borderColor: '#00A85B',
                borderWidth: 2,
                // backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            // {
            //     label: '',
            //     data: labelValues2,
            //     borderColor: 'rgb(80, 143, 244)',
            //     borderWidth: 5,
            //     // backgroundColor: 'rgba(255, 99, 132, 0.5)',
            // },

        ],
    };
    return (
        <>
            {salesData.length >= 1 &&
                <Line options={options} data={data6} />
            }

            {salesData.length === 0 &&
                <div className="no-data">No Data for specified period</div>
            }
        </>
    )
}


const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();
    const { storeId } = useParams();
    const { state } = useLocation();
    const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false);

    useEffect(() => {
        getStoreDetails({
            pageProps,
            payload: `?Id=${storeId}`
        })


        getStoreTransactions({
            pageProps,
            payload: `?StoreId=${storeId}`
        })


        let graphPayload = `StoreId=${storeId}&ReportGroupingType=${salesFlag}`;

        getAStoreChartAction({
            pageProps,
            payload: graphPayload
        })








    }, [])

    const ReportTable = () => {
        let txtnList = pageProps.getStoreTransactionsRequest?.request_data?.response?.data;
        let alltxtnList: any[] = txtnList?.results;
        return (
            <>
                <thead>
                    <tr>
                        <th className="left_top"> <span >Transaction date</span> </th>
                        <th> Transaction ID</th>
                        <th>Amount</th>
                        {/* <th>Fee</th> */}
                        <th>Status</th>
                        {/* <th>Paid out</th> */}
                        <th className="right_top"> <span>Invoice</span> </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        alltxtnList.map((eachItem: any, index: any) => {
                            return (
                                <tr>

                                    <td>{eachItem.createdDate ? getDateFromISO({ date: eachItem.createdDate, returnTime: true }) : "N/A"}</td>
                                    <td className="grayed">{eachItem.accountNumber}</td>
                                    <td className="grayed">${eachItem?.amount}</td>
                                    {/* <td className="grayed"> -</td> */}
                                    <td> <span className="order_status ongoing ">{eachItem?.payoutStatus}</span></td>
                                    {/* <td className="branded">-</td> */}

                                    <td className="branded"><span>Download <img src={DownloadIco} alt="" /> </span> </td>


                                </tr>
                            )
                        })
                    }

                </tbody>
            </>
        )
    }


    const ReportsDisplay = () => {
        let storeDetails = pageProps.getStoreDetailsRequest?.request_data?.response?.data;
        // const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false);

        return (
            <div className="reports_wrap">
                {showSuspendModal && <SuspendStoreModal pageProps={pageProps} storeId={storeId} showSuspendModal={showSuspendModal} setShowSuspendModal={setShowSuspendModal} />}
                <div className="report_head_wrap">
                    <div className="report_head_left" onClick={() => history.push(`/app/stores`)}>
                        <div className="report_heading"><img className="back_ico" src={BackIco} alt="" />  <span className="main_head_txt">{storeDetails?.name}</span>  </div>
                    </div>
                    {storeDetails?.isActive && <div className="report_head_right"> <button className="red_cta" onClick={() => {
                        toggleStoreAction({
                            pageProps,
                            payload: "CLEAR",
                            action: "suspend"
                        })
                        setShowSuspendModal(true)
                    }}>
                        <img src={SuspendIco} alt="" />
                        Suspend Store</button>
                    </div>
                    }

                </div>
                <div className="vendor_summary_top">
                    <div className="vendor_info_wrap">
                        <div className="info_wrap">
                            <div className="each_vendor_info">
                                <div className="vendor_info_head">Store Owner</div>
                                <div className="vendor_info_txt">{storeDetails?.storeOwner}</div>
                            </div>
                            <div className="each_vendor_info">
                                <div className="vendor_info_head">Contact number</div>
                                <div className="vendor_info_txt">{storeDetails?.phoneNumber}</div>
                            </div>
                        </div>
                        <div className="each_vendor_info">
                            <div className="vendor_info_head">Email</div>
                            <div className="vendor_info_txt">{storeDetails?.email}</div>
                        </div>
                        <div className="each_vendor_info">
                            <div className="vendor_info_head">Address</div>
                            <div className="vendor_info_txt">{storeDetails?.address}</div>
                        </div>
                        <div className="info_wrap">
                            <div className="each_vendor_info">
                                <div className="vendor_info_head">Total sales</div>
                                <div className="vendor_info_txt with_green">${storeDetails?.totalSales}</div>
                            </div>
                            <div className="each_vendor_info">
                                <div className="vendor_info_head">{storeDetails?.totalProducts > 0 ? "Number of products" : "Products"}</div>
                                {storeDetails?.totalProducts > 0 &&
                                    <div className="vendor_info_txt with_green">
                                        <span className="unset_txt" >
                                            {storeDetails?.totalProducts} -</span>
                                        <Link state={{ storeData: { storeName: storeDetails?.name, storeId } }} className="page-Link" to={`/app/stores/${storeId}/products`}>View Products</Link>
                                    </div>
                                }
                                {storeDetails?.totalProducts === 0 &&
                                    <div className="vendor_info_txt with_green">
                                        <Link state={{ storeData: { storeName: storeDetails?.name, storeId } }} className="page-Link" to={`/app/stores/${storeId}/products/create-new`}>Create A Product</Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="store_chart_wrap">
                        {pageProps.getStoreChartRequest.request_status === appConstants.GET_A_STORE_CHART_PENDING &&
                            <LoadingItem />
                        }
                        {pageProps.getStoreChartRequest.request_status === appConstants.GET_A_STORE_CHART_SUCCESS &&
                            <div className="all-sale-stats cards">
                            <div className="stats-heading">
                                <div className="heading-txt">Sales Report</div>
                                <div className="stats-heading">
                                    <div className={salesFlag === 1 ? "each-stat-head  active" : "each-stat-head"} onClick={() => setSalesFlag(1)} >6 Months</div>
                                    <div className={salesFlag === 3 ? "each-stat-head  active" : "each-stat-head"} onClick={() => setSalesFlag(3)}  >7 days</div>
                                    {/* <div className="each-stat-head">30 days</div> */}
                                </div>

                                <div className="goto-store">
                                    {/* <img src={ExportIco} alt="" /> */}
                                    <Link to={`/app/stores/${storeId}/details`}>
                                        <span>View Orders <img src={TrendUp} alt="" /> </span>
                                    </Link>
                                </div>

                            </div>
                            <div className="allstats">

                                <div className="each-chartwrap" id="chart-sales">
                                    <SalesChart salesData={pageProps.getStoreChartRequest?.request_data?.response} />

                                </div>
                            </div>
                        </div>
                        }
                        {pageProps.getStoreChartRequest.request_status === appConstants.GET_A_STORE_CHART_FAILURE &&
                            <AlertMsg type="error" message={pageProps.getStoreChartRequest?.request_data.error} />
                        }
                    </div>
                </div>
                {pageProps.getStoreTransactionsRequest.request_status === appConstants.GET_STORE_PAYMENT_HISTORY_PENDING &&
                    <LoadingItem />
                }
                {pageProps.getStoreTransactionsRequest.request_status === appConstants.GET_STORE_PAYMENT_HISTORY_SUCCESS &&
                    <div className="reports_table">
                        <div className="report_actions">
                            <input type="text" className="search_table" placeholder="Type in a transaction ID" />

                        </div>
                        <TableComponent
                            childComponent={<ReportTable />}
                        // childComponent={<ReportTable reportData={pageProps.getStoreOrdersRequest.request_data.response} />}
                        />
                    </div>
                }
                {pageProps.getStoreTransactionsRequest.request_status === appConstants.GET_STORE_PAYMENT_HISTORY_FAILURE &&
                    <AlertMsg type="error" message={pageProps.getStoreTransactionsRequest?.request_data.error} />
                }
            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                {pageProps.getStoreDetailsRequest.request_status === appConstants.GET_STORE_DETAILS_PENDING &&
                    <LoadingItem />
                }
                {pageProps.getStoreDetailsRequest.request_status === appConstants.GET_STORE_DETAILS_SUCCESS &&
                    <ReportsDisplay />
                }
                {pageProps.getStoreDetailsRequest.request_status === appConstants.GET_STORE_DETAILS_FAILURE &&
                    <AlertMsg type="error" message={pageProps.getStoreDetailsRequest?.request_data.error} />
                }
            </div>
        </div>
    )
}

const StoreFullDetails = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Vendor Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getStoreDetailsAction: appActions.GetStoreDetails,
    getStoreTransactionsAction: appActions.GetStoreTransactions,
    toggleStoreAction: appActions.ToggleStore,
    getAStoreChartData: appActions.GetAStoreChart,

};

const mapStateToProps = (state: any) => ({
    getStoreDetailsRequest: state.allInAppReducers.getStoreDetailsReducer,
    getStoreTransactionsRequest: state.allInAppReducers.getStoreTransactionsReducer,
    toggleStoreRequest: state.allInAppReducers.toggleStoreReducer,
    getStoreChartRequest: state.allInAppReducers.getStoreChartReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(StoreFullDetails);