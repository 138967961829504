export const authConstants ={
    
    LOGIN_USER_SUCCESS : 'LOGIN_USER_SUCCESS',
    LOGIN_USER_PENDING : 'LOGIN_USER_PENDING',
    LOGIN_USER_FAILURE : 'LOGIN_USER_FAILURE',
    LOGIN_USER_RESET : 'LOGIN_USER_RESET',
   
    LOGOUT: 'USERS_LOGOUT',

    LOGOUT_USER_SUCCESS : 'LOGOUT_USER_SUCCESS',
    LOGOUT_USER_PENDING : 'LOGOUT_USER_PENDING',
    LOGOUT_USER_FAILURE : 'LOGOUT_USER_FAILURE',
    LOGOUT_USER_RESET :   'LOGOUT_USER__RESET',

    CHANGE_PASSWORD_SUCCESS : 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_PENDING : 'CHANGE_PASSWORD_PENDING',
    CHANGE_PASSWORD_FAILURE : 'CHANGE_PASSWORD_FAILURE',
    CHANGE_PASSWORD_RESET :   'CHANGE_PASSWORD__RESET',

    FORGOT_PASSWORD_SUCCESS : 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_PENDING : 'FORGOT_PASSWORD_PENDING',
    FORGOT_PASSWORD_FAILURE : 'FORGOT_PASSWORD_FAILURE',
    FORGOT_PASSWORD_RESET :   'FORGOT_PASSWORD_RESET',

  

    COMPLETE_PASSWORD_RESET_SUCCESS : 'COMPLETE_PASSWORD_RESET_SUCCESS',
    COMPLETE_PASSWORD_RESET_PENDING : 'COMPLETE_PASSWORD_RESET_PENDING',
    COMPLETE_PASSWORD_RESET_FAILURE : 'COMPLETE_PASSWORD_RESET_FAILURE',
    COMPLETE_PASSWORD_RESET_RESET :   'COMPLETE_PASSWORD_RESET_RESET',
}