import React, { useState, useEffect } from 'react';
import { ChildrenCompProps } from "../../types";
import "../../scss/onboarding.scss"
import Logo from "../../assets/white_logo.png"


export const PostBoardingWrap: React.FC<ChildrenCompProps> = (props) => {
    const { childComponent, pageHeadingTitle } = props;
    // localStorage.removeItem("mami-Super-XAuth");
    // localStorage.removeItem("state");
    
    return (
        <div className="postboarding-wrap">
            <div className="postboarding-container">
                <div className="page-meta">
                    <div className="logo-wrap">
                        <img src={Logo} alt="Logo" />
                    </div>
                    {pageHeadingTitle &&
                        <div className="page-title">{pageHeadingTitle}</div>
                    }
                </div>
                <div className="postboard-form">
                    {childComponent}
                </div>
            </div>
        </div>
    )
}