import { HelpDetails } from "./help-details"
import { useState } from "react"
import './help.scss'
import { TbTag } from 'react-icons/tb'
import { PiDotOutlineFill, PiDotsThreeVerticalBold } from 'react-icons/pi'
import { BsCheckCircleFill } from 'react-icons/bs'
import user from '../../../assets/user.png'
import { AssignModal } from "./assign-modal"
import { Assignees } from "./assignee-list"
import { SupportChat } from "./support-chat"
import { assign } from "lodash"

type props = {
    ticket: string
    time: string | number
    title: string
    description: string
    status: 'Urgent' | 'Medium' | 'Low'
    type: "Not started" | "Ongoing" | "Resolved",
    customer?: string,
    message?: string,
    resolved?: string,
    selectedTab: number
}
export const Card: React.FC<props> = ({ ticket, time, title, description, status, type, customer, message, resolved, selectedTab }) => {
    const [openModal, setOpenModal] = useState(false)
    const [open, setOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [ongoingOpen, setOngoingOpen] = useState(false)


    const handleOpen = () => {
        setOpen(true)
    }
    const handleChatOpen = () => {
        setOngoingOpen(true)
        setIsOpen(true)
    }
    const handleChatClose = () => {
        setOngoingOpen(false)
        setIsOpen(false)
    }
    const handleModalOpen = () => {
        setIsOpen(false)
        setOpenModal(true)
    }
    const handleModalClose = () => {
        setOpen(false)
        setOpenModal(false)
    }
    return (
        <div className='card'>
            <div className='space'>
                <p className='ticket'>Ticket ID #{ticket}</p>
                {
                    type === 'Not started'
                        ? <p className='new'>{time}</p>
                        : type === 'Ongoing'
                            ? <PiDotsThreeVerticalBold />
                            : null
                }
            </div>
            <h6 className='card-title' onClick={() => handleChatOpen()} style={{cursor: 'pointer'}}>{title}</h6>
            {
                type === 'Not started'
                    ? <p className='description'>{description}</p>
                    : type === 'Ongoing'
                        ? <p className='description'>{description}</p>
                        : <div>
                            <p className="description2">Customer: {customer}</p>
                            <p className="description2">Resolved: {resolved}</p>
                            <span style={{ display: 'flex', alignItems: 'center' }}><p className="description2">Priority: </p><p className={
                                status === 'Urgent' ? 'priority1'
                                    : status === 'Medium' ? 'priority2'
                                        : 'priority3'
                            }>{status}</p></span>
                            <p className="description2">Messages: {message}</p>
                        </div>
            }
            <div className='space'>
                <div className='center'>
                    {
                        type === 'Not started'
                            ? <div className="center">
                                <TbTag color='#00A85B' />
                                <p className='text' onClick={() => handleModalOpen()}>Assign issue</p>
                            </div>
                            : type === 'Ongoing'
                                ? <div className="user" onClick={() => handleOpen()}>
                                    <img src={user} />
                                </div>
                                : <div className="user">
                                    <img src={user} />
                                </div>

                    }
                </div>
                {
                    type === 'Not started'
                        ? <div className={
                            status === 'Urgent'
                                ? 'fill'
                                : status === 'Medium'
                                    ? 'fill2' : 'fill3'
                        }>
                            <PiDotOutlineFill size={15} color={
                                status === 'Urgent'
                                    ? '#EC3237'
                                    : status === 'Medium'
                                        ? '#D89127' : '#2783D8'} />
                            <p className={
                                status === 'Urgent' ? 'text2'
                                    : status === 'Medium' ? 'text3'
                                        : 'text4'
                            }>{status}</p>
                        </div>
                        : type === 'Ongoing'
                            ? <div className={
                                status === 'Urgent'
                                    ? 'fill'
                                    : status === 'Medium'
                                        ? 'fill2' : 'fill3'
                            }>
                                <PiDotOutlineFill size={15} color={
                                    status === 'Urgent'
                                        ? '#EC3237'
                                        : status === 'Medium'
                                            ? '#D89127' : '#2783D8'} />
                                <p className={
                                    status === 'Urgent' ? 'text2'
                                        : status === 'Medium' ? 'text3'
                                            : 'text4'
                                }>{status}</p>
                            </div>
                            : <BsCheckCircleFill color="#00A85B" size={22} />
                }
            </div>
            {openModal && 
                    <AssignModal
                     content={Assignees}
                      title="Assign issue"
                      handleModalClose={handleModalClose}
                    />
            }
               {open && 
                    <AssignModal
                     content={Assignees}
                      title="Re-assign issue"
                      handleModalClose={handleModalClose}
                    />
            }
             {
                isOpen && selectedTab === 0 ?
                    <SupportChat assign="Assign issue" handleChatClose={handleChatClose} handleModalOpen={handleModalOpen} />
                    : null
            }
            {
                ongoingOpen && selectedTab === 1 ?
                    <SupportChat assign="Re-assign issue" assignee="Gabriella Daniella" handleChatClose={handleChatClose} handleModalOpen={handleModalOpen} />
                    : null
            }
           
        </div>
    )
}