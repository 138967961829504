export const appConstants ={
    GOOGLE_MAP_KEY : "AIzaSyBgo1zf5mTGQpvXu4hPjQ6c5Cg2Ppo4Klc",
  
    CREATE_PRODUCT_SUCCESS : 'CREATE_PRODUCT_SUCCESS',
    CREATE_PRODUCT_PENDING : 'CREATE_PRODUCT_PENDING',
    CREATE_PRODUCT_FAILURE : 'CREATE_PRODUCT_FAILURE',
    CREATE_PRODUCT_RESET : 'CREATE_PRODUCT_RESET',

    UPLOAD_MULTIPLE_SUCCESS : 'UPLOAD_MULTIPLE_SUCCESS',
    UPLOAD_MULTIPLE_PENDING : 'UPLOAD_MULTIPLE_PENDING',
    UPLOAD_MULTIPLE_FAILURE : 'UPLOAD_MULTIPLE_FAILURE',
    UPLOAD_MULTIPLE_RESET : 'UPLOAD_MULTIPLE_RESET',

    UPDATE_PRODUCT_SUCCESS : 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_PENDING : 'UPDATE_PRODUCT_PENDING',
    UPDATE_PRODUCT_FAILURE : 'UPDATE_PRODUCT_FAILURE',
    UPDATE_PRODUCT_RESET : 'UPDATE_PRODUCT_RESET',
    

    UPLOAD_PRODUCT_PICTURE_SUCCESS : 'UPLOAD_PRODUCT_PICTURE_SUCCESS',
    UPLOAD_PRODUCT_PICTURE_PENDING : 'UPLOAD_PRODUCT_PICTURE_PENDING',
    UPLOAD_PRODUCT_PICTURE_FAILURE : 'UPLOAD_PRODUCT_PICTURE_FAILURE',
    UPLOAD_PRODUCT_PICTURE_RESET : 'UPLOAD_PRODUCT_PICTURE_RESET',

    GET_PRODUCTS_SUCCESS : 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_PENDING : 'GET_PRODUCTS_PENDING',
    GET_PRODUCTS_FAILURE : 'GET_PRODUCTS_FAILURE',
    GET_PRODUCTS_RESET : 'GET_PRODUCTS_RESET',

    GET_A_PRODUCT_SUCCESS : 'GET_A_PRODUCT_SUCCESS',
    GET_A_PRODUCT_PENDING : 'GET_A_PRODUCT_PENDING',
    GET_A_PRODUCT_FAILURE : 'GET_A_PRODUCT_FAILURE',
    GET_A_PRODUCT_RESET : 'GET_A_PRODUCT_RESET',

    GET_PRODUCT_META_SUCCESS : 'GET_PRODUCT_META_SUCCESS',
    GET_PRODUCT_META_PENDING : 'GET_PRODUCT_META_PENDING',
    GET_PRODUCT_META_FAILURE : 'GET_PRODUCT_META_FAILURE',
    GET_PRODUCT_META_RESET : 'GET_PRODUCT_META_RESET',

    GET_IMAGE_GALLERY_SUCCESS : 'GET_IMAGE_GALLERY_SUCCESS',
    GET_IMAGE_GALLERY_PENDING : 'GET_IMAGE_GALLERY_PENDING',
    GET_IMAGE_GALLERY_FAILURE : 'GET_IMAGE_GALLERY_FAILURE',
    GET_IMAGE_GALLERY_RESET : 'GET_IMAGE_GALLERY_RESET',
    
    GET_IMAGE_GALLERY_NEWBATCH : 'GET_IMAGE_GALLERY_NEWBATCH',

    GET_VENDOR_ORDERS_SUCCESS : 'GET_VENDOR_ORDERS_SUCCESS',
    GET_VENDOR_ORDERS_PENDING : 'GET_VENDOR_ORDERS_PENDING',
    GET_VENDOR_ORDERS_FAILURE : 'GET_VENDOR_ORDERS_FAILURE',
    GET_VENDOR_ORDERS_RESET : 'GET_VENDOR_ORDERS_RESET',

    GET_ORDER_DETAILS_SUCCESS : 'GET_ORDER_DETAILS_SUCCESS',
    GET_ORDER_DETAILS_PENDING : 'GET_ORDER_DETAILS_PENDING',
    GET_ORDER_DETAILS_FAILURE : 'GET_ORDER_DETAILS_FAILURE',
    GET_ORDER_DETAILS_RESET : 'GET_ORDER_DETAILS_RESET',

    CREATE_PAYOUT_ACCOUNT_SUCCESS : 'CREATE_PAYOUT_ACCOUNT_SUCCESS',
    CREATE_PAYOUT_ACCOUNT_PENDING : 'CREATE_PAYOUT_ACCOUNT_PENDING',
    CREATE_PAYOUT_ACCOUNT_FAILURE : 'CREATE_PAYOUT_ACCOUNT_FAILURE',
    CREATE_PAYOUT_ACCOUNT_RESET : 'CREATE_PAYOUT_ACCOUNT_RESET',

    GET_PAYOUT_PENDING_LINK_SUCCESS : 'GET_PAYOUT_PENDING_LINK_SUCCESS',
    GET_PAYOUT_PENDING_LINK_PENDING : 'GET_PAYOUT_PENDING_LINK_PENDING',
    GET_PAYOUT_PENDING_LINK_FAILURE : 'GET_PAYOUT_PENDING_LINK_FAILURE',
    GET_PAYOUT_PENDING_LINK_RESET : 'GET_PAYOUT_PENDING_LINK_RESET',

    GET_STORE_PAY_ACCOUNTS_SUCCESS : 'GET_STORE_PAY_ACCOUNTS_SUCCESS',
    GET_STORE_PAY_ACCOUNTS_PENDING : 'GET_STORE_PAY_ACCOUNTS_PENDING',
    GET_STORE_PAY_ACCOUNTS_FAILURE : 'GET_STORE_PAY_ACCOUNTS_FAILURE',
    GET_STORE_PAY_ACCOUNTS_RESET : 'GET_STORE_PAY_ACCOUNTS_RESET',

    GET_STORE_PAYMENT_HISTORY_SUCCESS : 'GET_STORE_PAYMENT_HISTORY_SUCCESS',
    GET_STORE_PAYMENT_HISTORY_PENDING : 'GET_STORE_PAYMENT_HISTORY_PENDING',
    GET_STORE_PAYMENT_HISTORY_FAILURE : 'GET_STORE_PAYMENT_HISTORY_FAILURE',
    GET_STORE_PAYMENT_HISTORY_RESET : 'GET_STORE_PAYMENT_HISTORY_RESET',

    TOGGLE_A_PRODUCT_SUCCESS : 'TOGGLE_A_PRODUCT_SUCCESS',
    TOGGLE_A_PRODUCT_PENDING : 'TOGGLE_A_PRODUCT_PENDING',
    TOGGLE_A_PRODUCT_FAILURE : 'TOGGLE_A_PRODUCT_FAILURE',
    TOGGLE_A_PRODUCT_RESET : 'TOGGLE_A_PRODUCT_RESET',

    GET_STORE_DETAILS_SUCCESS : 'GET_STORE_DETAILS_SUCCESS',
    GET_STORE_DETAILS_PENDING : 'GET_STORE_DETAILS_PENDING',
    GET_STORE_DETAILS_FAILURE : 'GET_STORE_DETAILS_FAILURE',
    GET_STORE_DETAILS_RESET : 'GET_STORE_DETAILS_RESET',

    UPDATE_STORE_DETAILS_SUCCESS : 'UPDATE_STORE_DETAILS_SUCCESS',
    UPDATE_STORE_DETAILS_PENDING : 'UPDATE_STORE_DETAILS_PENDING',
    UPDATE_STORE_DETAILS_FAILURE : 'UPDATE_STORE_DETAILS_FAILURE',
    UPDATE_STORE_DETAILS_RESET : 'UPDATE_STORE_DETAILS_RESET',


    GET_DASHBOARD_BOTTOM_ONE_SUCCESS : 'GET_DASHBOARD_BOTTOM_ONE_SUCCESS',
    GET_DASHBOARD_BOTTOM_ONE_PENDING : 'GET_DASHBOARD_BOTTOM_ONE_PENDING',
    GET_DASHBOARD_BOTTOM_ONE_FAILURE : 'GET_DASHBOARD_BOTTOM_ONE_FAILURE',
    GET_DASHBOARD_BOTTOM_ONE_RESET : 'GET_DASHBOARD_BOTTOM_ONE_RESET',

    GET_DASHBOARD_BOTTOM_TWO_SUCCESS : 'GET_DASHBOARD_BOTTOM_TWO_SUCCESS',
    GET_DASHBOARD_BOTTOM_TWO_PENDING : 'GET_DASHBOARD_BOTTOM_TWO_PENDING',
    GET_DASHBOARD_BOTTOM_TWO_FAILURE : 'GET_DASHBOARD_BOTTOM_TWO_FAILURE',
    GET_DASHBOARD_BOTTOM_TWO_RESET : 'GET_DASHBOARD_BOTTOM_TWO_RESET',

    

    

    CREATE_PAYOUT_TOKEN_SUCCESS : 'CREATE_PAYOUT_TOKEN_SUCCESS',
    CREATE_PAYOUT_TOKEN_PENDING : 'CREATE_PAYOUT_TOKEN_PENDING',
    CREATE_PAYOUT_TOKEN_FAILURE : 'CREATE_PAYOUT_TOKEN_FAILURE',
    CREATE_PAYOUT_TOKEN_RESET : 'CREATE_PAYOUT_TOKEN_RESET',

    CHANGE_ORDER_STATUS_SUCCESS : 'CHANGE_ORDER_STATUS_SUCCESS',
    CHANGE_ORDER_STATUS_PENDING : 'CHANGE_ORDER_STATUS_PENDING',
    CHANGE_ORDER_STATUS_FAILURE : 'CHANGE_ORDER_STATUS_FAILURE',
    CHANGE_ORDER_STATUS_RESET : 'CHANGE_ORDER_STATUS_RESET',

    GET_STORES_LIST_SUCCESS : 'GET_STORES_LIST_SUCCESS',
    GET_STORES_LIST_PENDING : 'GET_STORES_LIST_PENDING',
    GET_STORES_LIST_FAILURE : 'GET_STORES_LIST_FAILURE',
    GET_STORES_LIST_RESET : 'GET_STORES_LIST_RESET',

    GET_TOPSTORES_SUCCESS : 'GET_TOPSTORES_SUCCESS',
    GET_TOPSTORES_PENDING : 'GET_TOPSTORES_PENDING',
    GET_TOPSTORES_FAILURE : 'GET_TOPSTORES_FAILURE',
    GET_TOPSTORES_RESET : 'GET_TOPSTORES_RESET',

    GET_VENDOR_DETAILS_SUCCESS : 'GET_VENDOR_DETAILS_SUCCESS',
    GET_VENDOR_DETAILS_PENDING : 'GET_VENDOR_DETAILS_PENDING',
    GET_VENDOR_DETAILS_FAILURE : 'GET_VENDOR_DETAILS_FAILURE',
    GET_VENDOR_DETAILS_RESET : 'GET_VENDOR_DETAILS_RESET',

    GET_VENDOR_STORES_SUCCESS : 'GET_VENDOR_STORES_SUCCESS',
    GET_VENDOR_STORES_PENDING : 'GET_VENDOR_STORES_PENDING',
    GET_VENDOR_STORES_FAILURE : 'GET_VENDOR_STORES_FAILURE',
    GET_VENDOR_STORES_RESET : 'GET_VENDOR_STORES_RESET',

    GET_CUSTOMERS_LIST_SUCCESS : 'GET_CUSTOMERS_LIST_SUCCESS',
    GET_CUSTOMERS_LIST_PENDING : 'GET_CUSTOMERS_LIST_PENDING',
    GET_CUSTOMERS_LIST_FAILURE : 'GET_CUSTOMERS_LIST_FAILURE',
    GET_CUSTOMERS_LIST_RESET : 'GET_CUSTOMERS_LIST_RESET',

    GET_VENDORS_LIST_SUCCESS : 'GET_VENDORS_LIST_SUCCESS',
    GET_VENDORS_LIST_PENDING : 'GET_VENDORS_LIST_PENDING',
    GET_VENDORS_LIST_FAILURE : 'GET_VENDORS_LIST_FAILURE',
    GET_VENDORS_LIST_RESET : 'GET_VENDORS_LIST_RESET',

    GET_ORDERS_SUCCESS : 'GET_ORDERS_SUCCESS',
    GET_ORDERS_PENDING : 'GET_ORDERS_PENDING',
    GET_ORDERS_FAILURE : 'GET_ORDERS_FAILURE',
    GET_ORDERS_RESET : 'GET_ORDERS_RESET',

    GET_PRODUCTS_LIST_SUCCESS : 'GET_PRODUCTS_LIST_SUCCESS',
    GET_PRODUCTS_LIST_PENDING : 'GET_PRODUCTS_LIST_PENDING',
    GET_PRODUCTS_LIST_FAILURE : 'GET_PRODUCTS_LIST_FAILURE',
    GET_PRODUCTS_LIST_RESET : 'GET_PRODUCTS_LIST_RESET',

    UPDATE_VENDOR_OR_STORE_STATUS_SUCCESS : 'UPDATE_VENDOR_OR_STORE_STATUS_SUCCESS',
    UPDATE_VENDOR_OR_STORE_STATUS_PENDING : 'UPDATE_VENDOR_OR_STORE_STATUS_PENDING',
    UPDATE_VENDOR_OR_STORE_STATUS_FAILURE : 'UPDATE_VENDOR_OR_STORE_STATUS_FAILURE',
    UPDATE_VENDOR_OR_STORE_STATUS_RESET : 'UPDATE_VENDOR_OR_STORE_STATUS_RESET',

   

    GET_DASHBOARD_SUMMARY_SUCCESS : 'GET_DASHBOARD_SUMMARY_SUCCESS',
    GET_DASHBOARD_SUMMARY_PENDING : 'GET_DASHBOARD_SUMMARY_PENDING',
    GET_DASHBOARD_SUMMARY_FAILURE : 'GET_DASHBOARD_SUMMARY_FAILURE',
    GET_DASHBOARD_SUMMARY_RESET : 'GET_DASHBOARD_SUMMARY_RESET',

    GET_PRODUCTS_SUMMARY_SUCCESS : 'GET_PRODUCTS_SUMMARY_SUCCESS',
    GET_PRODUCTS_SUMMARY_PENDING : 'GET_PRODUCTS_SUMMARY_PENDING',
    GET_PRODUCTS_SUMMARY_FAILURE : 'GET_PRODUCTS_SUMMARY_FAILURE',
    GET_PRODUCTS_SUMMARY_RESET : 'GET_PRODUCTS_SUMMARY_RESET',
    

    GET_VENDORS_SUMMARY_SUCCESS : 'GET_VENDORS_SUMMARY_SUCCESS',
    GET_VENDORS_SUMMARY_PENDING : 'GET_VENDORS_SUMMARY_PENDING',
    GET_VENDORS_SUMMARY_FAILURE : 'GET_VENDORS_SUMMARY_FAILURE',
    GET_VENDORS_SUMMARY_RESET : 'GET_VENDORS_SUMMARY_RESET',

    GET_A_STORE_CHART_SUCCESS : 'GET_A_STORE_CHART_SUCCESS',
    GET_A_STORE_CHART_PENDING : 'GET_A_STORE_CHART_PENDING',
    GET_A_STORE_CHART_FAILURE : 'GET_A_STORE_CHART_FAILURE',
    GET_A_STORE_CHART_RESET : 'GET_A_STORE_CHART_RESET',

    TOGGLE_USER_SUCCESS : 'TOGGLE_USER_SUCCESS',
    TOGGLE_USER_PENDING : 'TOGGLE_USER_PENDING',
    TOGGLE_USER_FAILURE : 'TOGGLE_USER_FAILURE',
    TOGGLE_USER_RESET : 'TOGGLE_USER_RESET',

    TOGGLE_STORE_SUCCESS : 'TOGGLE_STORE_SUCCESS',
    TOGGLE_STORE_PENDING : 'TOGGLE_STORE_PENDING',
    TOGGLE_STORE_FAILURE : 'TOGGLE_STORE_FAILURE',
    TOGGLE_STORE_RESET : 'TOGGLE_STORE_RESET',

    GET_CUSTOMER_ADDRESS_SUCCESS : 'GET_CUSTOMER_ADDRESS_SUCCESS',
    GET_CUSTOMER_ADDRESS_PENDING : 'GET_CUSTOMER_ADDRESS_PENDING',
    GET_CUSTOMER_ADDRESS_FAILURE : 'GET_CUSTOMER_ADDRESS_FAILURE',
    GET_CUSTOMER_ADDRESS_RESET : 'GET_CUSTOMER_ADDRESS_RESET',

    GET_URL_OPTIONS_SUCCESS : 'GET_URL_OPTIONS_SUCCESS',
    GET_URL_OPTIONS_PENDING : 'GET_URL_OPTIONS_PENDING',
    GET_URL_OPTIONS_FAILURE : 'GET_URL_OPTIONS_FAILURE',
    GET_URL_OPTIONS_RESET : 'GET_URL_OPTIONS_RESET',
    
    GET_STORE_CATS_SUCCESS : 'GET_STORE_CATS_SUCCESS',
    GET_STORE_CATS_PENDING : 'GET_STORE_CATS_PENDING',
    GET_STORE_CATS_FAILURE : 'GET_STORE_CATS_FAILURE',
    GET_STORE_CATS_RESET : 'GET_STORE_CATS_RESET',

    ADD_A_NEW_STORE_SUCCESS : 'ADD_A_NEW_STORE_SUCCESS',
    ADD_A_NEW_STORE_PENDING : 'ADD_A_NEW_STORE_PENDING',
    ADD_A_NEW_STORE_FAILURE : 'ADD_A_NEW_STORE_FAILURE',
    ADD_A_NEW_STORE_RESET : 'ADD_A_NEW_STORE_RESET',
}