
// import SuccessIcon from "../../../assets/images/icons/check-circle.png"
import LoaderIcon from "../../../assets/loader2.gif";
import "./index.scss"
export const LoadingItem = ({ type, message }: any) => {
    return (
        <div className="loader-wrap">
            <img src={LoaderIcon} alt="" />
            <div className="loading-txt">Please wait...</div>
        </div>
    )
}