// import React from 'react';
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { history } from "./shared/_helpers/history";
import AppRoutes from "./routes"
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    // @ts-expect-error
    <HistoryRouter history={history}>
      <AppRoutes />
    </HistoryRouter>
  );
}

export default App;
