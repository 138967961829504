
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";

import OrdersListManagement from "./orders-listing"


import { getDateFromISO } from "../../../shared/utils"
import { LoadingItem } from "../../../shared/components/loading"
import { appActions } from "../../../redux/actions/in-app"
import { appConstants } from "../../../redux/action-constants/inapp.constants";



import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"

const getOrderDetails = async ({ pageProps, payload }: any) => {

    await pageProps.getOrderDetailsAction(payload);

}

const getOrdersList = async ({ pageProps, payload }: any) => {

    await pageProps.getOrdersListAction(payload);
}


const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [orderstatus, setOrderstatus] = useState("0");
    const [searchText, setSearchText] = useState<string>("")
    const [searchValue, setSearchValue] = useState<string>("")

    useEffect(() => {
        getOrdersList({
            pageProps,
            payload: "CLEAR",
            isAStore: false
        })
        getOrderDetails({ pageProps, payload: "CLEAR" })

    }, [])



    useEffect(() => {


        getOrdersList({
            pageProps,
            payload: `?Page=${pageNumber}&Size=${pageSize}${orderstatus? `&Status=${orderstatus}`:""}`,
            isAStore: false
        })



    }, [])

    useEffect(() => {


        getOrdersList({
            pageProps,
            payload: `?Page=${pageNumber}&Size=${pageSize}${orderstatus? `&Status=${parseInt(orderstatus)}`:""}${searchText ? `&SearchText=${searchText}` : ""}`,
            isAStore: false
        })



    }, [pageNumber, pageSize, orderstatus,searchText])




    const ReportsDisplay = () => {
        let ordersList = pageProps.getOrderListRequest?.request_data?.response?.data;
        let allOrders: any[] = ordersList?.results;


        return (
            <div className="reports_wrap">
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className="report_heading">Order Management</div>
                        <div className="order_counts">
                            <div className="each_count">
                                <div className="count_title">All orders</div>
                                <div className="count_txt">{ordersList?.totalNumberOfRecords}</div>
                            </div>
                            <div className="each_count">
                                <div className="count_title">Total sales</div>
                                <div className="count_txt">$1,784,490.12</div>
                            </div>
                            <div className="each_count">
                                <div className="count_title">Orders in last 30 days</div>
                                <div className="count_txt">639</div>
                            </div>
                            <div className="each_count">
                                <div className="count_title">Service charge</div>
                                <div className="count_txt">$27,418</div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className="table_navs">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/orders">
                        <div className="table_nav_item">All orders</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/orders/pending">
                        <div className="table_nav_item">Pending orders</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/orders/completed">
                        <div className="table_nav_item">Completed orders</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/orders/cancelled">
                        <div className="table_nav_item">Cancelled orders</div>
                        <div className="underline"></div>
                    </NavLink>
                </div> */}
                {allOrders &&
                    <OrdersListManagement searchValue={searchValue} setSearchValue={setSearchValue} setSearchText={setSearchText} orderstatus={orderstatus} setOrderstatus={setOrderstatus} pageNumber={pageNumber} pageSize={pageSize} setPageSize={setPageSize} setPageNumber={setPageNumber} ordersList={ordersList} orderData={allOrders} orderType="all" />
                }
            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                {pageProps.getOrderListRequest.request_status === appConstants.GET_ORDERS_PENDING &&
                    <LoadingItem />
                }
                {pageProps.getOrderListRequest.request_status === appConstants.GET_ORDERS_SUCCESS &&
                    <ReportsDisplay />
                }
            </div>
        </div>
    )
}

const OrderManagement = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Order Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getOrdersListAction: appActions.GetOrdersList,
    getOrderDetailsAction: appActions.GetOrderDetails,
};

const mapStateToProps = (state: any) => ({
    getOrderListRequest: state.allInAppReducers.getOrderListReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderManagement);