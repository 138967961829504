
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";
import Rate from "../../../assets/rate.svg";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal'
import { TableComponent } from "../../../shared/components/table"
import { FilterUtil } from "../../../shared/components/report-filter"
import CheckIco from "../../../assets/check_n.svg"
import ReactPaginate from 'react-paginate';

import ActionIco from "../../../assets/action.png"
import CancelIco from "../../../assets/cancel.svg"

import { AlertMsg } from "../../../shared/components/alert-msg"
import Shop1 from "../../../assets/shop_1.jpeg"
import TrendUp from "../../../assets/trend_up.svg"
import ShopIco from "../../../assets/shop_ico.svg"
import EmailIco from "../../../assets/email.svg"
import CallIco from "../../../assets/call.svg"

import { getDateFromISO } from "../../../shared/utils"

import { appActions } from "../../../redux/actions/in-app"
import { LoadingItem } from "../../../shared/components/loading"
import { appConstants } from "../../../redux/action-constants/inapp.constants";



import { history } from "../../../shared/_helpers/history";


import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"



const getStoresList = async ({ pageProps, payload }: any) => {

    await pageProps.getStoresAction(payload, "unverified");
}

const toggleStoreAction = async ({ pageProps, payload, action }: any) => {

    await pageProps.toggleStoreAction(payload, action);
}
const SuspendStoreModal = (props: any) => {
    let { showSuspendModal, setShowSuspendModal, pageProps, storeId, action, vendorId } = props;

    // console.log("action", action, storeId)
    // console.log("pageProps", pageProps)

    let checkValidationSchema;
    // if (action !== "Approve") {
    //     checkValidationSchema = Yup.object().shape({
    //         reason: Yup.string().trim()
    //             .required('Required'),

    //     });
    // }

    const suspendProcess: any = (payload: any) => {
        toggleStoreAction({
            pageProps,
            payload,
            action: action.toLowerCase()
        }).then(() => {
        })
    }

    return (
        <Modal className="suspend_wrap" show={showSuspendModal} onHide={() => {
            if (!pageProps?.toggleStoreRequest?.is_request_processing) {
                setShowSuspendModal(false)
            }
        }
        }>
            <Modal.Header closeButton>
                {action} store?
            </Modal.Header>
            <Modal.Body>
                {pageProps?.toggleStoreRequest?.request_status !== appConstants.TOGGLE_STORE_SUCCESS &&
                    <>
                        <div className="head_message">
                            Please be advised that {action === "Approve" ? "approving" : "rejecting"} this store will also means that customers will be {action === "Approve" ? "able to make purchases from this store" : "unable to make purchases from this store until this store is approved"}. Are you sure you want to proceed with this action?
                        </div>
                        <div className="suspend_form">
                            <Formik
                                initialValues={{
                                    reason: ""
                                }}

                                validationSchema={checkValidationSchema}
                                onSubmit={(values) => {
                                    let { reason } = values;

                                    let payload;
                                    if (action !== "Approve") {
                                        payload = {
                                            ids: [
                                                storeId
                                            ],
                                            reason
                                        }
                                    } else {
                                        payload = {
                                            storeId,
                                            userId: vendorId
                                        }
                                    }

                                    if (action === "Reject"){
                                        payload = {
                                            storeId,
                                            userId: vendorId
                                        }
                                    }

                                    // console.log("payload", payload)

                                    suspendProcess(payload)




                                }}
                            >
                                {(props: FormikProps<any>) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        // handleBlur,
                                        handleChange,
                                        // isSubmitting,
                                    } = props;

                                    return (
                                        <Form>
                                            {pageProps.toggleStoreRequest.request_status !== appConstants.TOGGLE_STORE_SUCCESS &&
                                                <>
                                                    {/* {(action !== "Approve" && !pageProps.toggleStoreRequest?.is_request_processing) &&
                                                        <textarea value={values.reason}
                                                            name="reason" id="reason"
                                                            placeholder='Reason for rejection'
                                                            onChange={handleChange('reason')}
                                                            className={` ${errors.reason && touched.reason ?
                                                                "is-invalid-input "
                                                                : !errors.reason && values.reason !== "" ? "valid-input" : ""}`}
                                                            rows={10}></textarea>
                                                    } */}
                                                    {/* <ErrorMessage name="reason" className="form-input-error" component="div" /> */}

                                                    <div className="suspend_ctas">
                                                        <button className="btn cancel"
                                                            disabled={pageProps.toggleStoreRequest?.is_request_processing}
                                                            onClick={() => setShowSuspendModal(false)}
                                                        > Cancel</button>
                                                        <button
                                                            type="submit"
                                                            className="btn confirm"
                                                            disabled={pageProps.toggleStoreRequest?.is_request_processing}
                                                        >
                                                            {pageProps.toggleStoreRequest.is_request_processing ? "Please wait..." : "Confirm"}
                                                        </button>
                                                    </div>
                                                </>
                                            }

                                            {pageProps.toggleStoreRequest.request_status === appConstants.TOGGLE_STORE_FAILURE &&

                                                <AlertMsg type="error" message={pageProps.toggleStoreRequest?.request_data.error} />

                                            }




                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </>
                }
                {pageProps.toggleStoreRequest.request_status === appConstants.TOGGLE_STORE_SUCCESS &&

                    <AlertMsg type="success" message="Store status successfully updated" />

                }

            </Modal.Body>
        </Modal>
    )

}

const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false)

    const [storeId, setStoreId] = useState<any>()
    const [vendorId, setVendorId] = useState<any>()

    const [actionTodo, setActionTodo] = useState<any>()

    useEffect(() => {

        getStoresList({
            pageProps,
            payload: "CLEAR"
        })

        getStoresList({
            pageProps,
            payload: `?Page=${pageNumber}&Size=${pageSize}`
        })

    }, [])

    useEffect(() => {

        getStoresList({
            pageProps,
            payload: `?Page=${pageNumber}&Size=${pageSize}`
        })

    }, [pageNumber, pageSize])

    const loadNextPage = (nextPage: any, tempData: any) => {
        // const { dispatch } = this.props;
        // let { PageSize } = this.state;



        setPageNumber(nextPage)
    };

    const handlePageClick = (event: any) => {

        setPageNumber(event?.selected + 1)

    }

    const ReportTable = () => {
        let storesList = pageProps.getStoresListRequest?.request_data?.response?.data;
        let allStores: any[] = storesList?.results;


        return (
            <>
                <thead>
                    <tr>
                        <th className="left_top"> <span >Store</span> </th>
                        <th> Address</th>
                        <th>Vendor name</th>


                        <th> Contact details </th>
                        <th className="right_top"> <span>Registration date</span></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allStores.map((eachStore: any, index: any) => {
                            return (
                                <tr key={index}>

                                    <td className="brand_name"> <div className="brand_info"> <input type="checkbox" name="" id="" /> <img src={eachStore?.logoUrl} alt="" /></div> {eachStore?.name}</td>
                                    <td className="grayed">{eachStore?.streetAddress}</td>
                                    <td className="grayed">{eachStore?.vendor}</td>
                                    <td className="branded">
                                        <div><span className="info_ico"><img src={EmailIco} alt="" /></span> {eachStore?.storeEmail} </div>
                                        <div> <span className="info_ico"><img src={CallIco} alt="" /></span> {eachStore?.storePhoneNumber}  </div>
                                    </td>
                                    <td className="grayed more_info">
                                        <div className="inner_td">
                                            <div>
                                                {eachStore.dateCreated ? getDateFromISO({ date: eachStore.dateCreated, returnTime: true }) : "N/A"}
                                            </div>
                                            <Dropdown className="report_dropdown" as={ButtonGroup}>
                                                <Dropdown.Toggle id="dropdown-basic" className="action-cta">
                                                    <div className="action">
                                                        <img src={ActionIco} alt="" />
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {/* <Dropdown.Item>
                                                        View details
                                                    </Dropdown.Item> */}


                                                    <Dropdown.Item className="other_action" onClick={() => {
                                                        toggleStoreAction({
                                                            pageProps,
                                                            payload: "CLEAR",
                                                            action: "approve"
                                                        })
                                                        setStoreId(eachStore?.id)
                                                        setVendorId(eachStore?.vendorId)
                                                        setActionTodo("Approve")
                                                        setShowSuspendModal(true)
                                                    }
                                                    }>
                                                        <img src={CheckIco} alt="" /> Approve store
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="red_action" onClick={() => {
                                                        toggleStoreAction({
                                                            pageProps,
                                                            payload: "CLEAR",
                                                            action: "reject"
                                                        })
                                                        setStoreId(eachStore?.id)
                                                        setVendorId(eachStore?.vendorId)
                                                        setActionTodo("Reject")
                                                        setShowSuspendModal(true)
                                                    }
                                                    }>
                                                        <img src={CancelIco} alt="" /> Reject store
                                                    </Dropdown.Item>


                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>

                                </tr>
                            )
                        })
                    }

                </tbody>
            </>
        )
    }


    const ReportsDisplay = () => {
        let storesList = pageProps.getStoresListRequest?.request_data?.response?.data;

        return (
            <div className="reports_wrap">

                {(showSuspendModal && actionTodo) && <SuspendStoreModal vendorId={vendorId} action={actionTodo} pageProps={pageProps} storeId={storeId} showSuspendModal={showSuspendModal} setShowSuspendModal={setShowSuspendModal} />}
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className="report_heading">Stores <span>{storesList?.totalNumberOfRecords}</span></div>
                        {/* <div className="report_heading">Stores <span>{storesList?.totalNumberOfRecords}</span></div> */}

                    </div>

                </div>
                <div className="vendor_graphs">
                    <div className="top_of_graph">
                        <div className="left_side">
                            <img src={ShopIco} alt="" />
                            <div className="title_txt">TOP STORES <span>($)</span></div>
                        </div>
                        <div className="right_side">
                            <select name="" id="">
                                <option value="Winnipeg">Winnipeg</option>
                                <option value="Winnipeg">Manitoba</option>
                            </select>
                        </div>
                    </div>
                    <div className="all_graphs">
                        <div className="each_graph">
                            <div className="vendor_name_txt">Food Emporium</div>
                            <div className="sales_bar">
                                <div className="sales_bar_fill" style={{ width: "84%" }}>$15,734</div>

                            </div>
                        </div>
                        <div className="each_graph">
                            <div className="vendor_name_txt">Tasty Treats</div>
                            <div className="sales_bar">
                                <div className="sales_bar_fill" style={{ width: "79%" }}>$11,124</div>
                            </div>
                        </div>
                        <div className="each_graph">
                            <div className="vendor_name_txt">Savory Pantry</div>
                            <div className="sales_bar">
                                <div className="sales_bar_fill" style={{ width: "60%" }}>$8,473</div>
                            </div>
                        </div>
                        <div className="each_graph">
                            <div className="vendor_name_txt">SPAR Markets</div>
                            <div className="sales_bar">
                                <div className="sales_bar_fill" style={{ width: "50%" }}>$5,290</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table_navs">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores">
                        <div className="table_nav_item">Active stores </div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores/unverified">
                        <div className="table_nav_item">Unverified stores ({storesList?.totalNumberOfRecords})</div>
                        {/* <div className="table_nav_item">Unverified stores ({storesList?.totalNumberOfRecords})</div> */}
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores/suspended">
                        <div className="table_nav_item">Suspended stores </div>
                        <div className="underline"></div>
                    </NavLink>
                    {/* <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores/deleted">
                        <div className="table_nav_item">Deleted stores </div>
                        <div className="underline"></div>
                    </NavLink> */}
                </div>
                <div className="reports_table">
                    <div className="report_actions">
                        <input type="text" className="search_table" placeholder="Search for vendor, store name" />
                        <div className="right_actions">
                            <FilterUtil />
                        </div>
                    </div>
                    {storesList?.results &&
                        <TableComponent
                            isEmptyRecords={storesList?.results.length === 0}
                            // isEmptyRecords={!storesList || storesList?.results.length === 0}
                            requestData={storesList}
                            childComponent={<ReportTable />}
                        // childComponent={<ReportTable reportData={pageProps.getStoreOrdersRequest.request_data.response} />}
                        />
                    }
                    {storesList?.totalNumberOfRecords > pageSize &&
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            // initialPage={1}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={4}
                            forcePage={pageNumber - 1}
                            // onClick={handleItemsClick}
                            className="pagination_items"
                            pageClassName="page_num"
                            pageLinkClassName="page_link"
                            activeClassName="active_page_link"
                            previousClassName="previous_page_link"
                            nextClassName="next_page_link"
                            pageCount={storesList?.pageNumber}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                    }
                </div>
            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                {pageProps.getStoresListRequest.request_status === appConstants.GET_STORES_LIST_PENDING &&
                    <LoadingItem />
                }
                {pageProps.getStoresListRequest.request_status === appConstants.GET_STORES_LIST_SUCCESS &&
                    <ReportsDisplay />
                }
            </div>
        </div>
    )
}

const UnverifiedStoresManagement = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Vendor Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getStoresAction: appActions.GetStores,
    toggleStoreAction: appActions.ToggleStore,
};

const mapStateToProps = (state: any) => ({
    getStoresListRequest: state.allInAppReducers.getStoresListReducer,
    toggleStoreRequest: state.allInAppReducers.toggleStoreReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(UnverifiedStoresManagement);