
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useParams, useLocation } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";

import BackIco from "../../../assets/back.svg"

import OrdersListManagement from "./orders-listing"




import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"






const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();

    const { storeId } = useParams();
    const { state } = useLocation();



    useEffect(() => {



        // let loggedAccountInfo: any = localStorage.getItem("mami-Super-XAuth") || "{}";
        // loggedAccountInfo = JSON.parse(loggedAccountInfo);
        // let storeId = loggedAccountInfo.selectedStore.id;
        // let payload = `StoreId=${storeId}`;
        // let graphPayload = `StoreId=${storeId}&ReportGroupingType=${salesFlag}`;







    }, [])




    const ReportsDisplay = () => {
        return (
            <div className="reports_wrap">
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        {storeId &&
                        <div className="report_heading">
                            <img className="back_ico" src={BackIco} alt="" /> Back To <span className="main_head_txt">The Food Emporium</span>
                        </div>
                        }
                        <div className={storeId ? "report_heading smaller" : "report_heading"}>{!storeId ? "Order Management" : "Order History"}</div>
                        <div className="order_counts">
                            <div className="each_count">
                                <div className="count_title">All orders</div>
                                <div className="count_txt">5123</div>
                            </div>
                            <div className="each_count">
                                <div className="count_title">Total sales</div>
                                <div className="count_txt">$1,784,490.12</div>
                            </div>
                            <div className="each_count">
                                <div className="count_title">Orders in last 30 days</div>
                                <div className="count_txt">639</div>
                            </div>
                            <div className="each_count">
                                <div className="count_title">Service charge</div>
                                <div className="count_txt">$27,418</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="table_navs">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to={!storeId ? "/app/orders" : `/app/stores/${storeId}/orders`}>
                        <div className="table_nav_item">All orders</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to={!storeId ? "/app/orders/pending" : `/app/stores/${storeId}/orders/pending`}>
                        <div className="table_nav_item">Pending orders</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to={!storeId ? "/app/orders/completed" : `/app/stores/${storeId}/orders/completed`}>
                        <div className="table_nav_item">Completed orders</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to={!storeId ? "/app/orders/cancelled" : `/app/stores/${storeId}/orders/cancelled`}>
                        <div className="table_nav_item">Cancelled orders</div>
                        <div className="underline"></div>
                    </NavLink>
                </div>
                <OrdersListManagement orderType="pending" />
            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                <ReportsDisplay />
            </div>
        </div>
    )
}

const PendingOrderManagement = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Order Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
    // return {

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(PendingOrderManagement);