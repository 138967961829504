// import styles from "../../styles/pages/help-center.module.scss"
import { IoMdClose } from "react-icons/io"
import { CiImageOn } from "react-icons/ci"
import React, { useState } from "react"
import shoes from "../../../assets/shoess.jpg"
import round from '../../../assets/round.svg'
import './support.scss'
import user from '../../../assets/user.png'
import user2 from '../../../assets/user2.jpg'
import { TbTag } from "react-icons/tb"
import { PiDotOutlineFill } from 'react-icons/pi'


interface chatModal {
    handleChatClose: () => void
    handleModalOpen: () => void
    assignee?: string
    assign: 'Assign issue' | 'Re-assign issue'
}


export const Chat = [
    {
        userID: 1,
        type: "text",
        message: "The product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also tells on your brand image.",
    },
    {
        userID: 1,
        img: shoes,
        type: "image",
        date: 'Tuesday',
    },
    {
        userID: 2,
        type: "text",
        message: "Hello Jide, we’re sorry about the experience and we are dedicated to providing you with the best support. What would you say are the issues with the product?",
    },
    {
        userID: 2,
        type: "text",
        message: "This will help us determine whether to provide you with a refund or replace the said product."
    }
]

export const SupportChat: React.FC<chatModal> = ({ handleChatClose, handleModalOpen, assignee, assign }) => {
    const [messages, setMessages] = useState(Chat)
    const [message, setMessage] = useState('')

    const sendMessage = () => {
        if (message.trim() === "") {
            return;
        }

        const newMessage = {
            type: "text",
            userID: 2,
            message: message,
        };

        const newMessages = [...messages, newMessage];

        setMessages(newMessages);

        setMessage("");
    };
    return (
        <div className="chatContent">
            <div className="chatContainer">
                <div className="header">
                    <div className="header2">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <img src={user} className="img" />
                            <div className="texts" style={{ marginTop: '.7rem' }}>
                                <h4 className="h4">Vanessa Hillary</h4>
                                <p className="sub">vanessahilary@gmail.com</p>
                            </div>
                        </div>
                        <div onClick={handleChatClose}><IoMdClose size={25} style={{cursor: 'pointer'}} /></div>
                    </div>
                    <div className="container">
                        <div className="flexrow">
                           <div>
                           <h5 className="h4">Product Quality</h5>
                            {
                                assign === 'Re-assign issue'
                                    ? <div className="assign-row">
                                        <img src={user2} style={{width: '25px', height: '25px', borderRadius: '50%'}} />
                                        <span style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}><p className="assign">{assignee}</p> <p className="mami">(MamiMarkets)</p></span>
                                    </div> : null
                            }
                           </div>
                            <div>
                                <div className='fill'>
                                    <PiDotOutlineFill size={15} color='#EC3237' />
                                    <p className='text2'>Urgent</p>
                                </div>
                                <div className="center" style={{ marginTop: '1rem' }}>
                                    <TbTag color='#00A85B' />
                                    <p className='text' onClick={() => handleModalOpen()}>{assign}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chatmsg">
                    {
                        messages.map((elem, index) => {
                            return (
                                <>
                                    {
                                        elem.type === 'text' ? <div className={elem.userID === 1 ? "left" : "right"} key={index}>
                                            <p className={elem.userID === 1 ? "leftText" : "rightText"}>{elem.message}</p>

                                        </div> :
                                            <>
                                                {
                                                    elem.img && <img src={elem.img} alt="" className={elem.img && elem.userID === 1 ? "sentImg" : "sentImg2"} />
                                                }
                                                <p className="leftDate">{elem.date}</p>
                                            </>

                                    }
                                </>
                            )
                        })
                    }
                </div>
                <div className="bottom">
                    <div className="inputbox">
                        <input
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                            type="text"
                            placeholder="Type a reply"
                            className="input2" />
                        <CiImageOn className="icon2" size={20} />
                    </div>
                    <button className="send" onClick={sendMessage}>Send</button>
                </div>
            </div>
        </div>
    )
}