import { useState, useEffect } from "react";
import '../../../scss/onboarding.scss';
import { connect } from 'react-redux';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { OnBoardingFormValues, ILoginProps } from "../../../types"
import GSign from "../../../assets/g-sign.svg" 
import PwSeen from "../../../assets/icons/pw-visible.png" 
import PwHide from "../../../assets/icons/pw-hidden.png" 
import { PostBoardingWrap } from "../../../shared/templates/onboarding";
import { authActions } from "../../../redux/actions/onboarding"
import { AlertMsg } from "../../../shared/components/alert-msg"

const handleLogin = async ({ pageProps, payload }: any) => {

    await pageProps.LoginAction(payload);
}

const PostBoardingContent = ({ pageProps }: any) => {
    const [isPwShown, setShowPw] = useState<boolean>(false)
    useEffect(() => {
        pageProps.logoutAction();
        handleLogin({
            pageProps,
            payload: "CLEAR"
        })


    }, [])
    let checkValidationSchema = Yup.object().shape({
        username: Yup.string().trim()
            // .email('Valid email is required')
            .required('Required'),
        password: Yup.string()
            .required('Required'),

    });
    let initialValues: any = { username: "", password: "" }
    return (
        <div className="postboarding-content">
            <div className="content-heading">
                <h2>Login</h2>
               
            </div>
            <div className="form-wrap">
                <Formik
                    initialValues={initialValues}

                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {
                        let { username, password } = values;
                        let payload: any = {
                            username,
                            password,
                            grant_type: "password",
                            scope: "api1.read IdentityServerApi",
                            client_secret: "SuperSecretPassword",
                            client_id: "oauthClient"
                        }
                        var formBody: any = [];
                        for (var property in payload) {
                            var encodedKey = encodeURIComponent(property);
                            var encodedValue = encodeURIComponent(payload[property]);
                            formBody.push(encodedKey + "=" + encodedValue);
                        }
                        formBody = formBody.join("&");
                        // setLoginData(formBody)
                        await handleLogin({ pageProps, payload: formBody })
                    }}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            values,
                            touched,
                            errors,
                            // handleBlur,
                            handleChange,
                            // isSubmitting,
                        } = props;

                        return (
                            <Form>
                                {/* <PersistFormikValues name="signin-form" /> */}
                                <div className="form-group">
                                    <div className={errors.username && touched.username ? "invalid-input-wrap" : ""}>
                                        <Field
                                            value={values.username}
                                            id="username"
                                            name="username"
                                            type="text"
                                            onChange={handleChange('username')}
                                            placeholder="Email Address"
                                            className={`input-wrap ${errors.username && touched.username ?
                                                "is-invalid-input "
                                                : !errors.username && values.username !== "" ? "valid-input" : ""}`}
                                        />
                                    </div>
                                    <ErrorMessage name="username" className="form-input-error" component="div" />
                                </div>
                                <div className="form-group">
                                    <div className={errors.password && touched.password ? "invalid-input-wrap input-with-icon" : "input-with-icon"}>
                                        <Field
                                            id="password"
                                            value={values.password}
                                            name="password"
                                            type={!isPwShown?"password":"text"}
                                            placeholder="Password"
                                            onChange={handleChange}
                                            className={`input-wrap ${errors.password && touched.password ?
                                                "is-invalid-input "
                                                : values.password !== "" ? "valid-input" : ""}`}
                                        />
                                        <div className="show-hide" onClick={()=>setShowPw(!isPwShown)}>
                                            {isPwShown && <img src={PwHide} alt="" />}
                                            {!isPwShown && <img src={PwSeen} alt="" />}
                                        </div>
                                    </div>
                                    <ErrorMessage name="password" className="form-input-error" component="div" />
                                </div>
                                {pageProps?.loginRequest?.request_status
                                        && pageProps.loginRequest.request_status === "LOGIN_USER_FAILURE" &&
                                        <AlertMsg type="error" message={pageProps.loginRequest?.request_data.error} />
                                    }
                                <button
                                    className="main-cta full"
                                    //         className={`btn form-btn full-width 
                                    // ${((Object.keys(errors).length === 0 && Object.keys(touched).length >= 1)
                                    //                 || pageProps.loginRequest?.is_request_processing
                                    //             )
                                    //                 ? " btn-main " : "btn-frozen"}`
                                    //         }
                                    disabled={pageProps.loginRequest?.is_request_processing}
                                    type="submit">
                                    
                                    {pageProps.loginRequest.is_request_processing ? "Please wait..." : "Continue"}
                                </button>
                                {/* <div className="form-link">
                                    Forgot Password?
                                    <Link to="/account/forgot-password">Click here</Link>
                                </div> */}
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            

        </div>
    )
}


const LogIn = (pageProps: any) => {
    return (
        <PostBoardingWrap pageHeadingTitle="Back Office Management" childComponent={<PostBoardingContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    LoginAction: authActions.Login,
    logoutAction: authActions.Logout,
};

const mapStateToProps = (state: any) => ({
    loginRequest: state.onboardingOutboundReducers.loginReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(LogIn);