import { combineReducers } from "redux";
import { authConstants } from "../action-constants/onboarding.constants";
import {
    outboundReducers,
    inAppReducers
} from "./all-reducers"

const rootReducer = (state: any, action: any) => {
    if (action.type === authConstants.LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

const onboardingOutboundReducers = combineReducers({
    loginReducer: outboundReducers.loginReducer,
    forgotPasswordReducer: outboundReducers.forgotPasswordReducer,
  
});

const allInAppReducers = combineReducers({
    getImageGalleryReducer: inAppReducers.getImageGalleryReducer,
    createStoreProductReducer: inAppReducers.createStoreProductReducer,
    uploadStoreBulkProductsReducer: inAppReducers.uploadStoreBulkProductsReducer,
    updateStoreProductReducer: inAppReducers.updateStoreProductReducer,
    getProductMetaDataReducer: inAppReducers.getProductMetaDataReducer,
    getAProductReducer: inAppReducers.getAProductReducer,
    getACustomerAddressReducer: inAppReducers.getACustomerAddressReducer,
    
    getStoresListReducer: inAppReducers.getStoresListReducer,
    getTopStoresReducer: inAppReducers.getTopStoresReducer,
    getOrderListReducer: inAppReducers.getOrderListReducer,
    getOrderDetailsReducer: inAppReducers.getOrderDetailsReducer,
    getProductsListReducer: inAppReducers.getProductsListReducer,
    getStoreDetailsReducer: inAppReducers.getStoreDetailsReducer,
    getStoreTransactionsReducer: inAppReducers.getStoreTransactionsReducer,
    getCustomersReducer: inAppReducers.getCustomersReducer,
    getVendorsReducer: inAppReducers.getVendorsReducer,
    getVendorDetailsReducer: inAppReducers.getVendorDetailsReducer,
    getStoreChartReducer: inAppReducers.getStoreChartReducer,
    updateProductImageReducer: inAppReducers.updateProductImageReducer,
    
    getDashboardSummaryReducer: inAppReducers.getDashboardSummaryReducer,
    getDashboardBottomOneReducer: inAppReducers.getDashboardBottomOneReducer,
    getDashboardBottomTwoReducer: inAppReducers.getDashboardBottomTwoReducer,
    getVendorSummaryReducer: inAppReducers.getVendorSummaryReducer,
    getProductsSummaryReducer: inAppReducers.getProductsSummaryReducer,
    
    toggleUserReducer: inAppReducers.toggleUserReducer,
    toggleStoreReducer: inAppReducers.toggleStoreReducer,
    
    addNewStoreReducer: inAppReducers.addNewStoreReducer,
    getStoreCatsReducer: inAppReducers.getStoreCatsReducer,
    getUrlReducer: inAppReducers.getUrlReducer,
});


const appReducer = combineReducers({
    onboardingOutboundReducers,
    allInAppReducers
});

export default rootReducer;