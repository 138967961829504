import user from '../../../assets/user.png'
import user2 from '../../../assets/user2.jpg'
import { props } from './assign-modal'
export const Assignees: props = [
    {
        img: user,
        name: 'Joshua Kimmich',
        role: 'Super Admin',
        status: 'online'
    },
    {
        img: user2,
        name: 'Temitayo Alao',
        role: 'Analyst',
        status: 'offline'
    },
    {
        img: user,
        name: 'Gabriell Simmich',
        role: 'Manager',
        status: 'online'
    },
    {
        img: user2,
        name: 'Melinda Joy',
        role: 'Manager',
        status: 'offline'
    }, {
        img: user,
        name: 'Christopher Paul',
        role: 'Super Admin',
        status: 'offline'
    }, {
        img: user2,
        name: 'Olivia Tonali',
        status: 'online',
        role: 'Analyst'
    }, {
        img: user,
        name: 'Christopher Paul',
        role: 'Super Admin',
        status: 'offline'
    }, {
        img: user,
        name: 'Christopher Paul',
        role: 'Super Admin',
        status: 'offline'
    }, {
        img: user,
        name: 'Christopher Paul',
        role: 'Super Admin',
        status: 'offline'
    },
]