import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { InAppTemplate } from "../../../shared/templates/inapp";
import distinctColors from 'distinct-colors'

import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';





import { LoadingItem } from "../../../shared/components/loading"
import { AlertMsg } from "../../../shared/components/alert-msg"
import SalesIco from "../../../assets/sales.svg"
import VendorsIco from "../../../assets/all_vendor.svg"
import ProductsIco from "../../../assets/products.svg"
import UsersIco from "../../../assets/all_stores.svg"

import { appActions } from "../../../redux/actions/in-app"

import { appConstants } from "../../../redux/action-constants/inapp.constants";






import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ArcElement,
    BarElement,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const getProductsSummary = async ({ pageProps, payload }: any) => {

    await pageProps.getProductsSummaryAction(payload);
}

const getVendorsSummary = async ({ pageProps, payload }: any) => {

    await pageProps.getVendorsSummaryAction(payload);
}

const getDashboardSummary = async ({ pageProps, payload }: any) => {

    await pageProps.getDashboardSummaryAction(payload);
}

const getDashboardBottomOne = async ({ pageProps, payload }: any) => {

    await pageProps.getDashboardBottomOneAction(payload);
}

const getDashboardBottomTwoData = async ({ pageProps, payload }: any) => {

    await pageProps.getDashboardBottomTwoAction(payload);
}

const RenderTopOneStats = ({ pageProps }: any) => {
    const [dashboardSummary, setDashboardSummary] = useState(false);
    useEffect(() => {


        getDashboardSummary({
            pageProps,
            payload: ""
        }).then(() => {


        })




    }, [])

    useEffect(() => {

        if (pageProps.getDashboardSummaryRequest.request_status === "GET_DASHBOARD_SUMMARY_SUCCESS") {
            let vendorInfo = pageProps.getDashboardSummaryRequest.request_data.response?.data;
            setDashboardSummary(vendorInfo)
        }





    }, [pageProps?.getVendorSummaryRequest?.request_status])

    const RenderDashboardData = ({ dashboardData }: any) => {

        return (
            <div className="all_wide_summary">
                <div className="top_summary">
                    <div className="each_summary">
                        <div className="value_n_icon">
                            <div className="value_txt">${dashboardData?.totalSales}</div>
                            <img src={SalesIco} alt="" />
                        </div>
                        <div className="summary_name">Total sales</div>
                        <div className="summary_detail">
                            <span className="rise_trend">{dashboardData?.totalSalesDiff}%</span>
                            from last month
                        </div>
                    </div>
                    <div className="each_summary">
                        <div className="value_n_icon">
                            <div className="value_txt">{dashboardData?.vendorCount}</div>
                            <img src={VendorsIco} alt="" />
                        </div>
                        <div className="summary_name">Vendors</div>
                        <div className="summary_detail">
                            <span className="rise_trend">{dashboardData?.vendorCountDiff}%</span>
                            from last month
                        </div>
                    </div>
                </div>
                <div className="bottom_summary">
                    <div className="each_summary">
                        <div className="value_n_icon">
                            <div className="value_txt">{dashboardData?.productCount}</div>
                            <img src={ProductsIco} alt="" />
                        </div>
                        <div className="summary_name">Products</div>
                        <div className="summary_detail">
                            <span className="rise_trend">{dashboardData?.productCountDiff}%</span>
                            from last month
                        </div>
                    </div>
                    <div className="each_summary">
                        <div className="value_n_icon">
                            <div className="value_txt">{dashboardData?.customerCount}</div>
                            <img src={UsersIco} alt="" />
                        </div>
                        <div className="summary_name">Monthly Customers</div>
                        <div className="summary_detail">
                            <span className="fall_trend">{dashboardData?.customerCountDiff}%</span>
                            from last month
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            {pageProps.getDashboardSummaryRequest.request_status === "GET_DASHBOARD_SUMMARY_PENDING" &&
                <div className="all_wide_summary">
                    <LoadingItem />
                </div>
            }
            {pageProps.getDashboardSummaryRequest.request_status === "GET_DASHBOARD_SUMMARY_FAILURE" &&
                <div className="all_wide_summary">
                    <AlertMsg type="error" message={pageProps.getDashboardSummaryRequest?.request_data.error} />
                </div>
            }
            {pageProps.getDashboardSummaryRequest.request_status === "GET_DASHBOARD_SUMMARY_SUCCESS" &&
                <RenderDashboardData dashboardData={pageProps.getDashboardSummaryRequest?.request_data?.response.data} />
            }
        </>
    )
}



const RenderTopTwoStats = ({ pageProps }: any) => {
    const [vendorsSummary, setVendorsSummary] = useState(false);
    useEffect(() => {


        getVendorsSummary({
            pageProps,
            payload: ""
        })




    }, [])

    useEffect(() => {

        if (pageProps.getVendorSummaryRequest.request_status === "GET_VENDORS_SUMMARY_SUCCESS") {
            let vendorInfo = pageProps.getVendorSummaryRequest.request_data.response?.data;
            setVendorsSummary(vendorInfo)
        }





    }, [pageProps?.getVendorSummaryRequest?.request_status])


    const newShade = (hexColor: any, magnitude: any) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };


    const RenderDashboardData = ({ dashboardData }: any) => {

        let palette: any[] = distinctColors({ count: dashboardData.length, chromaMin: 20 }) || [];

        let chartColors: any[] = palette.map((each: any) => `${each}`)

        let colorsList: any[] = [...chartColors]
        const percentage = 66;
        return (
            <div className="all_zones">
                {
                    dashboardData.map((eachZone: any, index: any) => {
                        return (
                            <div className="zone_wrap" key={index}>
                                <div className="zone_name_wrap"> <span style={{ backgroundColor: colorsList[index] }}></span> <div className="name_txt">{eachZone?.region}</div> </div>
                                <CircularProgressbarWithChildren value={eachZone?.count}
                                    background={true}
                                    strokeWidth={4}
                                    styles={buildStyles({

                                        pathColor: colorsList[index],
                                        // pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
                                        // textColor: '#f88',
                                        trailColor: 'transparent',
                                        // trailColor: '#d6d6d6',
                                        backgroundColor: newShade(colorsList[index], 100),
                                    })}
                                >
                                    <div className="vendor_info">
                                        <div className="count_txt">{eachZone?.count}</div>
                                        <div className="other_txt" >Vendors</div>
                                    </div>

                                </CircularProgressbarWithChildren>
                            </div>
                        )
                    })

                }
            </div>
        )
    }
    return (
        <>
            {pageProps.getVendorSummaryRequest.request_status === "GET_VENDORS_SUMMARY_PENDING" &&
                <div className="all_wide_summary">
                    <LoadingItem />
                </div>
            }
            {pageProps.getVendorSummaryRequest.request_status === "GET_VENDORS_SUMMARY_FAILURE" &&
                <div className="all_wide_summary">
                    <AlertMsg type="error" message={pageProps.getDashboardSummaryRequest?.request_data.error} />
                </div>
            }
            {pageProps.getVendorSummaryRequest.request_status === "GET_VENDORS_SUMMARY_SUCCESS" &&
                <div className="all_vendor_summary">
                    <div className="title_head">
                        <div className="main_txt">Vendors per region</div>
                        <div className="helper_txt">Easily track and analyse business reach based on vendor distribution across various regions</div>
                    </div>
                    <RenderDashboardData dashboardData={pageProps.getVendorSummaryRequest?.request_data?.response.data} />
                </div>
            }
        </>
    )
}

const BottomTwoChart = ({ chartData, colorsList }: any) => {


    return (
        <div className="bottom_two_data">
            <div className="heading_wrap">
                <div className="heading_txt">Popular Products</div>
                <div className="see_more">See all</div>
            </div>
            <div className="all_stats">
                {
                    chartData.map((eachData: any, index: any) => {
                        console.log("eachData", eachData)
                        return (
                            <div className="each_data" key={index}>
                                <div className="data_wrap">
                                    <div className="data_name">{eachData?.name}</div>
                                    <div className="data_value">{eachData?.totalCount} stores</div>
                                </div>
                                <div className="value_bar">
                                    <div className="bar_indicator" style={{backgroundColor:eachData?.colorCode, width:eachData?.percentValue}}  ></div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

const BottomOneChart = ({ chartData }: any) => {

    let chartValues: any[] = chartData.map((each: any) => each?.count)
    let chartLabels: any[] = chartData.map((each: any) => each?.region)
    const chartOptions = {
        responsive: true,
        // options: {
        //     scales: {
        //         xAxes: [{ barPercentage: 0.5 }]
        //     }
        // },
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Customers per region',
                font: {
                    size: 15,
                    family: 'Helvetica Neue'
                }
            },
        },
        scales: {
            x: {
                grid: {
                    drawBorder: false,
                    lineWidth: 0
                }
            }
        }
    };
    const mainData = {
        labels: chartLabels,
        datasets: [
            {
                label: '',
                data: chartValues,
                backgroundColor: '#00A85B',
                maxBarThickness: 30,
                borderRadius: 8,
                borderSkipped: false
                // borderWidth: 2,

            },


        ],
    };
    return (
        <Bar options={chartOptions} data={mainData} />
    )
}

const RenderBottomOne = ({ pageProps }: any) => {
    const [bottomOneData, setBottomOneData] = useState<any>();

    useEffect(() => {


        getDashboardBottomOne({
            pageProps,
            payload: ""
        })

    }, [])

    useEffect(() => {

        if (pageProps.getDashboardBottomOneRequest.request_status === "GET_DASHBOARD_BOTTOM_ONE_SUCCESS") {
            let chartInfo = pageProps.getDashboardBottomOneRequest.request_data.response?.data;

            setBottomOneData(chartInfo)
        }





    }, [pageProps?.getDashboardBottomOneRequest?.request_status])





    return (
        <div className="bottom_one_chart">
            {pageProps.getDashboardBottomOneRequest.request_status === "GET_DASHBOARD_BOTTOM_ONE_PENDING" &&
                <LoadingItem />
            }
            {pageProps.getDashboardBottomOneRequest.request_status === "GET_DASHBOARD_BOTTOM_ONE_FAILURE" &&
                <AlertMsg type="error" message={pageProps.getDashboardBottomOneRequest?.request_data.error} />
            }
            {(pageProps.getDashboardBottomOneRequest.request_status === "GET_DASHBOARD_BOTTOM_ONE_SUCCESS" && bottomOneData) &&
                <BottomOneChart chartData={bottomOneData} />
            }
        </div>
    )
}

const RenderBottomTwo = ({ pageProps }: any) => {
    const [bottomTwoData, setBottomTwoData] = useState<any>();
    const [colorsToUse, setColorsList] = useState<any>();
    const [chartData, setChartData] = useState<any>();
    useEffect(() => {


        getDashboardBottomTwoData({
            pageProps,
            payload: ""
        })




    }, [])
    useEffect(() => {

        if (pageProps.getDashboardBottomTwoRequest.request_status === "GET_DASHBOARD_BOTTOM_TWO_SUCCESS") {
            let chartInfo = pageProps.getDashboardBottomTwoRequest.request_data.response?.data;

            setBottomTwoData(chartInfo)
        }





    }, [pageProps?.getDashboardBottomTwoRequest?.request_status])
    useEffect(() => {

        if (bottomTwoData && bottomTwoData.length >= 1) {


            let palette: any[] = distinctColors({ count: bottomTwoData.length, chromaMin: 20 }) || [];

            let chartColors: any[] = palette.map((each: any) => `${each}`)

            let colorsList: any[] = [...chartColors]
            setColorsList(colorsList)

            let overallCount = 0;
            let dataTwoStore = []
            for (let index = 0; index < bottomTwoData.length; index++) {
                overallCount += bottomTwoData[index]?.totalCount;
            }

            for (let index = 0; index < bottomTwoData.length; index++) {
                let percentValue = (bottomTwoData[index]?.totalCount * 100) / overallCount;
                dataTwoStore.push({
                    ...bottomTwoData[index],
                    percentValue: Number(percentValue.toFixed(2)),
                    colorCode: colorsList[index]
                })
            }
            setChartData(dataTwoStore)



        }


    }, [bottomTwoData])


    return (
        <div className="bottom_two_chart">
            {pageProps.getDashboardBottomTwoRequest.request_status === "GET_DASHBOARD_BOTTOM_TWO_PENDING" &&
                <LoadingItem />
            }
            {pageProps.getDashboardBottomTwoRequest.request_status === "GET_DASHBOARD_BOTTOM_TWO_FAILURE" &&
                <AlertMsg type="error" message={pageProps.getDashboardBottomTwoRequest?.request_data.error} />
            }
            {(pageProps.getDashboardBottomTwoRequest.request_status === "GET_DASHBOARD_BOTTOM_TWO_SUCCESS"
                && chartData && colorsToUse) &&
                <BottomTwoChart chartData={chartData} colorsList={colorsToUse} />
            }
        </div>
    )
}






const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();




    useEffect(() => {


        getDashboardSummary({
            pageProps,
            payload: "CLEAR"
        })
        getVendorsSummary({
            pageProps,
            payload: "CLEAR"
        })
        getDashboardBottomOne({
            pageProps,
            payload: "CLEAR"
        })
        getDashboardBottomTwoData({
            pageProps,
            payload: "CLEAR"
        })


    }, [])












    return (
        <div className="inapp-content">

            <div className="page-content dashboard">

                <div className="top_chart_section">
                    <RenderTopOneStats pageProps={pageProps} />
                    <RenderTopTwoStats pageProps={pageProps} />
                </div>
                <div className="bottom_chart_section">
                    <RenderBottomOne pageProps={pageProps} />
                    <RenderBottomTwo pageProps={pageProps} />
                </div>



            </div>
        </div>
    )
}

const Dashboard = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Dashboard" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getProductsSummaryAction: appActions.GetProductsSummary,
    getVendorsSummaryAction: appActions.GetVendorsSummary,
    getDashboardSummaryAction: appActions.GetDashboardSummary,
    getDashboardBottomOneAction: appActions.GetDashboardBottomOne,
    getDashboardBottomTwoAction: appActions.GetDashboardBottomTwo,
};

const mapStateToProps = (state: any) => ({
    getDashboardSummaryRequest: state.allInAppReducers.getDashboardSummaryReducer,
    getVendorSummaryRequest: state.allInAppReducers.getVendorSummaryReducer,
    getProductsSummaryRequest: state.allInAppReducers.getProductsSummaryReducer,
    getDashboardBottomOneRequest: state.allInAppReducers.getDashboardBottomOneReducer,
    getDashboardBottomTwoRequest: state.allInAppReducers.getDashboardBottomTwoReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);