import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { appConstants } from "../../action-constants/inapp.constants";

import { Dispatch } from 'redux';

import { history } from "../../../shared/_helpers/history";
import {
    handleRequestErrors,
} from "../../../shared/utils";

const GetDashboardSummary = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_DASHBOARD_SUMMARY}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_DASHBOARD_SUMMARY_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_DASHBOARD_SUMMARY_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_DASHBOARD_SUMMARY_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_DASHBOARD_SUMMARY_RESET, clear_data: "" };
    }
}

const GetDashboardBottomTwo = (actionPayload: any) => {
    
    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            
            let apiConsume = ApiService.request({ url: `${routes.GET_PRODUCT_CAT_SUMMARY}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_TWO_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_TWO_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_TWO_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_TWO_RESET, clear_data: "" };
    }
}

const GetDashboardBottomOne = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_CUSTOMER_SUMMARY}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_ONE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_ONE_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_ONE_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_ONE_RESET, clear_data: "" };
    }

    
}

const GetChartRequest = (storeId: any, storeName: any) => {
    let storePayload = `StoreId=${storeId}&ReportGroupingType=1`
    let apiConsume = ApiService.request({ url: `${routes.ORDER_GRAPH_SUMMARY}?${storePayload}`, http_method: "GET" });
    // dispatch(request(apiConsume));
    let responseData = apiConsume
        .then((response: any) => {
            return {
                storeName,
                storeChart: response.data,
                isSuccessful: true
            }
            // dispatch(success(response.data))
        })
        .catch((error: any) => {
            return {
                storeName,
                isSuccessful: false
            }
            // dispatch(failure(handleRequestErrors(error)));
        });
    return responseData.then((response: any) => {
        return {
            response
        }
        // dispatch(success(response.data))
    })

}

 


const GetAStoreChart = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.ORDER_GRAPH_SUMMARY}?${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_A_STORE_CHART_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_A_STORE_CHART_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_A_STORE_CHART_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_A_STORE_CHART_RESET, clear_data: "" };
    }
}



const GetVendorsSummary = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_VENDOR_SUMMARY}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_VENDORS_SUMMARY_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_VENDORS_SUMMARY_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_VENDORS_SUMMARY_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_VENDORS_SUMMARY_RESET, clear_data: "" };
    }
}


const GetProductsSummary = (actionPayload: any, isNewBatch: boolean) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_PRODUCTS_SUMMARY}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_PRODUCTS_SUMMARY_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_PRODUCTS_SUMMARY_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_PRODUCTS_SUMMARY_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_PRODUCTS_SUMMARY_RESET, clear_data: "" };
    }
}



const GetImageGallery = (actionPayload: any, isNewBatch: boolean) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_IMAGE_GALLERY}?${actionPayload || ""}`, http_method: "GET" });
            if (!isNewBatch) {
                dispatch(request(apiConsume));
            }
            return apiConsume
                .then((response: any) => {
                    // dispatch(success(response))
                    if (!isNewBatch) {
                        
                        dispatch(success(response))
                    } else {
                        dispatch(nextBatchLoaded(response))
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_IMAGE_GALLERY_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_IMAGE_GALLERY_SUCCESS, response: response.data, results: response.data.results };
    }
    function nextBatchLoaded(response: any) {
        return { type: appConstants.GET_IMAGE_GALLERY_NEWBATCH, response: response.data, results: response.data.results };
    }
    function failure(error: any) {
        return { type: appConstants.GET_IMAGE_GALLERY_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_IMAGE_GALLERY_RESET, clear_data: "" };
    }
}

const UploadProductImage = (actionPayload: any, otherNames: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.PRODUCT_IMAGE_UPLOAD, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {

                    let responseData = { ...response.data, otherNames }
                    dispatch(success(responseData))

                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.UPLOAD_PRODUCT_PICTURE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.UPLOAD_PRODUCT_PICTURE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.UPLOAD_PRODUCT_PICTURE_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.UPLOAD_PRODUCT_PICTURE_RESET, clear_data: "" };
    }
}

const GetProductMetaData = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let metaData: any = {};
            let apiConsume = ApiService.request({ url: `${routes.GET_CATEGORIES}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    // dispatch(success(response.data))
                    // }
                    let apiConsume2 = ApiService.request({ url: `${routes.GET_UNITS}`, http_method: "GET" });
                    dispatch(request(apiConsume2));
                    return apiConsume2
                        .then((response2: any) => {
                            // if (response?.data) {
                            metaData.units = response2.data.results;
                            metaData.categories = response.data.results;

                            dispatch(success(metaData))
                            // }
                        })
                        .catch((error: any) => {
                            dispatch(failure(handleRequestErrors(error)));
                        });
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_PRODUCT_META_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_PRODUCT_META_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_PRODUCT_META_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_PRODUCT_META_RESET, clear_data: "" };
    }
}

const CreateStoreProduct = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.CREATE_PRODUCT, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    history.replace(`/app/stores/${actionPayload?.storeId}/products`)
                    // history.replace("/app/my-products/success")

                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.CREATE_PRODUCT_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.CREATE_PRODUCT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.CREATE_PRODUCT_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.CREATE_PRODUCT_RESET, clear_data: "" };
    }
}

//Stores Actions
const GetStores = (actionPayload: any, status?: string) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let urlPath = routes.GET_STORES;

            if (status === "unverified") {
                urlPath = routes.UN_APPROVED_STORE;
            }
            let apiConsume = ApiService.request({ url: `${urlPath}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_STORES_LIST_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_STORES_LIST_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_STORES_LIST_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_STORES_LIST_RESET, clear_data: "" };
    }
}

const GetTopStoresinProvince = (actionPayload: any, status?: string) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let urlPath = routes.GET_TOP_STORES;


            let apiConsume = ApiService.request({ url: `${urlPath}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_TOPSTORES_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_TOPSTORES_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_TOPSTORES_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_TOPSTORES_RESET, clear_data: "" };
    }
}

const getMultiple = async (...objectsToGet:any)=> {
    let users:any = [];
    
    await Promise.all(objectsToGet.map((obj:any) =>
     ApiService.request({ url: `${routes.ORDER_GRAPH_SUMMARY}?StoreId=${obj.id}&ReportGroupingType=1`, http_method: "GET" })
        .then((response:any) => {
            // do something with response
            users.push({
                storeName:obj?.name,
                storeId:obj?.id,
               storeChart: response?.data
            });
        })
    ));
    return users;
}
const GetVendorDetails = (actionPayload: any, status?: string) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let urlPath = routes.GET_VENDOR_DETAILS;
            let contactUrlPath = routes.GET_VENDOR_CONTACT_DETAILS;

            let apiConsume1 = ApiService.request({ url: `${contactUrlPath}/${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume1));
            return apiConsume1
                .then((response1: any) => {

                    let apiConsume = ApiService.request({ url: `${urlPath}?UserId=${actionPayload}`, http_method: "GET" });
                    dispatch(request(apiConsume));
                    return apiConsume
                        .then(async(response: any) => {


                            let storesList: any[] = response?.data?.stores;
                            
                            
                            let allStoreChart:any[] =  await getMultiple(...storesList)
                            
                            let resultData = {
                                data: response?.data,
                                contact: response1?.data,
                                allStoreChart
                                // storesChartsData: allStoreChart
                            }
                            
                            dispatch(success(resultData))
                        })
                        .catch((error: any) => {
                            dispatch(failure(handleRequestErrors(error)));
                        });


                    // dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });




        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_VENDOR_DETAILS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_VENDOR_DETAILS_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_VENDOR_DETAILS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_VENDOR_DETAILS_RESET, clear_data: "" };
    }
}

const GetVendorStores = (actionPayload: any, status?: string) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let urlPath = routes.GET_TOP_STORES;


            let apiConsume = ApiService.request({ url: `${urlPath}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_TOPSTORES_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_TOPSTORES_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_TOPSTORES_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_TOPSTORES_RESET, clear_data: "" };
    }
}

const GetCustomers = (actionPayload: any, isNewBatch: boolean) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_CUSTOMERS}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_CUSTOMERS_LIST_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_CUSTOMERS_LIST_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_CUSTOMERS_LIST_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_CUSTOMERS_LIST_RESET, clear_data: "" };
    }
}

const GetCustomerAddress = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_CUSTOMER_ADDRESS}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_CUSTOMER_ADDRESS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_CUSTOMER_ADDRESS_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_CUSTOMER_ADDRESS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_CUSTOMER_ADDRESS_RESET, clear_data: "" };
    }
}

const GetVendors = (actionPayload: any, isNewBatch: boolean) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_VENDORS}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_VENDORS_LIST_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_VENDORS_LIST_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_VENDORS_LIST_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_VENDORS_LIST_RESET, clear_data: "" };
    }
}

const ToggleUser = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.TOGGLE_USER}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    dispatch(success(response.data))
                    setTimeout(() => {
                        window.location.reload()
                    }, 5000);

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.TOGGLE_USER_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.TOGGLE_USER_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.TOGGLE_USER_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.TOGGLE_USER_RESET, clear_data: "" };
    }
}

const ToggleStore = (actionPayload: any, action: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let urlPath = "", http_method = "";
            if (action === "activate") {
                urlPath = routes.ACTIVATE_STORE
                http_method = "POST"
            }
            if (action === "suspend") {
                urlPath = routes.DEACTIVATE_STORE
                http_method = "POST"
            }
            if (action === "approve") {
                urlPath = routes.APPROVE_STORE
                http_method = "PUT"
            }
            if (action === "reject") {
                urlPath = routes.DISAPPROVE_STORE
                http_method = "PUT"
            }
            let apiConsume = ApiService.request({ url: `${urlPath}`, http_method, data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    dispatch(success(response.data))
                    setTimeout(() => {
                        window.location.reload()
                    }, 5000);

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.TOGGLE_STORE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.TOGGLE_STORE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.TOGGLE_STORE_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.TOGGLE_STORE_RESET, clear_data: "" };
    }
}

const GetOrdersList = (actionPayload: any, isAStore?: boolean) => {

    if (actionPayload !== "CLEAR") {


        let allData: any = {}
        return (dispatch: Dispatch) => {
            let urlPath = routes.GET_ORDERS;

            if (isAStore) {
                urlPath = routes.GET_ORDERS;
            }
            // GET_STORE_ORDERS
            let apiConsume = ApiService.request({ url: `${urlPath}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    if (!isAStore) {
                        dispatch(success(response))

                    } else {
                        let searchParams = actionPayload.split("?")[1];
                        searchParams = JSON.parse('{"' + searchParams.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })


                        let apiConsume2 = ApiService.request({ url: `${routes.GET_STORE_DETAILS}?Id=${searchParams?.StoreId}`, http_method: "GET" });
                        dispatch(request(apiConsume2));
                        return apiConsume2
                            .then((response2: any) => {
                                // let allData ={
                                //     ...response,
                                //     storeDetails: response2
                                // }
                                allData.response = response;
                                allData.storeDetails = response2
                                
                                dispatch(success(allData))
                            })
                            .catch((error: any) => {
                                dispatch(failure(handleRequestErrors(error)));
                            });
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_ORDERS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_ORDERS_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_ORDERS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_ORDERS_RESET, clear_data: "" };
    }
}

const GetOrderDetails = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_ORDER_DETAILS}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))


                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_ORDER_DETAILS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_ORDER_DETAILS_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_ORDER_DETAILS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_ORDER_DETAILS_RESET, clear_data: "" };
    }
}

const GetProductsList = (actionPayload: any, isNewBatch: boolean) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_PRODUCTS}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))


                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_PRODUCTS_LIST_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_PRODUCTS_LIST_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_PRODUCTS_LIST_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_PRODUCTS_LIST_RESET, clear_data: "" };
    }
}

const GetStoreDetails = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_STORE_DETAILS}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_STORE_DETAILS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_STORE_DETAILS_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_STORE_DETAILS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_STORE_DETAILS_RESET, clear_data: "" };
    }
}

const GetStoreTransactions = (actionPayload: any, isNewBatch: boolean) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_STORE_TRANSACTIONS}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_STORE_PAYMENT_HISTORY_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_STORE_PAYMENT_HISTORY_SUCCESS, response };
    }

    function failure(error: any) {
        return { type: appConstants.GET_STORE_PAYMENT_HISTORY_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_STORE_PAYMENT_HISTORY_RESET, clear_data: "" };
    }
}

const UpdateStoreProduct = (actionPayload: any, action?: string) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let urlPath = routes.UPDATE_A_PRODUCT,
                requestType = "POST";

            if (action === "delete") {
                urlPath = `${routes.DELETE_A_PRODUCT}/${actionPayload?.storeId}/DeleteProduct/${actionPayload?.productId}`;
                requestType = "DELETE";

            }

            let apiConsume = ApiService.request({ url: urlPath, http_method: requestType, data: action !== "delete" ? actionPayload : null });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    if (action !== "delete") {
                        history.replace(`/app/stores/${actionPayload?.storeId}/products/`)
                    }
                    // history.replace("/app/my-products/success")

                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.UPDATE_PRODUCT_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.UPDATE_PRODUCT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.UPDATE_PRODUCT_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.UPDATE_PRODUCT_RESET, clear_data: "" };
    }
}

const GetAProduct = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_A_PRODUCT}?Id=${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_A_PRODUCT_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_A_PRODUCT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_A_PRODUCT_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_A_PRODUCT_RESET, clear_data: "" };
    }
}

const UploadBulkProducts = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let urlOption = routes.UPLOAD_BULK_PRODUCTS;
           


            let apiConsume = ApiService.request({ url: urlOption, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    dispatch(success(response.data))




                })
                .catch((error: any) => {

                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.UPLOAD_MULTIPLE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.UPLOAD_MULTIPLE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.UPLOAD_MULTIPLE_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.UPLOAD_MULTIPLE_RESET, clear_data: "" };
    }
}


const GetStoreUrl = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        


        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_URL}/${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));

            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {
                    

                    dispatch(success(response.data))
                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_URL_OPTIONS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_URL_OPTIONS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_URL_OPTIONS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_URL_OPTIONS_RESET, clear_data: "" };
    }
}

const GetStoreCategories = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_STORES_CATS}?${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_STORE_CATS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_STORE_CATS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_STORE_CATS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_STORE_CATS_RESET, clear_data: "" };
    }
}

const AddNewStore = (actionPayload: any, storeLogo: any) => {

    if (actionPayload !== "CLEAR") {





        return (dispatch: Dispatch) => {
            let apiConsume = ApiService.request({ url: routes.LOGO_IMAGE_UPLOAD, http_method: "POST", data: storeLogo });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    
                    
                    let mainData: any = { ...actionPayload }
                    if (response?.data) {
                        mainData.logoUrl = response.data.fileURL
                        let apiConsume2 = ApiService.request({ url: routes.ADD_A_STORE, http_method: "POST", data: mainData });
                        dispatch(request(apiConsume2));
                        return apiConsume2
                            .then((response2: any) => {

                                dispatch(success(response2.data))




                            })
                            .catch((error: any) => {
                                dispatch(failure(handleRequestErrors(error)));
                            });
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.ADD_A_NEW_STORE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.ADD_A_NEW_STORE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.ADD_A_NEW_STORE_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.ADD_A_NEW_STORE_RESET, clear_data: "" };
    }
}



export const appActions = {
    GetImageGallery,
    UploadProductImage,
    GetProductMetaData,
    CreateStoreProduct,
    UploadBulkProducts,
    GetAProduct,
    UpdateStoreProduct,
    GetCustomers,
    GetCustomerAddress,
    GetTopStoresinProvince,
    GetVendors,
    GetVendorDetails,
    GetVendorStores,

    ToggleStore,

    GetStores,
    GetOrdersList,
    GetOrderDetails,
    GetProductsList,
    GetStoreDetails,
    GetStoreTransactions,

    ToggleUser,

    GetProductsSummary,
    GetVendorsSummary,
    GetDashboardSummary,

    GetAStoreChart,
    GetDashboardBottomOne,
    GetDashboardBottomTwo,

    GetStoreUrl,
    GetStoreCategories,
    AddNewStore
}