
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useParams, useLocation, Link } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";
import OrdersListManagement from "../orders/orders-listing"

import BackIco from "../../../assets/back.svg"





import { LoadingItem } from "../../../shared/components/loading"
import { appActions } from "../../../redux/actions/in-app"
import { appConstants } from "../../../redux/action-constants/inapp.constants";


import { useWindowSize } from "../../../hooks/useWindowSize";



import AllProductsListManagement from "../products/product-listing"
import "../index.scss"



const getOrdersList = async ({ pageProps, payload, isAStore }: any) => {

    await pageProps.getOrdersListAction(payload, isAStore);
}


const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();


    const { storeId } = useParams();
    const { state } = useLocation();
    let { orderData } = pageProps


    useEffect(() => {

        let filterInfo = `?${state?.storeData?.storeId || storeId ? `StoreId=${state?.storeData?.storeId || storeId}` : ""}`;

        // console.log("storeId",storeId)
        getOrdersList({
            pageProps,
            payload: filterInfo,
            isAStore: storeId ? true : false
        }).then(() => {


        })






    }, [])






    const ReportsDisplay = () => {
        let ordersInfo = pageProps.getOrderListRequest?.request_data?.response;
        let allOrders: any[] = ordersInfo?.response?.data.results;
        let storeInfo = ordersInfo?.storeDetails?.data;
        // console.log("storeInfo", storeInfo)
        return (
            <div className="reports_wrap">
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className="report_heading">
                            <Link className="back_to_link" to={`/app/stores/${storeInfo?.id}/details`}>
                                <>
                                    <img className="back_ico" src={BackIco} alt="" /> Back To
                                    
                                </>
                            </Link>
                            <span className="main_head_txt">{storeInfo?.name}</span>
                            
                        </div>
                        <div className="report_heading smaller">Order History</div>

                        <div className="order_counts">
                            <div className="each_count">
                                <div className="count_title">All orders</div>
                                <div className="count_txt">{ordersInfo?.response?.data.totalNumberOfRecords}</div>
                            </div>
                            <div className="each_count">
                                <div className="count_title">Total sales</div>
                                <div className="count_txt">$1,784,490.12</div>
                            </div>
                            <div className="each_count">
                                <div className="count_title">Orders in last 30 days</div>
                                <div className="count_txt">639</div>
                            </div>
                            <div className="each_count">
                                <div className="count_title">Service charge</div>
                                <div className="count_txt">$27,418</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="table_navs">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to={`/app/stores/${storeId}/orders`}>
                        <div className="table_nav_item">All orders</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to={`/app/stores/${storeId}/orders/pending`}>
                        <div className="table_nav_item">Pending orders</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to={`/app/stores/${storeId}/orders/completed`}>
                        <div className="table_nav_item">Completed orders</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to={`/app/stores/${storeId}/orders/cancelled`}>
                        <div className="table_nav_item">Cancelled orders</div>
                        <div className="underline"></div>
                    </NavLink>
                </div>
                <OrdersListManagement orderData={allOrders} orderType="all" />
            </div>
        )
    }









    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                {pageProps.getOrderListRequest.request_status === appConstants.GET_ORDERS_PENDING &&
                    <LoadingItem />
                }
                {pageProps.getOrderListRequest.request_status === appConstants.GET_ORDERS_SUCCESS &&
                    <ReportsDisplay />
                }
            </div>
        </div>
    )
}

const StoreOrdersManagement = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Orders Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getOrdersListAction: appActions.GetOrdersList,
};

const mapStateToProps = (state: any) => ({
    getOrderListRequest: state.allInAppReducers.getOrderListReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(StoreOrdersManagement);