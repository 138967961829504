import './help.scss'
import { InAppTemplate } from "../../../shared/templates/inapp";
import { BiSearch } from 'react-icons/bi'
import { HelpDetails } from './help-details';
import { useState } from 'react';
import { Card } from './not-started';
import { AssignModal } from "./assign-modal"
import { SupportChat } from './support-chat';
import { Assignees } from './assignee-list';


export const HelpTab = ({ pageProps }: any) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false)


    const handleTab = (index: number) => {
        setSelectedTab(index)
    }
    const handleModalOpen = () => {
        setOpenModal(true)
    }
    const handleModalClose = () => {
        setOpenModal(false)
    }
    return (
        <div className='help-container'>
            <h4 className='help-title'>Support HQ</h4>
            <div className="flex-container">
                <p className='sub'>
                    Organise, collate, and attend to complaints from customers and vendors
                </p>
                <div className='flexed'>
                    <p className='sub3'>Customers</p>
                    <div className="left-border" />
                    <p className='sub2'>Vendors</p>
                </div>
            </div>
            <div className='tabs'>
                {
                    HelpDetails.map((elem, id) => <p key={id} onClick={() => handleTab(id)} className={id === selectedTab ? 'selected-tab' : 'tab'}>
                        {elem.name} ({HelpDetails.find((ele) => ele.name === elem.name)?.data.length})
                    </p>
                    )
                }
            </div>
            <div className='flex'>
                <BiSearch size={22} color='#BBBBBB' />
                <div className='input-search'>
                    <input type='text' name='search' placeholder='Search for ticket ID' />
                </div>
            </div>
            <div className='all-cards'>
                {HelpDetails[selectedTab].data.map((elem) =>
                    <div>
                        <Card
                            ticket={elem.ticket_id}
                            time={elem?.type as string}
                            title={elem.title}
                            description={elem.description}
                            status={elem.status as any}
                            type={
                                selectedTab === 0 ? 'Not started' : selectedTab === 1 ? 'Ongoing' : 'Resolved'
                            }
                            customer={elem.customer}
                            message={elem.messages}
                            resolved={elem.resolved}
                            selectedTab={selectedTab}
                        />
                    </div>)
                }
            </div>
           
            {openModal &&
                <AssignModal
                    content={Assignees}
                    title="Assign issue"
                    handleModalClose={handleModalClose}
                />

            }
        </div>
    )
}
const HelpCenter = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Help Center" childComponent={<HelpTab pageProps={pageProps} />} />
    )
}
export default HelpCenter;