import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { authConstants } from "../../action-constants/onboarding.constants";

import { Dispatch } from 'redux';

import { history } from "../../../shared/_helpers/history";
import {
    handleRequestErrors,
} from "../../../shared/utils";


const Login = (loginPayload: any) => {

    if (loginPayload !== "CLEAR") {

        let userData: any;

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.LOGIN, http_method: "POST", data: loginPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    if (response?.data) {
                        userData = { ...response.data };
                        userData.lastLogForAuth = Date.now();
                        localStorage.setItem("mami-Super-XAuth", JSON.stringify(userData));
                        dispatch(success(userData));
                        history.replace("/app")
                        
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.LOGIN_USER_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.LOGIN_USER_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.LOGIN_USER_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.LOGIN_USER_RESET, clear_data: "" };
    }
}

const ForgotPassword = (loginPayload: any) => {

    if (loginPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.INITIATE_FORGOT_PW, http_method: "POST", data: loginPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success({ email: loginPayload.email }))

                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.FORGOT_PASSWORD_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.FORGOT_PASSWORD_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.FORGOT_PASSWORD_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.FORGOT_PASSWORD_RESET, clear_data: "" };
    }
}









const Logout = () => {
    localStorage.removeItem("mami-Super-XAuth");
    localStorage.removeItem("state");

    history.push("/");
    return (dispatch: Dispatch) => {

        dispatch(logout());

    };

    function logout() {
        return { type: authConstants.LOGOUT };
    }

}

export const authActions = {
    Login,
    ForgotPassword,
    Logout
};