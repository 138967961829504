import React, { useState } from 'react';
import SideBar from "../components/sidebar"
import { AppHeader } from "../components/header"
import { ChildrenCompProps } from "../../types";
import "../../scss/inapp.scss"
import { useWindowSize } from "../../hooks/useWindowSize";
export const InAppTemplate: React.FC<ChildrenCompProps> = (props) => {
    const screenResolution: any = useWindowSize();
    const { childComponent, pageHeadingTitle, noSideBar, showMainPageHeading, mainPageHeading } = props;

    return (
        <div className="inapp-wrap">

            {(screenResolution?.width >= 768 && !noSideBar) &&
                <SideBar />
            }
            <div className="page-main-content">
                {/* {(screenResolution?.width >= 768 && !noSideBar) &&
                    <SideBar />
                } */}
                <AppHeader mainPageHeading={mainPageHeading} showMainPageHeading={showMainPageHeading} pageHeadingTitle={pageHeadingTitle} />
                <div className={screenResolution?.width >= 768 ? `inapp-container ${noSideBar ? "no-stores-wrap" : ""}`
                    : `inapp-container mobile ${noSideBar ? "no-stores-wrap" : ""}`}>
                    {childComponent}
                </div>
            </div>
        </div>
    )
}