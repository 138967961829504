import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';

import LogIn from "../modules/onboarding/login"
import ForgotPassword from "../modules/onboarding/login/forgot-password"
import Dashboard from "../modules/in-app/dashboard"
import CustomerManagement from "../modules/in-app/customers"
import OrderManagement from "../modules/in-app/orders"
import PendingOrderManagement from "../modules/in-app/orders/pending"
import CompletedOrderManagement from "../modules/in-app/orders/completed"
import CancelledOrderManagement from "../modules/in-app/orders/cancelled"

import ActiveStoresManagement from "../modules/in-app/stores"
import UnverifiedStoresManagement from "../modules/in-app/stores/unverified"
import SuspendedStoresManagement from "../modules/in-app/stores/suspended"
import DeletedStoresManagement from "../modules/in-app/stores/deleted"
import StoreFullDetails from "../modules/in-app/stores/store-details"
import StoreProductsManagement from "../modules/in-app/stores/store-products"
import StoreOrdersManagement from "../modules/in-app/stores/store-orders"

import ProductsManagement from "../modules/in-app/products"
import AddNewProduct from "../modules/in-app/products/addnew"
import EditProduct from "../modules/in-app/products/edit"
import AddMultipleProducts from "../modules/in-app/products/add-bulk"

import VendorsManagement from "../modules/in-app/vendors"
import VendorDetails from "../modules/in-app/vendors/vendor-details"
import SettingsManagement from "../modules/in-app/settings"
import HelpCenter from "../modules/in-app/help-center";


const AppRoutes = (pageProps:any) => {
    
    return(
        <Routes>
            <Route path="/" element={<LogIn />} />
            <Route path="/account/forgot-password" element={<ForgotPassword />} />
            <Route path="/app" element={<Dashboard />} />
            <Route path="/app/customers" element={<CustomerManagement />} />
            <Route path="/app/orders" element={<OrderManagement />} />
            <Route path="/app/orders/pending" element={<PendingOrderManagement />} />
            <Route path="/app/orders/completed" element={<CompletedOrderManagement />} />
            <Route path="/app/orders/cancelled" element={<CancelledOrderManagement />} />
            <Route path="/app/stores" element={<ActiveStoresManagement />} />
            <Route path="/app/stores/:storeId/details" element={<StoreFullDetails />} />
            <Route path="/app/stores/:storeId/products" element={<StoreProductsManagement />} />
            <Route path="/app/stores/:storeId/products/create-new" element={<AddNewProduct />} />
            <Route path="/app/stores/:storeId/products/create-bulk" element={<AddMultipleProducts />} />
            <Route path="/app/stores/:storeId/products/:productId/edit" element={<EditProduct />} />
            <Route path="/app/stores/:storeId/orders" element={<StoreOrdersManagement />} />
            <Route path="/app/stores/:storeId/orders/pending" element={<PendingOrderManagement />} />
            <Route path="/app/stores/:storeId/orders/completed" element={<CompletedOrderManagement />} />
            <Route path="/app/stores/:storeId/orders/cancelled" element={<CancelledOrderManagement />} />

            <Route path="/app/vendors" element={<VendorsManagement />} />
            <Route path="/app/vendors/:vendorId/details" element={<VendorDetails />} />

            <Route path="/app/stores/unverified" element={<UnverifiedStoresManagement />} />
            <Route path="/app/stores/suspended" element={<SuspendedStoresManagement />} />
            <Route path="/app/stores/deleted" element={<DeletedStoresManagement />} />
            <Route path="/app/products" element={<ProductsManagement />} />
            {/* <Route path="/app/products/create-new" element={<AddNewProduct />} /> */}
            {/* <Route path="/app/products/create-new/bulk" element={<AddMultipleProducts />} /> */}
            
            <Route path="/app/settings" element={<SettingsManagement />} />
            <Route path="/app/help-center" element={<HelpCenter />} />
        </Routes>
    )
}

const mapStateToProps = (state: any) => ({
   
})

export default connect(mapStateToProps)(AppRoutes);