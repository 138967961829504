
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";
import Rate from "../../../assets/rate.svg";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { TableComponent } from "../../../shared/components/table"
import { FilterUtil } from "../../../shared/components/report-filter"
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal'

import ActionIco from "../../../assets/action.png"
import ReactPaginate from 'react-paginate';

import { AlertMsg } from "../../../shared/components/alert-msg"
import Shop1 from "../../../assets/shop_1.jpeg"
import TrendUp from "../../../assets/trend_up.svg"
import ShopIco from "../../../assets/shop_ico.svg"
import CheckIco from "../../../assets/check_n.svg"
import CancelIco from "../../../assets/cancel.svg"


import { appActions } from "../../../redux/actions/in-app"
import { LoadingItem } from "../../../shared/components/loading"
import { appConstants } from "../../../redux/action-constants/inapp.constants";

import { getDateFromISO } from "../../../shared/utils"

import { history } from "../../../shared/_helpers/history";


import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"


const getStoresList = async ({ pageProps, payload }: any) => {

    await pageProps.getStoresAction(payload);
}

const getTopStoresinProvince = async ({ pageProps, payload }: any) => {

    await pageProps.getTopStoresinProvinceAction(payload);
}

const toggleStoreAction = async ({ pageProps, payload, action }: any) => {

    await pageProps.toggleStoreAction(payload, action);
}

const SuspendStoreModal = (props: any) => {
    let { showSuspendModal, setShowSuspendModal, pageProps, storeId, action } = props;

    let checkValidationSchema = Yup.object().shape({
        reason: Yup.string().trim()
            .required('Required'),

    });

    const suspendProcess: any = (payload: any) => {
        toggleStoreAction({
            pageProps,
            payload,
            action: "suspend"
        }).then(() => {
        })
    }

    return (
        <Modal className="suspend_wrap" show={showSuspendModal} onHide={() => {
            if (!pageProps?.toggleStoreRequest?.is_request_processing) {
                setShowSuspendModal(false)
            }
        }
        }>
            <Modal.Header closeButton>
                Suspend store?
            </Modal.Header>
            <Modal.Body>
                {pageProps?.toggleStoreRequest?.request_status !== appConstants.TOGGLE_STORE_SUCCESS &&
                    <>
                        <div className="head_message">
                            Please be advised that suspending this store will  means that <span> customers will be unable to make purchases from this store</span> until the suspension is lifted. Are you sure you want to proceed with this action?
                        </div>
                        <div className="suspend_form">
                            <Formik
                                initialValues={{
                                    reason: ""
                                }}

                                validationSchema={checkValidationSchema}
                                onSubmit={(values) => {
                                    let { reason } = values;
                                    let payload = {
                                        ids: [
                                            storeId
                                        ],
                                        reason
                                    }

                                    suspendProcess(payload)




                                }}
                            >
                                {(props: FormikProps<any>) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        // handleBlur,
                                        handleChange,
                                        // isSubmitting,
                                    } = props;

                                    return (
                                        <Form>


                                            {!pageProps.toggleStoreRequest?.is_request_processing &&
                                                <textarea value={values.reason}
                                                    name="reason" id="reason"
                                                    placeholder='Reason for suspension'
                                                    onChange={handleChange('reason')}
                                                    className={` ${errors.reason && touched.reason ?
                                                        "is-invalid-input "
                                                        : !errors.reason && values.reason !== "" ? "valid-input" : ""}`}
                                                    rows={10}></textarea>
                                            }
                                            <ErrorMessage name="reason" className="form-input-error" component="div" />
                                            {pageProps?.toggleStoreRequest?.request_status
                                                && pageProps.toggleStoreRequest.request_status === appConstants.TOGGLE_STORE_FAILURE &&
                                                <AlertMsg type="error" message={pageProps.loginRequest?.request_data.error} />
                                            }
                                            <div className="suspend_ctas">
                                                <button className="btn cancel"
                                                    disabled={pageProps.toggleStoreRequest?.is_request_processing}
                                                    onClick={() => setShowSuspendModal(false)}
                                                > Cancel</button>
                                                <button
                                                    type="submit"
                                                    className="btn confirm"
                                                    disabled={pageProps.toggleStoreRequest?.is_request_processing}
                                                >
                                                    {pageProps.toggleStoreRequest.is_request_processing ? "Please wait..." : "Confirm suspension"}
                                                </button>
                                            </div>




                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </>
                }
                {pageProps?.toggleStoreRequest?.request_status === appConstants.TOGGLE_STORE_SUCCESS &&
                    <AlertMsg type="success" message="Store was successfully suspended" />
                }
            </Modal.Body>
        </Modal>
    )

}

const RenderTopStores = ({ storesList }: any) => {
    // console.log("storesList", storesList)

    let storesTotal = 0;
    for (let each = 0; each < storesList.length; each++) {
        storesTotal += storesList[each].totalSales
    }
    storesTotal = Math.round(storesTotal * 100) / 100;

    return (
        <>
            {storesList.length >= 1 &&
                <div className="all_graphs">
                    {
                        storesList.map((eachStore: any, index: any) => {
                            let storePercent = (eachStore?.totalSales / storesTotal) * 100
                            storePercent = Math.round(storePercent * 100) / 100;

                            // storePercent = ((storePercent)/100) * 85

                            return (
                                <div className="each_graph" key={index}>
                                    <div className="vendor_name_txt">{eachStore?.storeName}</div>
                                    <div className="sales_bar">
                                        {/* {
                                            index === 0 &&
                                            <div className="sales_bar_fill" style={{ width: `85%` }}>${eachStore?.totalSales}</div>
                                        } */}
                                        {/* <div className="sales_bar_value_fill">
                                            <div className="bar_value_fill" style={{ width: `85%` }}></div>
                                        </div>
                                        <div className="sales_bar_value">
                                            ${eachStore?.totalSales}
                                        </div> */}
                                        <div className="sales_bar_fill" style={{ width: `${storePercent}%` }}>${eachStore?.totalSales}</div>
                                        {/* {
                                            index !== 0 &&
                                            <div className="sales_bar_fill" style={{ width: `${storePercent}%` }}>${eachStore?.totalSales}</div>
                                        } */}

                                    </div>
                                </div>
                            )
                        })
                    }
                    {/* <div className="each_graph">
                        <div className="vendor_name_txt">Food Emporium</div>
                        <div className="sales_bar">
                            <div className="sales_bar_fill" style={{ width: "84%" }}>$15,734</div>

                        </div>
                    </div>
                    <div className="each_graph">
                        <div className="vendor_name_txt">Tasty Treats</div>
                        <div className="sales_bar">
                            <div className="sales_bar_fill" style={{ width: "79%" }}>$11,124</div>
                        </div>
                    </div>
                    <div className="each_graph">
                        <div className="vendor_name_txt">Savory Pantry</div>
                        <div className="sales_bar">
                            <div className="sales_bar_fill" style={{ width: "60%" }}>$8,473</div>
                        </div>
                    </div>
                    <div className="each_graph">
                        <div className="vendor_name_txt">SPAR Markets</div>
                        <div className="sales_bar">
                            <div className="sales_bar_fill" style={{ width: "50%" }}>$5,290</div>
                        </div>
                    </div> */}
                </div>
            }

            {storesList.length === 0 &&
                <div className="all_graphs">
                    <div className="no_stores">No Top stores in selected province</div>
                </div>
            }
        </>
    )
}




const InAppContent = ({ pageProps }: any) => {

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const screenResolution: any = useWindowSize();
    const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false);

    const [actionTodo, setActionTodo] = useState<any>()
    const [storeId, setStoreId] = useState<any>()
    const [topProvince, setTopProvince] = useState<string>("Ontario")
    const [searchText, setSearchText] = useState<string>("")
    const [searchValue, setSearchValue] = useState<string>("")

    useEffect(() => {

        getStoresList({
            pageProps,
            payload: "CLEAR"
        })
        getStoresList({
            pageProps,
            payload: `?Page=${pageNumber}&Size=${pageSize}&IsActive=${true}`
        })
        getTopStoresinProvince({
            pageProps,
            payload: `?Province=${topProvince}`
        })

    }, [])

    useEffect(() => {

        getStoresList({
            pageProps,
            payload: `?Page=${pageNumber}&Size=${pageSize}&IsActive=${true}${searchText ? `&Name=${searchText}` : ""}`
        })

    }, [pageNumber, pageSize, searchText])

    useEffect(() => {

        getTopStoresinProvince({
            pageProps,
            payload: `?Province=${topProvince}`
        })

    }, [topProvince])
    const handlePageClick = (event: any) => {

        setPageNumber(event?.selected + 1)

    }
    const loadNextPage = (nextPage: any, tempData: any) => {
        // const { dispatch } = this.props;
        // let { PageSize } = this.state;



        setPageNumber(nextPage)
    };

    const ReportTable = () => {
        let storesList = pageProps.getStoresListRequest?.request_data?.response?.data;
        let activeStores: any[] = storesList?.results;
        // let activeStores = allStores.filter((eachStore: any) => eachStore?.isActive === true);
        return (
            <>
                <thead>
                    <tr>
                        <th className="left_top"> <span >Store</span> </th>
                        <th> Address</th>

                        <th>Store reviews</th>
                        <th>Created on</th>
                        <th className="right_top"> <span>Store information</span> </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        activeStores.map((eachStore: any, index: any) => {
                            let negativeFeedbackCount: any = 0,

                                positiveFeedbackCount: any = 0,

                                neutralFeedbackCount: any = 0,

                                totalReviewsCount: any = 0;

                            let allRatings: any[] = eachStore?.ratings || [];

                            allRatings.forEach((eachRating: any) => {

                                if (eachRating >= 4) {
                                    positiveFeedbackCount += 1
                                }
                                if (eachRating === 3) {
                                    neutralFeedbackCount += 1
                                }
                                if (eachRating < 3) {
                                    negativeFeedbackCount += 1
                                }


                            })
                            return (
                                <tr key={index}>

                                    <td className="brand_name"> <div className="brand_info"> <input type="checkbox" name="" id="" /> <img src={Shop1} alt="" /></div> {eachStore?.name}</td>
                                    <td className="grayed">{eachStore?.address?.addressLine1 || eachStore?.address?.addressLine2}</td>

                                    <td> <span className="postive review_count">{positiveFeedbackCount} - </span>  <span className="neutral review_count">{neutralFeedbackCount} - </span><span className="negative review_count">{negativeFeedbackCount}</span></td>
                                    <td className="grayed">{eachStore.dateCreated ? getDateFromISO({ date: eachStore.dateCreated, returnTime: true }) : "N/A"}</td>
                                    <td className="branded more_info">
                                        <div className="inner_td">
                                            <Link to={`/app/stores/${eachStore?.id}/details`}>
                                                <span>Store info <img src={TrendUp} alt="" /> </span>
                                            </Link>

                                            <Dropdown className="report_dropdown" as={ButtonGroup}>
                                                <Dropdown.Toggle id="dropdown-basic" className="action-cta">
                                                    <div className="action">
                                                        <img src={ActionIco} alt="" />
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {/* <Dropdown.Item>
                                                        View details
                                                    </Dropdown.Item> */}
                                                    {eachStore?.isActive &&
                                                        <>
                                                            <Dropdown.Item className="red_action" onClick={() => {
                                                                toggleStoreAction({
                                                                    pageProps,
                                                                    payload: "CLEAR",
                                                                    action: "suspend"
                                                                })
                                                                setStoreId(eachStore?.id)
                                                                // setActionTodo("Activate")
                                                                setShowSuspendModal(true)
                                                            }
                                                            }>
                                                                <img src={CancelIco} alt="" /> Suspend store
                                                            </Dropdown.Item>
                                                        </>
                                                    }
                                                    {/* {!eachStore?.isActive &&
                                                    <Dropdown.Item>
                                                    Approve Store
                                                    </Dropdown.Item>
                                                } */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>


                                </tr>
                            )
                        })
                    }

                </tbody>
            </>
        )
    }




    const ReportsDisplay = () => {
        let storesList = pageProps.getStoresListRequest?.request_data?.response?.data;
        let allStores: any[] = storesList?.results;

        // let activeStores = allStores.filter((eachStore: any) => eachStore?.isActive === true);


        return (
            <div className="reports_wrap">
                {showSuspendModal && <SuspendStoreModal pageProps={pageProps} storeId={storeId} showSuspendModal={showSuspendModal} setShowSuspendModal={setShowSuspendModal} />}
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className="report_heading">Stores <span>{storesList?.totalNumberOfRecords}</span></div>
                    </div>
                </div>
                <div className="vendor_graphs">
                    <div className="top_of_graph">
                        <div className="left_side">
                            <img src={ShopIco} alt="" />
                            <div className="title_txt">TOP STORES <span>($)</span></div>
                        </div>
                        <div className="right_side">
                            <select name="" id="" value={topProvince} onChange={(e: any) => {
                                setTopProvince(e.target.value)
                            }}>
                                <option value="">Choose Province</option>
                                <option value="Ontario">Ontario</option>
                                <option value="Manitoba">Manitoba</option>
                                <option value="British Columbia">British Columbia</option>
                                <option value="Saskatchewan">Saskatchewan</option>
                                <option value="Alberta">Alberta</option>
                                <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                <option value="Prince Edward Island">Prince Edward Island</option>
                                <option value="Nova Scotia">Nova Scotia</option>
                                <option value="New Brunswick">New Brunswick</option>
                                <option value="Quebec">Quebec</option>
                            </select>
                        </div>
                    </div>
                    {pageProps?.getTopStoresRequest?.request_status === appConstants.GET_TOPSTORES_SUCCESS &&
                        <RenderTopStores storesList={pageProps?.getTopStoresRequest?.request_data?.response?.data} />
                    }
                    {pageProps?.getTopStoresRequest?.request_status === appConstants.GET_TOPSTORES_PENDING &&
                        <div className="all_graphs">
                            <LoadingItem />
                        </div>
                    }
                    {pageProps.getTopStoresRequest.request_status === appConstants.GET_TOPSTORES_FAILURE &&
                        <div className="all_graphs">
                            <AlertMsg type="error" message={pageProps.getStoresListRequest?.request_data.error} />
                        </div>
                    }
                </div>
                <div className="table_navs">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores">
                        <div className="table_nav_item">Active stores ({storesList?.totalNumberOfRecords})</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores/unverified">
                        <div className="table_nav_item">Unverified stores </div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores/suspended">
                        <div className="table_nav_item">Suspended stores </div>
                        <div className="underline"></div>
                    </NavLink>
                    {/* <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores/deleted">
                        <div className="table_nav_item">Deleted stores</div>
                        <div className="underline"></div>
                    </NavLink> */}
                </div>
                <div className="reports_table">
                    <div className="report_actions">
                        <input type="text" value={searchValue} autoFocus={true} className="search_table" placeholder="Search for store name"
                            onChange={(e: any) => {
                                setSearchValue(e.target.value)
                                if(e.target.value===""){
                                    setSearchText("")
                                }
                                
                            }}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                    
                                    if (e.target.value.length >= 2) {
                                        setSearchText(e.target.value.trim())
                                    }
                                }
                            }}
                        />
                        <div className="right_actions">
                            <FilterUtil />
                        </div>
                    </div>
                    {storesList?.results &&
                        <TableComponent
                            isEmptyRecords={!storesList || storesList?.results.length === 0}
                            requestData={storesList}
                            // setPageNumber={()=>setPageNumber()}
                            // refreshFunc={()=>{
                            //     setPageSize()
                            // }}
                            childComponent={<ReportTable />}
                        // childComponent={<ReportTable reportData={pageProps.getStoreOrdersRequest.request_data.response} />}
                        />
                    }
                    {storesList?.totalNumberOfRecords > pageSize &&
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            // initialPage={1}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={4}
                            forcePage={pageNumber - 1}
                            // onClick={handleItemsClick}
                            className="pagination_items"
                            pageClassName="page_num"
                            pageLinkClassName="page_link"
                            activeClassName="active_page_link"
                            previousClassName="previous_page_link"
                            nextClassName="next_page_link"
                            pageCount={storesList?.pageNumber}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                    }
                </div>
            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                {pageProps.getStoresListRequest.request_status === appConstants.GET_STORES_LIST_PENDING &&
                    <LoadingItem />
                }
                {pageProps.getStoresListRequest.request_status === appConstants.GET_STORES_LIST_SUCCESS &&
                    <ReportsDisplay />
                }
                {pageProps.getStoresListRequest.request_status === appConstants.GET_STORES_LIST_FAILURE &&
                    <AlertMsg type="error" message={pageProps.getStoresListRequest?.request_data.error} />
                }
            </div>
        </div>
    )
}

const ActiveStoresManagement = (pageProps: any) => {

    return (
        <InAppTemplate pageHeadingTitle="Stores Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getStoresAction: appActions.GetStores,
    getTopStoresinProvinceAction: appActions.GetTopStoresinProvince,
    toggleStoreAction: appActions.ToggleStore,
};

const mapStateToProps = (state: any) => ({
    getStoresListRequest: state.allInAppReducers.getStoresListReducer,
    getTopStoresRequest: state.allInAppReducers.getTopStoresReducer,
    toggleStoreRequest: state.allInAppReducers.toggleStoreReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(ActiveStoresManagement);