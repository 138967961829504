import { appConstants } from "../../action-constants/inapp.constants"
// import {ReducerParams} from "../../../types"


let userLogged = localStorage.getItem('mami-Super-XAuth') || "{}";

let user = JSON.parse(userLogged);
const initialState = Object.keys(user).length >= 1 ? { loggedIn: true, user } : { loggedIn: false };

const initialGalleryState: any = {
  // isEndOfCatalogue: false,
  results: [],
  // nextItemsBatch: [],
  // currentPage: 1
}


export const getDashboardSummaryReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_DASHBOARD_SUMMARY_PENDING:
      return {
        request_status: appConstants.GET_DASHBOARD_SUMMARY_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstants.GET_DASHBOARD_SUMMARY_SUCCESS:
      return {
        request_status: appConstants.GET_DASHBOARD_SUMMARY_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_DASHBOARD_SUMMARY_FAILURE:
      return {
        request_status: appConstants.GET_DASHBOARD_SUMMARY_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_DASHBOARD_SUMMARY_RESET:
      return {
        request_status: appConstants.GET_DASHBOARD_SUMMARY_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getDashboardBottomOneReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_DASHBOARD_BOTTOM_ONE_PENDING:
      return {
        request_status: appConstants.GET_DASHBOARD_BOTTOM_ONE_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstants.GET_DASHBOARD_BOTTOM_ONE_SUCCESS:
      return {
        request_status: appConstants.GET_DASHBOARD_BOTTOM_ONE_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_DASHBOARD_BOTTOM_ONE_FAILURE:
      return {
        request_status: appConstants.GET_DASHBOARD_BOTTOM_ONE_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_DASHBOARD_BOTTOM_ONE_RESET:
      return {
        request_status: appConstants.GET_DASHBOARD_BOTTOM_ONE_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getDashboardBottomTwoReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_DASHBOARD_BOTTOM_TWO_PENDING:
      return {
        request_status: appConstants.GET_DASHBOARD_BOTTOM_TWO_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstants.GET_DASHBOARD_BOTTOM_TWO_SUCCESS:
      return {
        request_status: appConstants.GET_DASHBOARD_BOTTOM_TWO_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_DASHBOARD_BOTTOM_TWO_FAILURE:
      return {
        request_status: appConstants.GET_DASHBOARD_BOTTOM_TWO_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_DASHBOARD_BOTTOM_TWO_RESET:
      return {
        request_status: appConstants.GET_DASHBOARD_BOTTOM_TWO_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getVendorSummaryReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_VENDORS_SUMMARY_PENDING:
      return {
        request_status: appConstants.GET_VENDORS_SUMMARY_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstants.GET_VENDORS_SUMMARY_SUCCESS:
      return {
        request_status: appConstants.GET_VENDORS_SUMMARY_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_VENDORS_SUMMARY_FAILURE:
      return {
        request_status: appConstants.GET_VENDORS_SUMMARY_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_VENDORS_SUMMARY_RESET:
      return {
        request_status: appConstants.GET_VENDORS_SUMMARY_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getStoreChartReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_A_STORE_CHART_PENDING:
      return {
        request_status: appConstants.GET_A_STORE_CHART_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstants.GET_A_STORE_CHART_SUCCESS:
      return {
        request_status: appConstants.GET_A_STORE_CHART_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_A_STORE_CHART_FAILURE:
      return {
        request_status: appConstants.GET_A_STORE_CHART_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_A_STORE_CHART_RESET:
      return {
        request_status: appConstants.GET_A_STORE_CHART_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getProductsSummaryReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_PRODUCTS_SUMMARY_PENDING:
      return {
        request_status: appConstants.GET_PRODUCTS_SUMMARY_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstants.GET_PRODUCTS_SUMMARY_SUCCESS:
      return {
        request_status: appConstants.GET_PRODUCTS_SUMMARY_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_PRODUCTS_SUMMARY_FAILURE:
      return {
        request_status: appConstants.GET_PRODUCTS_SUMMARY_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_PRODUCTS_SUMMARY_RESET:
      return {
        request_status: appConstants.GET_PRODUCTS_SUMMARY_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getProductMetaDataReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_PRODUCT_META_PENDING:
      return {
        request_status: appConstants.GET_PRODUCT_META_PENDING,
        is_request_processing: true,
        request_data: action
      };
    case appConstants.GET_PRODUCT_META_SUCCESS:
      return {
        request_status: appConstants.GET_PRODUCT_META_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_PRODUCT_META_FAILURE:
      return {
        request_status: appConstants.GET_PRODUCT_META_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_PRODUCT_META_RESET:
      return {
        request_status: appConstants.GET_PRODUCT_META_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getImageGalleryReducer = (state: any = initialGalleryState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_IMAGE_GALLERY_PENDING:
      // return {
      //     request_status: appConstants.GET_IMAGE_GALLERY_PENDING,
      //     is_request_processing: true,
      //     request_data: action
      // };
      return Object.assign({}, state, {
        request_status: appConstants.GET_IMAGE_GALLERY_PENDING,
        is_request_processing: true,
        results: [],
        request_data: action,
        galleryData: []
      })
    case appConstants.GET_IMAGE_GALLERY_SUCCESS:


      // initialGalleryState.results.concat(action?.response?.results)


      // if(action.response.pageNumber===action.response.totalNumberOfPages){
      //     return {
      //         request_status: appConstants.GET_IMAGE_GALLERY_SUCCESS,
      //         is_request_processing: false,
      //         request_data: action,
      //         galleryData :state,
      //         endOfGallery: true
      //     };
      // }
      // return Object.assign({}, state, {
      //     results: state.results.concat(action.response.results),
      //     request_status: appConstants.GET_IMAGE_GALLERY_SUCCESS,
      //     is_request_processing: false,
      //     request_data: action,
      //   })

      return {
        ...state,
        request_status: appConstants.GET_IMAGE_GALLERY_SUCCESS,
        is_request_processing: false,
        request_data: action,
        galleryData: action.results,
        // endOfGallery:false
      };
    case appConstants.GET_IMAGE_GALLERY_NEWBATCH:


      // initialGalleryState.results.concat(action?.response?.results)

      return {
        ...state,
        request_status: appConstants.GET_IMAGE_GALLERY_NEWBATCH,
        is_request_processing: false,
        request_data: action,
        galleryData: [...state.galleryData, ...action.results],
      };
    case appConstants.GET_IMAGE_GALLERY_FAILURE:
      return {
        request_status: appConstants.GET_IMAGE_GALLERY_FAILURE,
        is_request_processing: false,
        galleryData: action.results,
        request_data: action
      };
    case appConstants.GET_IMAGE_GALLERY_RESET:
      return {
        request_status: appConstants.GET_IMAGE_GALLERY_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const createStoreProductReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.CREATE_PRODUCT_PENDING:
          return {
              request_status: appConstants.CREATE_PRODUCT_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.CREATE_PRODUCT_SUCCESS:
          return {
              request_status: appConstants.CREATE_PRODUCT_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.CREATE_PRODUCT_FAILURE:
          return {
              request_status: appConstants.CREATE_PRODUCT_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.CREATE_PRODUCT_RESET:
          return {
              request_status: appConstants.CREATE_PRODUCT_RESET,
              is_request_processing: false,
              request_data: {},
          };


      default:
          return { ...state }
  }
}

export const uploadStoreBulkProductsReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.UPLOAD_MULTIPLE_PENDING:
          return {
              request_status: appConstants.UPLOAD_MULTIPLE_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.UPLOAD_MULTIPLE_SUCCESS:
          return {
              request_status: appConstants.UPLOAD_MULTIPLE_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.UPLOAD_MULTIPLE_FAILURE:
          return {
              request_status: appConstants.UPLOAD_MULTIPLE_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.UPLOAD_MULTIPLE_RESET:
          return {
              request_status: appConstants.UPLOAD_MULTIPLE_RESET,
              is_request_processing: false,
              request_data: {},
          };


      default:
          return { ...state }
  }
}

export const updateStoreProductReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.UPDATE_PRODUCT_PENDING:
          return {
              request_status: appConstants.UPDATE_PRODUCT_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.UPDATE_PRODUCT_SUCCESS:
          return {
              request_status: appConstants.UPDATE_PRODUCT_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.UPDATE_PRODUCT_FAILURE:
          return {
              request_status: appConstants.UPDATE_PRODUCT_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.UPDATE_PRODUCT_RESET:
          return {
              request_status: appConstants.UPDATE_PRODUCT_RESET,
              is_request_processing: false,
              request_data: {},
          };


      default:
          return { ...state }
  }
}

export const updateProductImageReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.UPLOAD_PRODUCT_PICTURE_PENDING:
          return {
              request_status: appConstants.UPLOAD_PRODUCT_PICTURE_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.UPLOAD_PRODUCT_PICTURE_SUCCESS:
          return {
              request_status: appConstants.UPLOAD_PRODUCT_PICTURE_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.UPLOAD_PRODUCT_PICTURE_FAILURE:
          return {
              request_status: appConstants.UPLOAD_PRODUCT_PICTURE_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.UPLOAD_PRODUCT_PICTURE_RESET:
          return {
              request_status: appConstants.UPLOAD_PRODUCT_PICTURE_RESET,
              is_request_processing: false,
              request_data: {},
          };


      default:
          return { ...state }
  }
}


export const getStoresListReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_STORES_LIST_PENDING:
      return {
        request_status: appConstants.GET_STORES_LIST_PENDING,
        is_request_processing: true,
        request_data: action
      };
    case appConstants.GET_STORES_LIST_SUCCESS:
      return {
        request_status: appConstants.GET_STORES_LIST_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_STORES_LIST_FAILURE:
      return {
        request_status: appConstants.GET_STORES_LIST_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_STORES_LIST_RESET:
      return {
        request_status: appConstants.GET_STORES_LIST_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getTopStoresReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_TOPSTORES_PENDING:
      return {
        request_status: appConstants.GET_TOPSTORES_PENDING,
        is_request_processing: true,
        request_data: action
      };
    case appConstants.GET_TOPSTORES_SUCCESS:
      return {
        request_status: appConstants.GET_TOPSTORES_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_TOPSTORES_FAILURE:
      return {
        request_status: appConstants.GET_TOPSTORES_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_TOPSTORES_RESET:
      return {
        request_status: appConstants.GET_TOPSTORES_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getVendorDetailsReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_VENDOR_DETAILS_PENDING:
      return {
        request_status: appConstants.GET_VENDOR_DETAILS_PENDING,
        is_request_processing: true,
        request_data: action
      };
    case appConstants.GET_VENDOR_DETAILS_SUCCESS:
      return {
        request_status: appConstants.GET_VENDOR_DETAILS_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_VENDOR_DETAILS_FAILURE:
      return {
        request_status: appConstants.GET_VENDOR_DETAILS_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_VENDOR_DETAILS_RESET:
      return {
        request_status: appConstants.GET_VENDOR_DETAILS_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getOrderListReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_ORDERS_PENDING:
      return {
        request_status: appConstants.GET_ORDERS_PENDING,
        is_request_processing: true,
        request_data: action
      };
    case appConstants.GET_ORDERS_SUCCESS:
      return {
        request_status: appConstants.GET_ORDERS_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_ORDERS_FAILURE:
      return {
        request_status: appConstants.GET_ORDERS_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_ORDERS_RESET:
      return {
        request_status: appConstants.GET_ORDERS_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getOrderDetailsReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_ORDER_DETAILS_PENDING:
      return {
        request_status: appConstants.GET_ORDER_DETAILS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstants.GET_ORDER_DETAILS_SUCCESS:
      return {
        request_status: appConstants.GET_ORDER_DETAILS_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_ORDER_DETAILS_FAILURE:
      return {
        request_status: appConstants.GET_ORDER_DETAILS_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_ORDER_DETAILS_RESET:
      return {
        request_status: appConstants.GET_ORDER_DETAILS_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getProductsListReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_PRODUCTS_LIST_PENDING:
      return {
        request_status: appConstants.GET_PRODUCTS_LIST_PENDING,
        is_request_processing: true,
        request_data: action
      };
    case appConstants.GET_PRODUCTS_LIST_SUCCESS:
      return {
        request_status: appConstants.GET_PRODUCTS_LIST_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_PRODUCTS_LIST_FAILURE:
      return {
        request_status: appConstants.GET_PRODUCTS_LIST_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_PRODUCTS_LIST_RESET:
      return {
        request_status: appConstants.GET_PRODUCTS_LIST_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getStoreDetailsReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_STORE_DETAILS_PENDING:
      return {
        request_status: appConstants.GET_STORE_DETAILS_PENDING,
        is_request_processing: true,
        request_data: action
      };
    case appConstants.GET_STORE_DETAILS_SUCCESS:
      return {
        request_status: appConstants.GET_STORE_DETAILS_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_STORE_DETAILS_FAILURE:
      return {
        request_status: appConstants.GET_STORE_DETAILS_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_STORE_DETAILS_RESET:
      return {
        request_status: appConstants.GET_STORE_DETAILS_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getStoreTransactionsReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_STORE_PAYMENT_HISTORY_PENDING:
      return {
        request_status: appConstants.GET_STORE_PAYMENT_HISTORY_PENDING,
        is_request_processing: true,
        request_data: action
      };
    case appConstants.GET_STORE_PAYMENT_HISTORY_SUCCESS:
      return {
        request_status: appConstants.GET_STORE_PAYMENT_HISTORY_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_STORE_PAYMENT_HISTORY_FAILURE:
      return {
        request_status: appConstants.GET_STORE_PAYMENT_HISTORY_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_STORE_PAYMENT_HISTORY_RESET:
      return {
        request_status: appConstants.GET_STORE_PAYMENT_HISTORY_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getCustomersReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_CUSTOMERS_LIST_PENDING:
      return {
        request_status: appConstants.GET_CUSTOMERS_LIST_PENDING,
        is_request_processing: true,
        request_data: action
      };
    case appConstants.GET_CUSTOMERS_LIST_SUCCESS:
      return {
        request_status: appConstants.GET_CUSTOMERS_LIST_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_CUSTOMERS_LIST_FAILURE:
      return {
        request_status: appConstants.GET_CUSTOMERS_LIST_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_CUSTOMERS_LIST_RESET:
      return {
        request_status: appConstants.GET_CUSTOMERS_LIST_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const getVendorsReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
    case appConstants.GET_VENDORS_LIST_PENDING:
      return {
        request_status: appConstants.GET_VENDORS_LIST_PENDING,
        is_request_processing: true,
        request_data: action
      };
    case appConstants.GET_VENDORS_LIST_SUCCESS:
      return {
        request_status: appConstants.GET_VENDORS_LIST_SUCCESS,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_VENDORS_LIST_FAILURE:
      return {
        request_status: appConstants.GET_VENDORS_LIST_FAILURE,
        is_request_processing: false,
        request_data: action
      };
    case appConstants.GET_VENDORS_LIST_RESET:
      return {
        request_status: appConstants.GET_VENDORS_LIST_RESET,
        is_request_processing: false,
        request_data: {},
      };


    default:
      return { ...state }
  }
}

export const toggleUserReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.TOGGLE_USER_PENDING:
          return {
              request_status: appConstants.TOGGLE_USER_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.TOGGLE_USER_SUCCESS:
          return {
              request_status: appConstants.TOGGLE_USER_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.TOGGLE_USER_FAILURE:
          return {
              request_status: appConstants.TOGGLE_USER_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.TOGGLE_USER_RESET:
          return {
              request_status: appConstants.TOGGLE_USER_RESET,
              is_request_processing: false,
              request_data: {},
          };


      default:
          return { ...state }
  }
}

export const toggleStoreReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.TOGGLE_STORE_PENDING:
          return {
              request_status: appConstants.TOGGLE_STORE_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.TOGGLE_STORE_SUCCESS:
          return {
              request_status: appConstants.TOGGLE_STORE_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.TOGGLE_STORE_FAILURE:
          return {
              request_status: appConstants.TOGGLE_STORE_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.TOGGLE_STORE_RESET:
          return {
              request_status: appConstants.TOGGLE_STORE_RESET,
              is_request_processing: false,
              request_data: {},
          };


      default:
          return { ...state }
  }
}

export const getAProductReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.GET_A_PRODUCT_PENDING:
          return {
              request_status: appConstants.GET_A_PRODUCT_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.GET_A_PRODUCT_SUCCESS:
          return {
              request_status: appConstants.GET_A_PRODUCT_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.GET_A_PRODUCT_FAILURE:
          return {
              request_status: appConstants.GET_A_PRODUCT_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.GET_A_PRODUCT_RESET:
          return {
              request_status: appConstants.GET_A_PRODUCT_RESET,
              is_request_processing: false,
              request_data: {},
          };


      default:
          return { ...state }
  }
}

export const getACustomerAddressReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.GET_CUSTOMER_ADDRESS_PENDING:
          return {
              request_status: appConstants.GET_CUSTOMER_ADDRESS_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.GET_CUSTOMER_ADDRESS_SUCCESS:
          return {
              request_status: appConstants.GET_CUSTOMER_ADDRESS_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.GET_CUSTOMER_ADDRESS_FAILURE:
          return {
              request_status: appConstants.GET_CUSTOMER_ADDRESS_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.GET_CUSTOMER_ADDRESS_RESET:
          return {
              request_status: appConstants.GET_CUSTOMER_ADDRESS_RESET,
              is_request_processing: false,
              request_data: {},
          };


      default:
          return { ...state }
  }
}

export const getUrlReducer = (state=initialState, action:any)=>{
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.GET_URL_OPTIONS_PENDING:
          return {
              request_status: appConstants.GET_URL_OPTIONS_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.GET_URL_OPTIONS_SUCCESS:
          return {
              request_status: appConstants.GET_URL_OPTIONS_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.GET_URL_OPTIONS_FAILURE:
          return {
              request_status: appConstants.GET_URL_OPTIONS_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.GET_URL_OPTIONS_RESET:
          return {
              request_status: appConstants.GET_URL_OPTIONS_RESET,
              is_request_processing: false,
              request_data: {},
          };
      

      default:
          return {...state} 
  }
}

export const getStoreCatsReducer = (state=initialState, action:any)=>{
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.GET_STORE_CATS_PENDING:
          return {
              request_status: appConstants.GET_STORE_CATS_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.GET_STORE_CATS_SUCCESS:
          return {
              request_status: appConstants.GET_STORE_CATS_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.GET_STORE_CATS_FAILURE:
          return {
              request_status: appConstants.GET_STORE_CATS_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.GET_STORE_CATS_RESET:
          return {
              request_status: appConstants.GET_STORE_CATS_RESET,
              is_request_processing: false,
              request_data: {},
          };
      

      default:
          return {...state} 
  }
}

export const addNewStoreReducer = (state = initialState, action: any) => {
  // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
  switch (action.type) {
      case appConstants.ADD_A_NEW_STORE_PENDING:
          return {
              request_status: appConstants.ADD_A_NEW_STORE_PENDING,
              is_request_processing: true,
              request_data: action
          };
      case appConstants.ADD_A_NEW_STORE_SUCCESS:
          return {
              request_status: appConstants.ADD_A_NEW_STORE_SUCCESS,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.ADD_A_NEW_STORE_FAILURE:
          return {
              request_status: appConstants.ADD_A_NEW_STORE_FAILURE,
              is_request_processing: false,
              request_data: action
          };
      case appConstants.ADD_A_NEW_STORE_RESET:
          return {
              request_status: appConstants.ADD_A_NEW_STORE_RESET,
              is_request_processing: false,
              request_data: {},
          };


      default:
          return { ...state }
  }
}

