
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";
import Rate from "../../../assets/rate.svg";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { TableComponent } from "../../../shared/components/table"

import Modal from 'react-bootstrap/Modal'



import TrendUp from "../../../assets/trend_up.svg"
import ActionIco from "../../../assets/action.png"
import { AlertMsg } from "../../../shared/components/alert-msg"
import ExportIco from "../../../assets/icons/export.png"
import TeamIco from "../../../assets/team.svg"
import FilterIco from "../../../assets/filter.svg"
import DeleteIco from "../../../assets/icons/delete.svg"
import EditIco from "../../../assets/icons/edit.svg"
import CheckIco from "../../../assets/check_gray.svg"

import MemberImg from "../../../assets/u.jpeg"


import { appConstants } from "../../../redux/action-constants/inapp.constants";



import { history } from "../../../shared/_helpers/history";


import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"



const AddMemberModal = (props: any) => {
    let { showMemberModal, setShowMemberModal } = props;

    let detailsValidationSchema = Yup.object().shape({
        memberName: Yup.string()
            .required("Required"),
        memberRole: Yup.string()
            .required("Required"),
        memberEmail: Yup.string()
            .required("Required"),


    });
    let initialValues: any = {
        memberName: "",
        memberRole: "",
        memberEmail: "",
    }
    return (
        <Modal className="member_wrap" show={showMemberModal} onHide={() => setShowMemberModal(false)}>
            <Modal.Header closeButton>
                Add team member
            </Modal.Header>
            <Modal.Body>
                <div className="form_wrap">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={detailsValidationSchema}
                        onSubmit={(values: any, actions) => { }}
                    >

                        {(props: FormikProps<any>) => {
                            const {
                                values,
                                touched,
                                errors,
                                setFieldTouched,
                                handleChange,
                                setFieldValue,
                            } = props;
                            return (
                                <Form>
                                    <div className="form-group">
                                        <div className="">
                                            <label htmlFor="memberName">
                                                Name
                                            </label>
                                            <Field
                                                value={values.memberName}
                                                name="memberName"
                                                id="memberName"
                                                autoComplete="off"
                                                type="text"
                                                placeholder="Enter team member’s name"
                                                onChange={handleChange}
                                                className={`input-wrap inapp-input ${errors.memberName && touched.memberName ?
                                                    "is-invalid-input "
                                                    : !errors.memberName && values.memberName !== "" ? "valid-input" : ""}`}
                                            >

                                            </Field>
                                            <ErrorMessage name="memberName" className="form-input-error" component="span" />
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <div className="">
                                            <label htmlFor="memberEmail">
                                                Email
                                            </label>
                                            <Field
                                                value={values.memberEmail}
                                                name="memberEmail"
                                                id="memberEmail"
                                                autoComplete="off"
                                                type="text"
                                                placeholder="sample@gmail.com"
                                                onChange={handleChange}
                                                className={`input-wrap inapp-input ${errors.memberEmail && touched.memberEmail ?
                                                    "is-invalid-input "
                                                    : !errors.memberEmail && values.memberEmail !== "" ? "valid-input" : ""}`}
                                            >

                                            </Field>
                                            <ErrorMessage name="memberEmail" className="form-input-error" component="span" />
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <div className="">
                                            <label htmlFor="memberRole">
                                                Role
                                            </label>
                                            <select 
                                                value={values.memberRole}
                                                name="memberRole"
                                                id="memberRole"
                                                className={`input-wrap inapp-input ${errors.memberRole && touched.memberRole ?
                                                    "is-invalid-input "
                                                    : !errors.memberRole && values.memberRole !== "" ? "valid-input" : ""}`}>
                                                <option value="">Super Admin</option>
                                                <option value="">Manager</option>
                                                <option value="">Analyst</option>
                                                <option value="">Auditor</option>
                                            </select>
                                            {/* <Field
                                                value={values.productName}
                                                name="memberName"
                                                id="memberName"
                                                autoComplete="off"
                                                type="text"
                                                placeholder="sample@gmail.com"
                                                onChange={handleChange}
                                                className={`input-wrap inapp-input ${errors.memberName && touched.memberName ?
                                                    "is-invalid-input "
                                                    : !errors.memberName && values.memberName !== "" ? "valid-input" : ""}`}
                                            >

                                            </Field> */}
                                            <ErrorMessage name="memberName" className="form-input-error" component="span" />
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <button className="btn send_now" type="submit"> Send invite</button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>

            </Modal.Body>
        </Modal>
    )

}


const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();

    const [showMemberModal, setShowMemberModal] = useState<boolean>(false);


    useEffect(() => {



        // let loggedAccountInfo: any = localStorage.getItem("mami-Super-XAuth") || "{}";
        // loggedAccountInfo = JSON.parse(loggedAccountInfo);
        // let storeId = loggedAccountInfo.selectedStore.id;
        // let payload = `StoreId=${storeId}`;
        // let graphPayload = `StoreId=${storeId}&ReportGroupingType=${salesFlag}`;







    }, [])

    const ReportTable = () => {
        return (
            <>
                <thead>
                    <tr>
                        <th className="left_top"> <span >Name</span> </th>
                        <th> Role</th>
                        <th className="right_top centered"> <span>Actions</span> </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="brand_name">
                            <div className="team_member ">
                                <input type="checkbox" name="" id="" />
                                <img src={MemberImg} alt="" />
                                <div className="member_info">
                                    <div className="member_name"> Alexandra Dubois</div>
                                    <div className="member_email"> jkimmich@gmail.com</div>
                                </div>
                            </div>

                        </td>
                        <td>
                            <select name="" id="" className="admin_roles level1">
                                <option value="">Super Admin</option>
                                <option value="">Analyst</option>
                                <option value="">Manager</option>
                                <option value="">Author</option>
                            </select>
                        </td>
                        <td>
                            <div className="action_ctas centered">
                                <button>
                                    <img src={EditIco} alt="" />
                                </button>
                                <button>
                                    <img src={DeleteIco} alt="" />
                                </button>
                            </div>
                        </td>

                    </tr>
                    <tr>
                        <td className="brand_name">
                            <div className="team_member ">
                                <input type="checkbox" name="" id="" />
                                <img src={MemberImg} alt="" />
                                <div className="member_info">
                                    <div className="member_name"> Alexandra Dubois</div>
                                    <div className="member_email"> jkimmich@gmail.com</div>
                                </div>
                            </div>

                        </td>
                        <td>
                            <select name="" id="" className="admin_roles level2">
                                <option value="">Analyst</option>
                                <option value="">Super Admin</option>
                                <option value="">Manager</option>
                                <option value="">Author</option>
                            </select>
                        </td>
                        <td>
                            <div className="action_ctas centered">
                                <button>
                                    <img src={EditIco} alt="" />
                                </button>
                                <button>
                                    <img src={DeleteIco} alt="" />
                                </button>
                            </div>
                        </td>

                    </tr>
                    <tr>
                        <td className="brand_name">
                            <div className="team_member ">
                                <input type="checkbox" name="" id="" />
                                <img src={MemberImg} alt="" />
                                <div className="member_info">
                                    <div className="member_name"> Alexandra Dubois</div>
                                    <div className="member_email"> jkimmich@gmail.com</div>
                                </div>
                            </div>

                        </td>
                        <td>
                            <select name="" id="" className="admin_roles level3">
                                <option value="">Manager</option>
                                <option value="">Super Admin</option>
                                <option value="">Analyst</option>
                                <option value="">Author</option>
                            </select>
                        </td>
                        <td>
                            <div className="action_ctas centered">
                                <button>
                                    <img src={EditIco} alt="" />
                                </button>
                                <button>
                                    <img src={DeleteIco} alt="" />
                                </button>
                            </div>
                        </td>

                    </tr>
                    <tr>
                        <td className="brand_name">
                            <div className="team_member ">
                                <input type="checkbox" name="" id="" />
                                <img src={MemberImg} alt="" />
                                <div className="member_info">
                                    <div className="member_name"> Alexandra Dubois</div>
                                    <div className="member_email"> jkimmich@gmail.com</div>
                                </div>
                            </div>

                        </td>
                        <td>
                            <select name="" id="" className="admin_roles level4">
                                <option value="">Author</option>
                                <option value="">Manager</option>
                                <option value="">Super Admin</option>
                                <option value="">Analyst</option>
                            </select>
                        </td>
                        <td>
                            <div className="action_ctas centered">
                                <button>
                                    <img src={EditIco} alt="" />
                                </button>
                                <button>
                                    <img src={DeleteIco} alt="" />
                                </button>
                            </div>
                        </td>

                    </tr>



                </tbody>
            </>
        )
    }


    const ReportsDisplay = () => {
        return (
            <div className="reports_wrap">
                <AddMemberModal showMemberModal={showMemberModal} setShowMemberModal={setShowMemberModal} />
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className="report_heading">Team Management</div>
                        <div className="report_desc">Add team members, and assign roles</div>
                    </div>
                    <div className="report_head_right">
                        <div className="report_head_cta">
                            {/* <button>
                                <img src={ExportIco} alt="" />
                                <span>Export database</span>
                            </button> */}
                            <button className="shaded" onClick={() => setShowMemberModal(true)}>
                                <img src={TeamIco} alt="" />
                                <span>Add team member</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="reports_table">
                    <div className="teams_wrap">
                        <div className="left_side">
                            <div className="top_filter">
                                <input type="text" className="search_table" placeholder="Search" />
                                <div className="filter_wrap">
                                    <img src={FilterIco} alt="" />
                                    <select name="" id="">
                                        <option value="">Filter By role</option>
                                    </select>
                                </div>
                            </div>
                            <TableComponent
                                childComponent={<ReportTable />}

                            />
                        </div>
                        <div className="right_side">
                            <div className="permissions_wrap">
                                <div className="header_msg">
                                    <div className="main">Permissions</div>
                                    <div className="helper">Grant permissions for each role</div>
                                </div>
                                <div className="all_permissions">
                                    <div className="each_permisison">
                                        <div className="permission_name">Create and edit blog posts</div>
                                        <div className="all_roles">
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Super Admin</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Manager</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Author</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Analyst</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="each_permisison">
                                        <div className="permission_name">Edit permission</div>
                                        <div className="all_roles">
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Super Admin</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Manager</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Author</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Analyst</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="each_permisison">
                                        <div className="permission_name">Export datasets</div>
                                        <div className="all_roles">
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Super Admin</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Manager</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Author</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Analyst</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="each_permisison">
                                        <div className="permission_name">Inventory management</div>
                                        <div className="all_roles">
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Super Admin</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Manager</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Author</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Analyst</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="each_permisison">
                                        <div className="permission_name">Resolve vendor issues</div>
                                        <div className="all_roles">
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Super Admin</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Manager</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Author</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Analyst</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="each_permisison">
                                        <div className="permission_name">Access control</div>
                                        <div className="all_roles">
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Super Admin</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Manager</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Author</label>
                                            </div>
                                            <div className="role_check">
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">Analyst</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="save_cta">
                                    <button className='btn'>
                                        <img src={CheckIco} alt="" />
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                <ReportsDisplay />
            </div>
        </div>
    )
}

const SettingsManagement = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Settings Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
    // return {

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(SettingsManagement);