
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";
import Rate from "../../../assets/rate.svg";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { TableComponent } from "../../../shared/components/table"

import {FilterUtil} from "../../../shared/components/report-filter"




import { AlertMsg } from "../../../shared/components/alert-msg"
import Shop1 from "../../../assets/shop_1.jpeg"
import TrendUp from "../../../assets/trend_up.svg"
import ShopIco from "../../../assets/shop_ico.svg"



import { appConstants } from "../../../redux/action-constants/inapp.constants";



import { history } from "../../../shared/_helpers/history";


import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"






const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();




    useEffect(() => {



        // let loggedAccountInfo: any = localStorage.getItem("mami-Super-XAuth") || "{}";
        // loggedAccountInfo = JSON.parse(loggedAccountInfo);
        // let storeId = loggedAccountInfo.selectedStore.id;
        // let payload = `StoreId=${storeId}`;
        // let graphPayload = `StoreId=${storeId}&ReportGroupingType=${salesFlag}`;







    }, [])

    const ReportTable = () => {
        return (
            <>
                <thead>
                    <tr>
                        <th className="left_top"> <span >Store</span> </th>
                        <th> Address</th>
                        <th>Total sales</th>
                        <th>Store reviews</th>
                        <th>Created on</th>
                        <th className="right_top"> <span>Store information</span> </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>

                        <td className="brand_name"> <div className="brand_info"> <input type="checkbox" name="" id="" /> <img src={Shop1} alt="" /></div> The Food Emporium</td>
                        <td className="grayed">123, Main St, Winnipeg</td>
                        <td className="branded">$54,972</td>
                        <td> <span className="postive review_count">329 - </span>  <span className="neutral review_count">141 - </span><span className="negative review_count">36</span></td>
                        <td className="grayed">05/02/2023</td>
                        <td className="branded">
                            <Link to="/app/stores/234/details">
                                <span>Store info <img src={TrendUp} alt="" /> </span>
                            </Link>
                        </td>


                    </tr>
                    <tr>

                        <td className="brand_name"> <div className="brand_info"> <input type="checkbox" name="" id="" /> <img src={Shop1} alt="" /></div>5th Street Pastry</td>
                        <td className="grayed">456 Maple Av, Toronto</td>
                        <td className="branded">$51,494</td>
                        <td> <span className="postive review_count">329 - </span>  <span className="neutral review_count">141 - </span><span className="negative review_count">36</span></td>
                        <td className="grayed">05/02/2023</td>
                        <td className="branded">
                            <Link to="/app/stores/234/details">
                                <span>Store info <img src={TrendUp} alt="" /> </span>
                            </Link>
                        </td>


                    </tr>
                    <tr>

                        <td className="brand_name"> <div className="brand_info"> <input type="checkbox" name="" id="" /> <img src={Shop1} alt="" /></div>Montana Cave</td>
                        <td className="grayed">789 Birch Plz, Vancouver</td>
                        <td className="branded">$51,494</td>
                        <td> <span className="postive review_count">329 - </span>  <span className="neutral review_count">141 - </span><span className="negative review_count">36</span></td>
                        <td className="grayed">05/02/2023</td>
                        <td className="branded">
                            <Link to="/app/stores/234/details">
                                <span>Store info <img src={TrendUp} alt="" /> </span>
                            </Link>
                        </td>


                    </tr>
                    <tr>

                        <td className="brand_name"> <div className="brand_info"> <input type="checkbox" name="" id="" /> <img src={Shop1} alt="" /></div>Staples Center</td>
                        <td className="grayed">222 Queen's Rd, Halifax</td>
                        <td className="branded">$45,041</td>
                        <td> <span className="postive review_count">329 - </span>  <span className="neutral review_count">141 - </span><span className="negative review_count">36</span></td>
                        <td className="grayed">05/02/2023</td>
                        <td className="branded">
                            <Link to="/app/stores/234/details">
                                <span>Store info <img src={TrendUp} alt="" /> </span>
                            </Link>
                        </td>


                    </tr>
                    <tr>

                        <td className="brand_name"> <div className="brand_info"> <input type="checkbox" name="" id="" /> <img src={Shop1} alt="" /></div>Sabyasachi</td>
                        <td className="grayed">1010 Elm St, Calgary</td>
                        <td className="branded">$44,583</td>
                        <td> <span className="postive review_count">329 - </span>  <span className="neutral review_count">141 - </span><span className="negative review_count">36</span></td>
                        <td className="grayed">05/02/2023</td>
                        <td className="branded">
                            <Link to="/app/stores/234/details">
                                <span>Store info <img src={TrendUp} alt="" /> </span>
                            </Link>
                        </td>


                    </tr>
                </tbody>
            </>
        )
    }


    const ReportsDisplay = () => {
        return (
            <div className="reports_wrap">
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className="report_heading">Stores <span>412</span></div>

                    </div>

                </div>
                <div className="vendor_graphs">
                    <div className="top_of_graph">
                        <div className="left_side">
                            <img src={ShopIco} alt="" />
                            <div className="title_txt">TOP STORES <span>($)</span></div>
                        </div>
                        <div className="right_side">
                            <select name="" id="">
                                <option value="Winnipeg">Winnipeg</option>
                                <option value="Winnipeg">Manitoba</option>
                            </select>
                        </div>
                    </div>
                    <div className="all_graphs">
                        <div className="each_graph">
                            <div className="vendor_name_txt">Food Emporium</div>
                            <div className="sales_bar">
                                <div className="sales_bar_fill" style={{ width: "84%" }}>$15,734</div>

                            </div>
                        </div>
                        <div className="each_graph">
                            <div className="vendor_name_txt">Tasty Treats</div>
                            <div className="sales_bar">
                                <div className="sales_bar_fill" style={{ width: "79%" }}>$11,124</div>
                            </div>
                        </div>
                        <div className="each_graph">
                            <div className="vendor_name_txt">Savory Pantry</div>
                            <div className="sales_bar">
                                <div className="sales_bar_fill" style={{ width: "60%" }}>$8,473</div>
                            </div>
                        </div>
                        <div className="each_graph">
                            <div className="vendor_name_txt">SPAR Markets</div>
                            <div className="sales_bar">
                                <div className="sales_bar_fill" style={{ width: "50%" }}>$5,290</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table_navs">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores">
                        <div className="table_nav_item">Active stores (350)</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores/unverified">
                        <div className="table_nav_item">Unverified stores (31)</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores/suspended">
                        <div className="table_nav_item">Suspended stores (20)</div>
                        <div className="underline"></div>
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/stores/deleted">
                        <div className="table_nav_item">Deleted stores (11)</div>
                        <div className="underline"></div>
                    </NavLink>
                </div>
                <div className="reports_table">
                    <div className="report_actions">
                        <input type="text" className="search_table" placeholder="Search for vendor, store name" />
                        <div className="right_actions">
                            <FilterUtil/>
                        </div>
                    </div>
                    <TableComponent
                        childComponent={<ReportTable />}
                    // childComponent={<ReportTable reportData={pageProps.getStoreOrdersRequest.request_data.response} />}
                    />
                </div>
            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                <ReportsDisplay />
            </div>
        </div>
    )
}

const DeletedStoresManagement = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Vendor Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
    // return {

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(DeletedStoresManagement);