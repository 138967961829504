let AUTH_BASE_URL = window.location.origin!=="https://backoffice.mamimarkets.com" ? "https://staging-identityapi.mamimarkets.com":"https://identityapi.mamimarkets.com";
let STORE_BASE_URL = window.location.origin!=="https://backoffice.mamimarkets.com" ? "https://staging-productapi.mamimarkets.com":"https://productapi.mamimarkets.com";
let MEDIA_BASE_URL = window.location.origin!=="https://backoffice.mamimarkets.com" ? "https://staging-mediaapi.mamimarkets.com":"https://mediaapi.mamimarkets.com";
let BACKOFFICE_BASE_URL = window.location.origin!=="https://backoffice.mamimarkets.com" ? "https://staging-backofficeapi.mamimarkets.com":"https://backofficeapi.mamimarkets.com";
let BACKOFFICE_BASE_URL2 = window.location.origin!=="https://backoffice.mamimarkets.com" ? "https://staging-reportsapi.mamimarkets.com":"https://reportapi.mamimarkets.com";

// const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL
// const STORE_BASE_URL = process.env.REACT_APP_STORE_BASE_URL
// const MEDIA_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
// const BACKOFFICE_BASE_URL = process.env.REACT_APP_BACKOFFICE_BASE_URL
// const BACKOFFICE_BASE_URL2 = process.env.REACT_APP_REPORTS_BASE_URL


BACKOFFICE_BASE_URL = window.location.hostname!=="localhost"? BACKOFFICE_BASE_URL: `http://localhost:8070/proxy`;
BACKOFFICE_BASE_URL2 = window.location.hostname!=="localhost"? BACKOFFICE_BASE_URL2: `http://localhost:8090/proxy`;

AUTH_BASE_URL = window.location.hostname!=="localhost"? AUTH_BASE_URL: `http://localhost:8040/proxy`;
let AUTH_BASE = window.location.hostname!=="localhost"? `${AUTH_BASE_URL}/api/v1/`: `http://localhost:8040/proxy/api/v1/`;
// const AUTH_BASE = `${AUTH_BASE_URL}/api/v1/`
// const STORE_BASE = `${STORE_BASE_URL}/api`

// let STORE_BASE_URL = window.location.origin!=="https://vendor.mamimarkets.com" ? "https://staging-productapi.mamimarkets.com":"https://productapi.mamimarkets.com";
let STORE_BASE = window.location.hostname!=="localhost"?`${STORE_BASE_URL}/api`: `http://localhost:8050/proxy/api`;

// const MEDIA_BASE = `${MEDIA_BASE_URL}/api`
let MEDIA_BASE =  window.location.hostname!=="localhost"? `${MEDIA_BASE_URL}/api`: `http://localhost:8060/proxy/api`;

// const BACKOFFICE_BASE = `${BACKOFFICE_BASE_URL}/api/v1`
let BACKOFFICE_BASE = window.location.hostname!=="localhost"? `${BACKOFFICE_BASE_URL}/api/v1` : `http://localhost:8070/proxy/api/v1`
let BACKOFFICE_BASE2 = window.location.hostname!=="localhost"? `${BACKOFFICE_BASE_URL2}/api/v1`: `http://localhost:8090/proxy/api/v1`
// const BACKOFFICE_BASE2 = `${BACKOFFICE_BASE_URL2}/api/v1`


export const routes = {
    LOGIN: `${AUTH_BASE_URL}/connect/token`,
    
    INITIATE_FORGOT_PW: `${AUTH_BASE}Accounts/ForgotPassword`,
    
    TOGGLE_USER: `${AUTH_BASE}Accounts/ToggleUserAccount`,

    RESET_PW: `${AUTH_BASE}Accounts/ResetPassword`,
    CREATE_PRODUCT: `${BACKOFFICE_BASE}/Products/CreateProduct`,
    UPLOAD_BULK_PRODUCTS: `${BACKOFFICE_BASE}/Products/CreateBulkProduct`,
    GET_CATEGORIES: `${STORE_BASE}/Categories`,
    GET_UNITS: `${STORE_BASE}/UnitOfMeasures`,
    PRODUCT_IMAGE_UPLOAD: `${MEDIA_BASE}/ProductImage/upload`,
    GET_IMAGE_GALLERY: `${MEDIA_BASE}/ProductImage/getImages`,
    
    ORDER_GRAPH_SUMMARY: `${BACKOFFICE_BASE}/Orders/GetOrderGraphReport`,
    
    GET_STORES: `${BACKOFFICE_BASE}/Stores/GetStores`,
    GET_TOP_STORES: `${BACKOFFICE_BASE}/Stores/GetTopstores`,
    APPROVE_STORE: `${AUTH_BASE}Stores/Approve`,
    DISAPPROVE_STORE: `${AUTH_BASE}Stores/Disapprove`,
    UN_APPROVED_STORE: `${AUTH_BASE}Stores/GetUnapprovedStores`,
    ACTIVATE_STORE: `${BACKOFFICE_BASE}/Stores/Activate`,
    DEACTIVATE_STORE: `${BACKOFFICE_BASE}/Stores/Deactivate`,
    GET_CUSTOMERS: `${AUTH_BASE}Accounts/GetUsers`,
    GET_CUSTOMER_ADDRESS: `${STORE_BASE}/Addresses`,
    GET_VENDORS: `${AUTH_BASE}Accounts/GetVendors`,
    GET_STORE_DETAILS: `${BACKOFFICE_BASE}/Orders/GetStoreDetail`,
    GET_ORDERS: `${BACKOFFICE_BASE}/Orders/GetOrders`,
    GET_ORDER_DETAILS: `${BACKOFFICE_BASE}/Orders/GetOrdersByNumber`,
    GET_STORE_ORDERS: `${BACKOFFICE_BASE}/Orders/StoreOrders`,
    GET_PRODUCTS: `${BACKOFFICE_BASE}/Products/GetProducts`,
    GET_A_PRODUCT: `${BACKOFFICE_BASE}/Products/GetProduct`,
    UPDATE_A_PRODUCT: `${BACKOFFICE_BASE}/Products/UpdateProduct`,
    DELETE_A_PRODUCT: `${BACKOFFICE_BASE}/Products`,
    GET_STORE_TRANSACTIONS: `${BACKOFFICE_BASE}/Stores/GetStoreTransactions`,
    GET_VENDOR_DETAILS: `${BACKOFFICE_BASE}/Vendors/GetVendorDetail`,
    GET_VENDOR_CONTACT_DETAILS: `${AUTH_BASE}Accounts/GetUserProfile`,

    GET_PRODUCT_CAT_SUMMARY: `${BACKOFFICE_BASE}/Products/GetTopCategoriesQuery`,
    GET_DASHBOARD_SUMMARY: `${BACKOFFICE_BASE2}/Reports/GetQuickSummary`,
    GET_VENDOR_SUMMARY: `${BACKOFFICE_BASE2}/Reports/GetCreatedVendorByRegion`,
    GET_CUSTOMER_SUMMARY: `${BACKOFFICE_BASE2}/Reports/GetCreatedUserByRegion`,
    GET_PRODUCTS_SUMMARY: `${BACKOFFICE_BASE2}/Reports/GetCreatedProductsByRegion`,

    ADD_A_STORE : `${AUTH_BASE}Stores/AdminAddStore`,
    GET_URL: `${AUTH_BASE}Stores/GetUrl`,
    GET_STORES_CATS: `${STORE_BASE}/StoreGroups`,
    LOGO_IMAGE_UPLOAD: `${MEDIA_BASE}/ProductImage/UploadImage`,
}

