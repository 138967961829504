type props = {
    name: string;
    data: Array<{
        ticket_id: string
        title: string
        description: string
        status: string
        type?: string
        customer?: string
        messages?: string
        resolved?: string
    }>
}


export const HelpDetails: props[] = [
    {
        name: 'Not started',
        data: [
            {
                ticket_id: '356',
                type: 'NEW',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Urgent'
            },
            {
                ticket_id: '395',
                type: '17:19',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Low'
            },
            {
                ticket_id: '406',
                type: 'NEW',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Medium'
            },
            {
                ticket_id: '396',
                type: 'NEW',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Urgent'
            },
            {
                ticket_id: '386',
                type: 'NEW',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Urgent'
            },

        ]
    },
    {
        name: 'Ongoing',
        data: [
            {
                ticket_id: '356',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Urgent'
            },
            {
                ticket_id: '395',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Low'
            },
            {
                ticket_id: '406',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Medium'
            },
            {
                ticket_id: '396',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Urgent'
            },
            {
                ticket_id: '386',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Urgent'
            },

        ]
    },
    {
        name: 'Resolved',
        data: [
            {
                ticket_id: '356',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Urgent',
                customer: 'Jack Grealish',
                messages: '15',
                resolved: '9:31pm 15/05'

            },
            {
                ticket_id: '395',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Low',
                customer: 'Jack Grealish',
                messages: '15',
                resolved: '9:31pm 15/05'
            },
            {
                ticket_id: '406',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Medium',
                customer: 'Jack Grealish',
                messages: '15',
                resolved: '9:31pm 15/05'
            },
            {
                ticket_id: '396',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Urgent',
                customer: 'Jack Grealish',
                messages: '15',
                resolved: '9:31pm 15/05'
            },
            {
                ticket_id: '386',
                title: 'Product Quality',
                description: 'Product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate quality delivery but also...',
                status: 'Urgent',
                customer: 'Jack Grealish',
                messages: '15',
                resolved: '9:31pm 15/05'
            },

        ]
    },
]