import {
    loginReducer,
    forgotPasswordReducer,
    resetPasswordReducer,
 
} from "../reducers/onboarding/outbound.reducer"

import {
    createStoreProductReducer,
    uploadStoreBulkProductsReducer,
    updateStoreProductReducer,
    getProductMetaDataReducer,
    getImageGalleryReducer,

    getStoresListReducer,
    getTopStoresReducer,
    getOrderListReducer,
    getOrderDetailsReducer,
    getProductsListReducer,
    getStoreDetailsReducer,
    getStoreTransactionsReducer,
    getCustomersReducer,
    getVendorsReducer,
    getVendorDetailsReducer,
    getStoreChartReducer,
    updateProductImageReducer,
    getAProductReducer,

    getDashboardSummaryReducer,
    getDashboardBottomOneReducer,
    getDashboardBottomTwoReducer,
    getVendorSummaryReducer,
    getProductsSummaryReducer,
    getACustomerAddressReducer,

    toggleUserReducer,
    toggleStoreReducer,

    addNewStoreReducer,
    getStoreCatsReducer,
    getUrlReducer
} from "../reducers/inapp/inapp.reducer"



export const inAppReducers = {
    createStoreProductReducer,
    uploadStoreBulkProductsReducer,
    updateStoreProductReducer,
    getProductMetaDataReducer,
    getImageGalleryReducer,

    getStoresListReducer,
    getTopStoresReducer,
    getOrderListReducer,
    getOrderDetailsReducer,
    getProductsListReducer,
    getStoreDetailsReducer,
    getStoreTransactionsReducer,
    getCustomersReducer,
    getVendorsReducer,
    getVendorDetailsReducer,
    getStoreChartReducer,
    updateProductImageReducer,
    getAProductReducer,

    getDashboardSummaryReducer,
    getDashboardBottomOneReducer,
    getDashboardBottomTwoReducer,
    getVendorSummaryReducer,
    getProductsSummaryReducer,
    getACustomerAddressReducer,

    toggleUserReducer,
    toggleStoreReducer,

    addNewStoreReducer,
    getStoreCatsReducer,
    getUrlReducer
}



export const outboundReducers = {
    loginReducer,
    forgotPasswordReducer,
    resetPasswordReducer,
   
}


