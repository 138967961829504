
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Rate from "../../../assets/rate.svg";

import { Link, useParams, useLocation } from "react-router-dom"

import { TableComponent } from "../../../shared/components/table"
import Modal from 'react-bootstrap/Modal'

import ReactPaginate from 'react-paginate';



import { AlertMsg } from "../../../shared/components/alert-msg"
import AddIco from "../../../assets/icons/add.png"
import ShopIco from "../../../assets/shop_1.jpeg"
import DeleteIco from "../../../assets/icons/delete.svg"
import EditIco from "../../../assets/icons/edit.svg"

import { appActions } from "../../../redux/actions/in-app"
import { LoadingItem } from "../../../shared/components/loading"
import { appConstants } from "../../../redux/action-constants/inapp.constants";



import { getDateFromISO } from "../../../shared/utils"
import { history } from "../../../shared/_helpers/history";


import { useWindowSize } from "../../../hooks/useWindowSize";

import { FilterUtil } from "../../../shared/components/report-filter"

import "../index.scss"



const getProductsListAction = async ({ pageProps, payload }: any) => {

    await pageProps.getProductsListAction(payload);
}

const deleteProductAction = async ({ pageProps, payload }: any) => {

    await pageProps.updateProductAction(payload, "delete");
}




const SuspendStoreModal = (props: any) => {
    let { showSuspendModal, setShowSuspendModal, pageProps, productStoreId, productId, productName } = props;



    const deleteProcess: any = (payload: any) => {
        deleteProductAction({
            pageProps,
            payload
        }).then(() => {
        })
    }

    return (
        <Modal className="suspend_wrap" show={showSuspendModal} onHide={() => {
            if (!pageProps?.updateStoreProductRequest?.is_request_processing) {
                setShowSuspendModal(false)
            }
        }
        }>
            <Modal.Header closeButton>
                Delete {productName}?
            </Modal.Header>
            <Modal.Body>
                {pageProps?.updateStoreProductRequest?.request_status !== appConstants.UPDATE_PRODUCT_SUCCESS &&
                    <>
                        <div className="head_message">
                            Please be advised that deleting this product will  means that <span> customers will be unable to purchase this product</span>.<br /> Are you sure you want to proceed with this action?
                        </div>
                        {pageProps?.updateStoreProductRequest?.request_status
                            && pageProps.updateStoreProductRequest.request_status === appConstants.UPDATE_PRODUCT_FAILURE &&
                            <AlertMsg type="error" message={pageProps.updateStoreProductRequest?.request_data.error} />
                        }
                        <div className="suspend_ctas">
                            <button className="btn cancel"
                                disabled={pageProps.updateStoreProductRequest?.is_request_processing}
                                onClick={() => setShowSuspendModal(false)}
                            > Cancel</button>
                            <button
                                type="submit"
                                className="btn confirm"
                                onClick={() => {
                                    let payload = {
                                        storeId: productStoreId,
                                        productId: productId
                                    }
                                    deleteProcess(payload)
                                }}
                                disabled={pageProps.updateStoreProductRequest?.is_request_processing}
                            >
                                {pageProps.updateStoreProductRequest.is_request_processing ? "Please wait..." : "Confirm"}
                            </button>
                        </div>
                    </>
                }
                {pageProps?.updateStoreProductRequest?.request_status === appConstants.UPDATE_PRODUCT_SUCCESS &&
                    <AlertMsg type="success" message="Product was successfully deleted" />
                }
            </Modal.Body>
        </Modal>
    )

}


const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();
    const { storeId } = useParams();
    const { state } = useLocation();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false)
    const [productId, setProductId] = useState();
    const [productStoreId, setProductStoreId] = useState();
    const [productName, setProductName] = useState();
    const [searchText, setSearchText] = useState<string>("")
    const [searchValue, setSearchValue] = useState<string>("")

    useEffect(() => {
        let filterInfo = `?${state?.storeData?.storeId ? `StoreId=${state?.storeData?.storeId}&` : ""}Page=${pageNumber}&Size=${pageSize}`;


        let payload = filterInfo;
        getProductsListAction({
            pageProps,
            payload
        }).then(() => {
        })






    }, [])

    useEffect(() => {

        let filterInfo = `?${state?.storeData?.storeId ? `StoreId=${state?.storeData?.storeId}&` : ""}Page=${pageNumber}&Size=${pageSize}${searchText ? `&SearchText=${searchText}` : ""}`;


        let payload = filterInfo;
        getProductsListAction({
            pageProps,
            payload
        }).then(() => {
        })

    }, [pageNumber, pageSize,searchText])

    const handlePageClick = (event: any) => {
        
        setPageNumber(event?.selected+1)
        
    }

    const handleItemsClick = (event: any) => {
        // const newOffset = (event.selected * itemsPerPage) % items.length;
        // setPageNumber(event?.selected)
        
    }

    const ReportTable = () => {
        let productsList = pageProps.getProductsListRequest?.request_data?.response?.data;
        let allProducts = productsList?.results;
        return (
            <>
                <thead>
                    <tr>
                        <th className="left_top">
                            <span>Product</span>
                        </th>
                        <th>Quantity</th>

                        <th>Category</th>
                        {/* <th>Switch</th> */}
                        {/* <th>On-sale</th> */}
                        <th>Store</th>
                        <th>Price</th>
                        <th>Rating</th>
                        <th>Created On</th>

                        <th className="right_top blanked"> <span></span></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allProducts.map((eachItem: any, index: any) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="product-table-info">
                                            <input type="checkbox" name="" id="" />
                                            <img src={eachItem?.urls && eachItem?.urls.length >= 1 ? eachItem?.urls[0] : ShopIco} alt="" />
                                            <div className="prod-name">
                                                {eachItem?.name}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{eachItem?.availableQuantity}</td>
                                    <td>
                                        {(eachItem?.categories && eachItem?.categories.length >= 1) &&
                                            <>
                                                {
                                                    eachItem?.categories.map((eachCat: any, idx: any) => {
                                                        return (
                                                            <span className="cate" key={idx}>
                                                                {eachCat}
                                                            </span>
                                                        )
                                                    })
                                                }
                                                {/* <span className="cate">
                                            Beverage
                                        </span>
                                        <span className="cate">
                                            Dairy
                                        </span> */}
                                            </>
                                        }
                                        {((eachItem?.categories && eachItem?.categories.length === 0) || !eachItem?.categories) &&
                                            <span className="cate">
                                                N/A
                                            </span>
                                        }
                                    </td>
                                    <td className="grayed">{eachItem?.storeName}</td>
                                    <td className="branded">${eachItem?.price?.amount || "0"}</td>
                                    <td><img className="rate_ico" src={Rate} alt="" />{eachItem?.ratingSummary?.averageRating} <span className="grayed">({eachItem.ratingSummary?.count})</span></td>
                                    <td className="grayed">{eachItem.createdDate ? getDateFromISO({ date: eachItem.createdDate, returnTime: true }) : "N/A"}</td>
                                    <td>
                                        <div className="action_ctas">
                                            <Link target="_blank" className="page-Link" to={`/app/stores/${eachItem?.storeId}/products/${eachItem?.id}/edit`}>
                                                <button>
                                                    <img src={EditIco} alt="" />
                                                </button>
                                            </Link>
                                            <button onClick={() => {
                                                deleteProductAction({
                                                    pageProps,
                                                    payload: "CLEAR",
                                                })
                                                setProductId(eachItem?.id)
                                                setProductName(eachItem?.name)
                                                setProductStoreId(eachItem?.storeId)
                                                setShowSuspendModal(true)
                                            }}>
                                                <img src={DeleteIco} alt="" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    {/* <tr>
                        <td>
                            <div className="product-table-info">
                                <input type="checkbox" name="" id="" />
                                <img src={ShopIco} alt="" />
                                <div className="prod-name">
                                    Cashew Juice
                                </div>
                            </div>
                        </td>
                        <td>23</td>
                        <td>
                            <span className="cate">
                                Beverage
                            </span>
                            <span className="cate">
                                Dairy
                            </span>
                        </td>
                        <td className="grayed">The Food Emporium</td>
                        <td className="branded">$10.19</td>
                        <td><img className="rate_ico" src={Rate} alt="" /> 4.8 <span className="grayed">(126)</span></td>
                        <td className="grayed">01/05/2023</td>
                        <td>
                            <div className="action_ctas">
                                <button>
                                    <img src={EditIco} alt="" />
                                </button>
                                <button>
                                    <img src={DeleteIco} alt="" />
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="product-table-info">
                                <input type="checkbox" name="" id="" />
                                <img src={ShopIco} alt="" />
                                <div className="prod-name">
                                    Cashew Juice
                                </div>
                            </div>
                        </td>
                        <td>23</td>
                        <td>
                            <span className="cate">
                                Beverage
                            </span>
                            <span className="cate">
                                Dairy
                            </span>
                        </td>
                        <td className="grayed">The Food Emporium</td>
                        <td className="branded">$10.19</td>
                        <td><img className="rate_ico" src={Rate} alt="" /> 4.8 <span className="grayed">(126)</span></td>
                        <td className="grayed">01/05/2023</td>
                        <td>
                            <div className="action_ctas">
                                <button>
                                    <img src={EditIco} alt="" />
                                </button>
                                <button>
                                    <img src={DeleteIco} alt="" />
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="product-table-info">
                                <input type="checkbox" name="" id="" />
                                <img src={ShopIco} alt="" />
                                <div className="prod-name">
                                    Cashew Juice
                                </div>
                            </div>
                        </td>
                        <td>23</td>
                        <td>
                            <span className="cate">
                                Beverage
                            </span>
                            <span className="cate">
                                Dairy
                            </span>
                        </td>
                        <td className="grayed">The Food Emporium</td>
                        <td className="branded">$10.19</td>
                        <td><img className="rate_ico" src={Rate} alt="" /> 4.8 <span className="grayed">(126)</span></td>
                        <td className="grayed">01/05/2023</td>
                        <td>
                            <div className="action_ctas">
                                <button>
                                    <img src={EditIco} alt="" />
                                </button>
                                <button>
                                    <img src={DeleteIco} alt="" />
                                </button>
                            </div>
                        </td>
                    </tr> */}



                </tbody>
            </>
        )
    }


    const ReportsDisplay = () => {
        let productsList = pageProps.getProductsListRequest?.request_data?.response?.data;

        return (
            <div className="reports_wrap">
                {(showSuspendModal && productStoreId && productId && productName) && <SuspendStoreModal productName={productName} productId={productId} pageProps={pageProps} productStoreId={productStoreId} showSuspendModal={showSuspendModal} setShowSuspendModal={setShowSuspendModal} />}
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className={storeId ? "report_heading smaller" : "report_heading"}>{storeId ? "Products Inventory" : "Products"} <span>{productsList?.totalNumberOfRecords.toLocaleString()}</span></div>
                        {!storeId && <div className="report_desc">Easily add, edit and delete products from vendors&apos; stores </div>}
                    </div>
                    {/* {!storeId &&
                        <div className="report_head_right">
                            <div className="report_head_cta">
                                <Link className="head_cta_link" to={`/app/products/create-new`}>
                                    <button>
                                        <img src={AddIco} alt="" />
                                        <span>New Product</span>
                                    </button>
                                </Link>

                            </div>
                        </div>
                    } */}
                </div>
                <div className="reports_table">
                    <div className="report_actions">
                        
                        <input type="text" value={searchValue} autoFocus={true} className="search_table" placeholder={!storeId ? "Search for product or store name" : "Type in a product name"}
                            onChange={(e: any) => {
                                setSearchValue(e.target.value)
                                if(e.target.value===""){
                                    setSearchText("")
                                }
                                
                            }}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                    
                                    if (e.target.value.length >= 2) {
                                        setSearchText(e.target.value.trim())
                                    }
                                }
                            }}
                        />
                        <div className="right_actions">
                            <FilterUtil />


                        </div>
                    </div>
                    <TableComponent
                        childComponent={<ReportTable />}
                    // childComponent={<ReportTable reportData={pageProps.getStoreOrdersRequest.request_data.response} />}
                    />
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        // initialPage={1}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={4}
                        forcePage={pageNumber-1}
                        // onClick={handleItemsClick}
                        className="pagination_items"
                        pageClassName="page_num"
                        pageLinkClassName="page_link"
                        activeClassName="active_page_link"
                        previousClassName="previous_page_link"
                        nextClassName="next_page_link"
                        pageCount={productsList?.pageNumber}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                {pageProps.getProductsListRequest.request_status === appConstants.GET_PRODUCTS_LIST_PENDING &&
                    <LoadingItem />
                }
                {pageProps.getProductsListRequest.request_status === appConstants.GET_PRODUCTS_LIST_SUCCESS &&
                    <ReportsDisplay />
                }
            </div>
        </div>
    )
}

const AllProductsListManagement = (pageProps: any) => {
    return (
        <InAppContent pageProps={pageProps} />
    )
}


const mapDispatchToProps = {
    getProductsListAction: appActions.GetProductsList,
    updateProductAction: appActions.UpdateStoreProduct,
};

const mapStateToProps = (state: any) => ({
    getProductsListRequest: state.allInAppReducers.getProductsListReducer,
    updateStoreProductRequest: state.allInAppReducers.updateStoreProductReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(AllProductsListManagement);