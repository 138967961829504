
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";


import BackIco from "../../../assets/back.svg"

import AddIco from "../../../assets/icons/add.png"








import { useWindowSize } from "../../../hooks/useWindowSize";

import { history } from "../../../shared/_helpers/history";

import AllProductsListManagement from "../products/product-listing"
import "../index.scss"
import { color } from 'html2canvas/dist/types/css/types/color';






const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();
    const navigate = useNavigate();
    const { state } = useLocation();

   

    useEffect(() => {


    }, [])

    










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">
            <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className="report_heading" onClick={()=>navigate(-1)}><img className="back_ico" src={BackIco} alt="" /> Back To <span className="main_head_txt">{state?.storeData?.storeName}</span>  </div>
                    </div>
                    <div className="report_head_right">
                            <div className="report_head_cta">
                                <Link state={{ storeData: { storeName: state?.storeData?.storeName } }}  className="head_cta_link" to={`/app/stores/${state?.storeData?.storeId}/products/create-new`}>
                                    <button>
                                        <img src={AddIco} alt="" />
                                        <span>New Product</span>
                                    </button>
                                </Link>

                            </div>
                        </div>

                </div>
            <div className="page-content dashboard">
                <AllProductsListManagement />
            </div>
        </div>
    )
}

const StoreProductsManagement = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Products Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
    // return {

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(StoreProductsManagement);