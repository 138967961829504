
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { InAppTemplate } from "../../../shared/templates/inapp";
import Rate from "../../../assets/rate.svg";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { TableComponent } from "../../../shared/components/table"



import Modal from 'react-bootstrap/Modal'

import { LoadingItem } from "../../../shared/components/loading"
import { AlertMsg } from "../../../shared/components/alert-msg"
import ExportIco from "../../../assets/icons/export.png"

import { appActions } from "../../../redux/actions/in-app"

import { appConstants } from "../../../redux/action-constants/inapp.constants";


import ReactPaginate from 'react-paginate';
import { history } from "../../../shared/_helpers/history";


import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"


const getCustomersList = async ({ pageProps, payload }: any) => {

    await pageProps.getCustomersAction(payload);
}

const getCustomerAddressList = async ({ pageProps, payload }: any) => {

    await pageProps.getCustomerAddressAction(payload);
}

const RenderAddressList = ({ addressInfo }: any) => {
    
    return (
        <div className="addresses">
            {addressInfo?.results.length === 0 &&
                <div className="no_address">  No address found</div>
            }
            {(addressInfo?.results.length >= 1) &&
                <>
                    {addressInfo?.results.map((eachAddress: any, index: any) => {
                        return (
                            <div className="each_address_info" key={index}>
                                <div className="address_full">{eachAddress.addressLine1}</div>
                                <div className="address_area">{eachAddress.postalCode ? `${eachAddress.postalCode}, ` : ""}{eachAddress.city ? `${eachAddress.city}` : ""}{eachAddress.province ? `,${eachAddress.province}` : ""}</div>
                            </div>
                        )
                    })
                    }
                </>
            }
        </div>
    )
}


const ShowAddressList = ({ showAllAddressModal, setShowAllAddressModal, customerName, pageProps }: any) => {

    
    return (
        <Modal className="address_modal" show={showAllAddressModal} onHide={() => {
            setShowAllAddressModal(false)
        }
        }>
            <Modal.Header className="address_modal_head" closeButton={true}>
                {customerName} address list
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    <div className="address_modal_form addresses_list_modal">
                        {pageProps?.getACustomerAddressRequest?.request_status === appConstants.GET_CUSTOMER_ADDRESS_PENDING &&
                            <LoadingItem />
                        }
                        {pageProps?.getACustomerAddressRequest?.request_status === appConstants.GET_CUSTOMER_ADDRESS_SUCCESS &&
                            <RenderAddressList addressInfo={pageProps?.getACustomerAddressRequest?.request_data?.response?.data} />
                        }
                        {pageProps?.getACustomerAddressRequest?.request_status === appConstants.GET_CUSTOMER_ADDRESS_FAILURE &&
                            <AlertMsg type="error" message={pageProps.getACustomerAddressRequest?.request_data.error} />
                        }
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    )

}

const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [customerId, setCustomerId] = useState<any>();
    const [customerName, setCustomerName] = useState<any>();
    const [showAllAddressModal, setShowAllAddressModal] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("")
    const [searchValue, setSearchValue] = useState<string>("")

    useEffect(() => {

        getCustomersList({
            pageProps,
            payload: `?Page=${pageNumber}&Size=${pageSize}`
        })





    }, [])

    useEffect(() => {

        getCustomersList({
            pageProps,
            payload: `?Page=${pageNumber}&Size=${pageSize}${searchText ? `&Name=${searchText}` : ""}`
        })

    }, [pageNumber, pageSize,searchText])

    // useEffect(() => {
    //     console.log("customerId", customerId)
    //     getCustomerAddressList({
    //         pageProps,
    //         payload: `?UserId=${customerId}`
    //     })

    // }, [customerId])

    const loadCustomerAddress = (customerId: any) => {

        getCustomerAddressList({
            pageProps,
            payload: `?UserId=${customerId}`
        })

    }

    const handlePageClick = (event: any) => {

        setPageNumber(event?.selected + 1)

    }
    const ReportTable = () => {
        let dataList = pageProps.getCustomersRequest?.request_data?.response?.data;
        let allData: any[] = dataList?.results;

        return (
            <>
                <thead>
                    <tr>
                        <th className="left_top"> <span >Name</span> </th>
                        <th>Address</th>
                        {/* <th>Average rating</th> */}
                        <th>Email</th>
                        <th className="right_top"> <span>Created on</span> </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allData.map((eachData: any, index: any) => {
                            return (
                                <tr key={index}>
                                    <td>{eachData?.firstName} {eachData?.lastName}</td>
                                    <td className="branded"><span
                                        onClick={() => {

                                            loadCustomerAddress(eachData?.id)
                                            setCustomerName(`${eachData?.firstName} ${eachData?.lastName} `)
                                            setShowAllAddressModal(true)
                                        }}
                                        className="view_link">View address</span></td>
                                    {/* <td className="grayed">{eachData?.address || "N/A"}</td> */}
                                    {/* <td className="branded">$1,267.19</td> */}
                                    {/* <td><img className="rate_ico" src={Rate} alt="" /> 4.8 <span className="grayed">(126)</span></td> */}
                                    <td className="grayed">{eachData?.email}</td>
                                    <td className="grayed">
                                        01/05/2023
                                    </td>
                                </tr>
                            )
                        })
                    }


                </tbody>
            </>
        )
    }


    const ReportsDisplay = () => {
        let dataList = pageProps.getCustomersRequest?.request_data?.response?.data;
        return (
            <div className="reports_wrap">
                {(showAllAddressModal && customerName) &&
                    <ShowAddressList
                        showAllAddressModal={showAllAddressModal}
                        setShowAllAddressModal={setShowAllAddressModal}
                        customerName={customerName}
                        pageProps={pageProps}
                    />
                }
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className="report_heading">Customers <span>{dataList?.totalNumberOfRecords}</span></div>
                        <div className="report_desc">Customers are users that have opened an account with  <span>mamimarkets.com</span></div>
                    </div>
                    <div className="report_head_right">
                        {/* <div className="report_head_cta">
                            <button>
                                <img src={ExportIco} alt="" />
                                <span>Export database</span>
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className="reports_table">
                    <div className="report_actions">
                        
                        <input type="text" value={searchValue} autoFocus={true} className="search_table" placeholder="Search for customer name"
                            onChange={(e: any) => {
                                setSearchValue(e.target.value)
                                if(e.target.value===""){
                                    setSearchText("")
                                }
                                
                            }}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                    
                                    if (e.target.value.length >= 2) {
                                        setSearchText(e.target.value.trim())
                                    }
                                }
                            }}
                        />
                        <div className="right_actions">
                            <div className="sort_cta">
                                Sort by
                                <select name="sorting" id="">
                                    <option value="city"> city</option>
                                </select>
                            </div>
                            <div className="sort_cta">
                                Sort by
                                <select name="sorting" id="">
                                    <option value="city"> date</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <TableComponent
                        childComponent={<ReportTable />}
                    // childComponent={<ReportTable reportData={pageProps.getStoreOrdersRequest.request_data.response} />}
                    />
                    {dataList?.totalNumberOfRecords > pageSize &&
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            // initialPage={1}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={4}
                            forcePage={pageNumber - 1}
                            // onClick={handleItemsClick}
                            className="pagination_items"
                            pageClassName="page_num"
                            pageLinkClassName="page_link"
                            activeClassName="active_page_link"
                            previousClassName="previous_page_link"
                            nextClassName="next_page_link"
                            pageCount={dataList?.pageNumber}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                    }
                </div>
            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                {pageProps.getCustomersRequest.request_status === appConstants.GET_CUSTOMERS_LIST_PENDING &&
                    <LoadingItem />
                }
                {pageProps.getCustomersRequest.request_status === appConstants.GET_CUSTOMERS_LIST_SUCCESS &&
                    <ReportsDisplay />
                }
            </div>
        </div>
    )
}

const CustomerManagement = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Customers Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getCustomersAction: appActions.GetCustomers,
    getCustomerAddressAction: appActions.GetCustomerAddress,
};

const mapStateToProps = (state: any) => ({
    getCustomersRequest: state.allInAppReducers.getCustomersReducer,
    getACustomerAddressRequest: state.allInAppReducers.getACustomerAddressReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagement);