
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";
import Rate from "../../../assets/rate.svg";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { TableComponent } from "../../../shared/components/table"

import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';


import { LoadingItem } from "../../../shared/components/loading"
import TrendUp from "../../../assets/trend_up.svg"
import ActionIco from "../../../assets/action.png"
import { AlertMsg } from "../../../shared/components/alert-msg"
import ExportIco from "../../../assets/icons/export.png"
import ReactPaginate from 'react-paginate';

import { appActions } from "../../../redux/actions/in-app"
import { appConstants } from "../../../redux/action-constants/inapp.constants";
import Modal from 'react-bootstrap/Modal'

import CheckIco from "../../../assets/check_n.svg"
import CancelIco from "../../../assets/cancel.svg"
import { history } from "../../../shared/_helpers/history";


import { useWindowSize } from "../../../hooks/useWindowSize";

import "../index.scss"



const getVendorsList = async ({ pageProps, payload }: any) => {

    await pageProps.getVendorsAction(payload);
}

const toggleUser = async ({ pageProps, payload }: any) => {

    await pageProps.toggleUserAction(payload);
}

const SuspendActionModal = (props: any) => {
    let { showSuspendModal, setShowSuspendModal, actionTodo, pageProps, vendorId, vendorName } = props;

    const suspendProcess: any = () => {
        toggleUser({
            pageProps,
            payload: `?Id=${vendorId}`
        }).then(() => {
        })
    }
    return (
        <Modal className="suspend_wrap" show={showSuspendModal} onHide={() => setShowSuspendModal(false)}>
            <Modal.Header closeButton>
                {actionTodo} {vendorName}?
            </Modal.Header>
            <Modal.Body>
                <div className="head_message">
                    Please be advised that {actionTodo === "Activate" ? "activating" : "suspending"} this vendor will also <span> {actionTodo.toLowerCase()} all of the stores owned by the vendor.</span> This means that customers will be {actionTodo === "Activate" ? "able to make purchases from these stores" : "unable to make purchases from these stores until the suspension is lifted"}. Are you sure you want to proceed with this action?
                </div>
                {pageProps.toggleUserRequest.request_status !== appConstants.TOGGLE_USER_SUCCESS &&
                    <div className="suspend_form">
                        {actionTodo !== "Activate" && <textarea name="" id="" placeholder='Reason for suspension' rows={10}></textarea>}
                        <div className="suspend_ctas">
                            <button className="btn cancel"> Cancel</button>
                            <button onClick={suspendProcess} disabled={pageProps.toggleUserRequest?.is_request_processing} className="btn confirm">

                                {pageProps.toggleUserRequest?.is_request_processing ? "Please wait" : `${actionTodo !== "Activate" ? " Confirm suspension" : "Confirm activation"}`}
                            </button>
                        </div>
                    </div>
                }
                {pageProps.toggleUserRequest.request_status === appConstants.TOGGLE_USER_FAILURE &&

                    <AlertMsg type="error" message={pageProps.toggleUserRequest?.request_data.error} />

                }
                {pageProps.toggleUserRequest.request_status === appConstants.TOGGLE_USER_SUCCESS &&

                    <AlertMsg type="success" message="Vendor status successfully updated" />

                }
            </Modal.Body>
        </Modal>
    )

}


const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const screenResolution: any = useWindowSize();
    const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false)
    const [actionTodo, setActionTodo] = useState<any>()
    const [vendorId, setVendorId] = useState<any>()
    const [vendorName, setVendorName] = useState<any>()
    const [searchText, setSearchText] = useState<string>("")
    const [searchValue, setSearchValue] = useState<string>("")

    useEffect(() => {



        getVendorsList({
            pageProps,
            payload: `?Page=${pageNumber}&Size=${pageSize}`
        }).then(() => {


        })



    }, [])

    useEffect(() => {



        getVendorsList({
            pageProps,
            payload: `?Page=${pageNumber}&Size=${pageSize}${searchText ? `&Name=${searchText}` : ""}`
        }).then(() => {


        })



    }, [pageNumber, pageSize, searchText])

    const ReportTable = () => {
        let dataList = pageProps.getVendorsRequest?.request_data?.response?.data;
        let allData: any[] = dataList?.results;
        return (
            <>
                <thead>
                    <tr>
                        <th className="left_top"> <span >Vendor name</span> </th>
                        <th> Store(s)</th>
                        {/* <th>Total earnings</th> */}
                        <th>Status</th>
                        <th>Email</th>
                        <th className="right_top"> <span>Vendor information</span> </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allData.map((eachData: any, index: any) => {
                            return (
                                <tr key={index}>
                                    <td>{eachData?.firstName} {eachData?.lastName}</td>
                                    <td className="grayed">{eachData?.storeCount}</td>
                                    {/* <td className="branded">$126,594</td> */}
                                    <td>
                                        {eachData?.status &&
                                            <div className="vendor_status active">
                                                <span className="status_tick"></span>
                                                <span className="status_txt">{eachData?.status !== "" ? eachData?.status : "Inactive"}</span>
                                            </div>
                                        }
                                    </td>
                                    <td className="grayed">{eachData?.email}</td>
                                    <td className="branded more_info">
                                        <div className="inner_td">
                                            <Link to={`/app/vendors/${eachData?.id}/details`}>
                                                <span>More details <img src={TrendUp} alt="" /> </span>
                                            </Link>
                                            <Dropdown className="report_dropdown" as={ButtonGroup}>
                                                <Dropdown.Toggle id="dropdown-basic" className="action-cta">
                                                    <div className="action">
                                                        <img src={ActionIco} alt="" />
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {/* <Dropdown.Item>
                                                        View details
                                                    </Dropdown.Item> */}
                                                    {eachData?.status.toLowerCase() === "active" &&
                                                        <>
                                                            <Dropdown.Item className="red_action" onClick={() => {
                                                                toggleUser({
                                                                    pageProps,
                                                                    payload: "CLEAR"
                                                                }).then(() => {
                                                                })
                                                                setVendorId(eachData?.id)
                                                                setVendorName(`${eachData?.firstName} ${eachData?.lastName}`)
                                                                setActionTodo("Suspend")
                                                                setShowSuspendModal(true)
                                                            }}
                                                            >
                                                                <img src={CancelIco} alt="" /> Suspend Vendor
                                                            </Dropdown.Item>
                                                        </>
                                                    }
                                                    {eachData?.status.toLowerCase() !== "active" &&
                                                        <>
                                                            <Dropdown.Item className="other_action" onClick={() => {
                                                                toggleUser({
                                                                    pageProps,
                                                                    payload: "CLEAR"
                                                                }).then(() => {
                                                                })
                                                                setVendorId(eachData?.id)
                                                                setVendorName(`${eachData?.firstName} ${eachData?.lastName}`)
                                                                setActionTodo("Activate")
                                                                setShowSuspendModal(true)
                                                            }}>
                                                                <img src={CheckIco} alt="" /> Activate Vendor
                                                            </Dropdown.Item>
                                                        </>
                                                    }
                                                    {/* {!eachStore?.isActive &&
                                                    <Dropdown.Item>
                                                    Approve Store
                                                    </Dropdown.Item>
                                                } */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {/* <tr>
                        <td>Alexandra Dubois</td>
                        <td className="grayed">3</td>
                        <td className="branded">$126,594</td>
                        <td>
                            <div className="vendor_status active">
                                <span className="status_tick"></span>
                                <span className="status_txt">Active</span>
                            </div>
                        </td>
                        <td className="grayed">j.kimmich@gmail.com</td>
                        <td className="branded more_info">
                            <Link to="/app/vendors/234/details">
                                <span>More details <img src={TrendUp} alt="" /> </span>
                            </Link>
                            <div className="action">
                                <img src={ActionIco} alt="" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Alexandra Dubois</td>
                        <td className="grayed">3</td>
                        <td className="branded">$126,594</td>
                        <td>
                            <div className="vendor_status suspended">
                                <span className="status_tick"></span>
                                <span className="status_txt">Suspended</span>
                            </div>
                        </td>
                        <td className="grayed">j.kimmich@gmail.com</td>
                        <td className="branded more_info">
                            <Link to="/app/vendors/234/details">
                                <span>More details <img src={TrendUp} alt="" /> </span>
                            </Link>
                            <div className="action">
                                <img src={ActionIco} alt="" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Alexandra Dubois</td>
                        <td className="grayed">3</td>
                        <td className="branded">$126,594</td>
                        <td>
                            <div className="vendor_status active">
                                <span className="status_tick"></span>
                                <span className="status_txt">Active</span>
                            </div>
                        </td>
                        <td className="grayed">j.kimmich@gmail.com</td>
                        <td className="branded more_info">
                            <Link to="/app/vendors/234/details">
                                <span>More details <img src={TrendUp} alt="" /> </span>
                            </Link>
                            <div className="action">
                                <img src={ActionIco} alt="" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Alexandra Dubois</td>
                        <td className="grayed">3</td>
                        <td className="branded">$126,594</td>
                        <td>
                            <div className="vendor_status active">
                                <span className="status_tick"></span>
                                <span className="status_txt">Active</span>
                            </div>
                        </td>
                        <td className="grayed">j.kimmich@gmail.com</td>
                        <td className="branded more_info">
                            <Link to="/app/stores/234/details">
                                <span>More details <img src={TrendUp} alt="" /> </span>
                            </Link>
                            <div className="action">
                                <img src={ActionIco} alt="" />
                            </div>
                        </td>
                    </tr> */}


                </tbody>
            </>
        )
    }
    const handlePageClick = (event: any) => {

        setPageNumber(event?.selected + 1)

    }

    const ReportsDisplay = () => {
        let dataList = pageProps.getVendorsRequest?.request_data?.response?.data;
        return (
            <div className="reports_wrap">
                {(showSuspendModal && vendorName) && <SuspendActionModal vendorName={vendorName} vendorId={vendorId} pageProps={pageProps} actionTodo={actionTodo} showSuspendModal={showSuspendModal} setShowSuspendModal={setShowSuspendModal} />}
                <div className="report_head_wrap">
                    <div className="report_head_left">
                        <div className="report_heading">Vendors <span>{dataList?.totalNumberOfRecords}</span></div>
                        <div className="report_desc">A vendor is any person with one or more stores on  <span>mamimarkets.com</span></div>
                    </div>
                    <div className="report_head_right">
                        <div className="report_head_cta">
                            {/* <button>
                                <img src={ExportIco} alt="" />
                                <span>Export database</span>
                            </button> */}
                        </div>
                    </div>
                </div>
                <div className="reports_table">
                    <div className="report_actions">
                    <input type="text" value={searchValue} autoFocus={true} className="search_table" placeholder="Search for vendor name"
                            onChange={(e: any) => {
                                setSearchValue(e.target.value)
                                if(e.target.value===""){
                                    setSearchText("")
                                }
                                
                            }}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                    
                                    if (e.target.value.length >= 2) {
                                        setSearchText(e.target.value.trim())
                                    }
                                }
                            }}
                        />

                    </div>
                    <TableComponent
                        childComponent={<ReportTable />}
                    // childComponent={<ReportTable reportData={pageProps.getStoreOrdersRequest.request_data.response} />}
                    />
                    {dataList?.totalNumberOfRecords > pageSize &&
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            // initialPage={1}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={4}
                            forcePage={pageNumber - 1}
                            // onClick={handleItemsClick}
                            className="pagination_items"
                            pageClassName="page_num"
                            pageLinkClassName="page_link"
                            activeClassName="active_page_link"
                            previousClassName="previous_page_link"
                            nextClassName="next_page_link"
                            pageCount={dataList?.pageNumber}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                    }
                </div>
            </div>
        )
    }










    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">

            <div className="page-content dashboard">
                {pageProps.getVendorsRequest.request_status === appConstants.GET_VENDORS_LIST_PENDING &&
                    <LoadingItem />
                }
                {pageProps.getVendorsRequest.request_status === appConstants.GET_VENDORS_LIST_SUCCESS &&
                    <ReportsDisplay />
                }
            </div>
        </div>
    )
}

const VendorsManagement = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Vendors Management" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getVendorsAction: appActions.GetVendors,
    toggleUserAction: appActions.ToggleUser,
};

const mapStateToProps = (state: any) => ({
    getVendorsRequest: state.allInAppReducers.getVendorsReducer,
    toggleUserRequest: state.allInAppReducers.toggleUserReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(VendorsManagement);